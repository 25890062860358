import {SubscriptionModel} from '../pricing/subscription.model';
import {CountryModel} from '../localization/country.model';
import {CultureModel} from '../localization/culture.model';
import {StudentRelationshipModel} from './student-relationship.model';
import {CsvImportService} from '../../services/reusable/csv-import.service';
import {DateTime} from 'luxon';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

export class StudentModel {

  private static storageKey = 'student';
  private static currentStudent: StudentModel = null;

  constructor() {
    this.evolytesProfile = new EvolytesProfile();
    this.birthDate = new Date();
    this.birthDate.setDate(1);
    this.birthDate.setHours(0);
    this.birthDate.setMinutes(0);
    this.birthDate.setSeconds(0);
    this.birthDate.setMilliseconds(0);
  }

  public static SubscriptionStatusTypes = {
    Active: 'Active',
    BillingFailed: 'BillingFailed',
    FirstBillingFailed: 'FirstBillingFailed',
    NotSetup: 'NotSetup',
    Test: 'Test'
  };

  static ChargebeeSubscriptionsStatusTypes = {
    future: 'future',
    inTrial: 'in_trial',
    active: 'active',
    nonRenewing: 'non_renewing',
    paused: 'paused',
    cancelled: 'cancelled',
    notSubscribed: 'not_subscribed',
    list: function () {
      return [this.future, this.inTrial, this.active, this.nonRenewing, this.nonRenewing, this.paused, this.cancelled, this.notSubscribed];
    }
  };

  public static SchoolTypes = {
    public: 'public',
    private: 'private',
    home: 'home',
    list: function () {
      return [this.public, this.private, this.home];
    }
  };

  public static SchoolTypesDropdownList = [{
    key: 'public',
    value: 'schoolTypes.public',
    iconURL: null
  }, {
    key: 'private',
    value: 'schoolTypes.private',
    iconURL: null
  }, {
    key: 'home',
    value: 'schoolTypes.home',
    iconURL: null
  }];

  public static ChargebeeSubscriptionsStatusTypesDropdownList = [{
    key: 'in_trial',
    value: 'inTrial',
    iconURL: null
  }, {
    key: 'future',
    value: 'future',
    iconURL: null
  },
    {
      key: 'active',
      value: 'active',
      iconURL: null
    },
    {
      key: 'non_renewing',
      value: 'nonRenewing',
      iconURL: null
    },
    {
      key: 'paused',
      value: 'paused',
      iconURL: null
    },
    {
      key: 'cancelled',
      value: 'cancelled',
      iconURL: null
    },
    {
      key: 'not_subscribed',
      value: 'notSubscribed',
      iconURL: null
    }
  ];

  public _id: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public birthDate: Date;
  public country: string = CountryModel.ISL;
  public culture: string = CultureModel.enGB;
  public schoolType: string = StudentModel.SchoolTypes.public;
  public schoolLicenseExpiresAt: Date;
  public schoolId: string;
  public teacherId: string;
  public evolytesProfile: EvolytesProfile;
  public billingStatus: string;
  public trialEndDate: Date;
  public _creator: string;
  public qrCodePassword: string;

  // authentication
  isQRCodeAuthEnabled: boolean;
  isPinAuthEnabled: boolean;
  isEmailAuthEnabled: boolean;

  public createdAt: Date;
  public updatedAt: Date;

  public subscription: SubscriptionModel = null;
  public relationships: StudentRelationshipModel[] = [];

  public schoolAccess: {
    schoolId: string,
    schoolLicenseExpiresAt: Date
  }[] = [];

  static getCurrent(): StudentModel {

    if (this.currentStudent) {
      return this.currentStudent;
    }

    const currentStudentJson = localStorage.getItem(this.storageKey);
    if (currentStudentJson) {
      this.currentStudent = this.generateModel(JSON.parse(currentStudentJson));
      return this.currentStudent;
    }

    return null;

  }

  static setCurrent(student: StudentModel) {
    this.currentStudent = student;
    if (this.currentStudent) {
      localStorage.setItem(this.storageKey, JSON.stringify(this.currentStudent));
    } else {
      this.removeCurrent();
    }

  }

  static removeCurrent() {

    this.currentStudent = null;
    localStorage.removeItem(this.storageKey);

  }

  /**
   * Creates a student model from the json
   *
   * @param json The json object.
   */
  static generateModel(json: any): StudentModel {

    const student = new StudentModel();
    student._id = json._id;
    student.firstName = json.firstName;
    student.middleName = json.middleName;
    student.lastName = json.lastName;
    student.country = json.country;
    student.culture = json.culture;
    student.schoolType = json.schoolType;
    student.schoolId = json.schoolId;
    student.teacherId = json.teacherId;
    student.billingStatus = json.billingStatus;
    student._creator = json._creator;
    student.qrCodePassword = json.qrCodePassword;

    student.isQRCodeAuthEnabled = json.isQRCodeAuthEnabled;
    student.isPinAuthEnabled = json.isPinAuthEnabled;
    student.isEmailAuthEnabled = json.isEmailAuthEnabled;

    if (json.schoolLicenseExpiresAt) {
      student.schoolLicenseExpiresAt = new Date(json.schoolLicenseExpiresAt);
    }

    if (json.birthDate) {
      const birthDate = new Date(json.birthDate);
      student.birthDate = birthDate;
    }

    if (json.createdAt) {
      const createdAt = new Date(json.createdAt);
      student.createdAt = createdAt;
    }

    if (json.updatedAt) {
      const updatedAt = new Date(json.updatedAt);
      student.updatedAt = updatedAt;
    }

    if (json.trialEndDate) {
      student.trialEndDate = new Date(json.trialEndDate);
    }

    if (json.subscription) {
      student.subscription = SubscriptionModel.generateModel(json.subscription);
    }

    if (json.evolytesProfile) {
      student.evolytesProfile = EvolytesProfile.generateModel(json.evolytesProfile);
    }

    if (json.schoolAccess) {
      student.schoolAccess = json.schoolAccess;
      const accessList = [];
      for (const access of json.schoolAccess) {

        const body = {
          schoolId: access.schoolId,
          schoolLicenseExpiresAt: null
        };

        if (access.schoolLicenseExpiresAt) {
          body.schoolLicenseExpiresAt = new Date(access.schoolLicenseExpiresAt);
        }

        accessList.push(body);

      }

      student.schoolAccess = accessList;

    }

    return student;

  }

  static generateModels(jsonList: any[]): StudentModel[] {

    const list = [];

    for (const json of jsonList) {
      const student = this.generateModel(json);
      list.push(student);
    }

    return list;

  }

  static importStudentsViaCSV(event, csvSv: CsvImportService, country: string): Observable<StudentModel[]> {

    return new Observable((subscriber) => {

      const students = [];

      if (event.target.files.length > 0) {

        const currentFile = event.target.files[0];

        const properties = ['Name', 'Birth Date'];
        csvSv.importCSV(currentFile, properties).subscribe((objects) => {

          let line = 1;
          for (const studentBody of objects) {

            const student = new StudentModel();

            for (const property of properties) {

              if (property === 'Birth Date') {

                let value: string = studentBody[property];

                // Remove empty spaces.
                const regExp = / /gi;
                const regExp2 = new RegExp(String.fromCharCode(160), 'gi');
                value = value.replace(regExp, '');
                value = value.replace(regExp2, '');

                if (value) {

                  if (country === CountryModel.ISL) {

                    // Kennitala
                    if (value.length === 6) {

                      const kennitala = DateTime.fromFormat(value, 'ddMMyy');
                      kennitala.set({ day: 1 });
                      if (kennitala.isValid) {
                        student.birthDate = kennitala.toJSDate();
                        continue;
                      }

                    } else if ((value.charAt(6) === '-' && value.length === 11) || (value.length === 10 && value.includes('/') === false)) {


                      const kennitala = DateTime.fromFormat(value.slice(0, 6), 'ddMMyy');
                      kennitala.set({ day: 1 });
                      if (kennitala.isValid) {
                        student.birthDate = kennitala.toJSDate();
                        continue;
                      }

                    }

                    // if all else fails run these
                    // dd/MM/yyyy
                    const ddMMyyyy = DateTime.fromFormat(value, 'dd/MM/yyyy');
                    ddMMyyyy.set({ day: 1 });
                    if (ddMMyyyy.isValid) {
                      student.birthDate = ddMMyyyy.toJSDate();
                      continue;
                    }

                    // if all else fails run these
                    // dd/MM/yy
                    const ddMMyy = DateTime.fromFormat(value, 'dd/MM/yy');
                    ddMMyy.set({ day: 1 });
                    if (ddMMyy.isValid) {
                      student.birthDate = ddMMyy.toJSDate();
                      continue;
                    }

                    // MM/yyyy
                    const MMyyyy = DateTime.fromFormat(value, 'MM/yyyy');
                    MMyyyy.set({ day: 1 });
                    if (MMyyyy.isValid) {
                      student.birthDate = MMyyyy.toJSDate();
                      continue;
                    }

                    // MM/yy
                    const MMyy = DateTime.fromFormat(value, 'MM/yy');
                    MMyy.set({ day: 1 });
                    if (MMyy.isValid) {
                      student.birthDate = MMyy.toJSDate();
                      continue;
                    }

                  } else if (country === CountryModel.USA) {

                    const MMddyyyy = DateTime.fromFormat(value, 'MMddyyyy');
                    if (MMddyyyy.isValid) {
                      student.birthDate = MMddyyyy.toJSDate();
                      continue;
                    }

                    const MMddyy = DateTime.fromFormat(value, 'MMddyy');
                    if (MMddyy.isValid) {
                      student.birthDate = MMddyy.toJSDate();
                      continue;
                    }

                  }

                  // if all else fails run these
                  // dd/MM/yyyy
                  const ddMMyyyySlash = DateTime.fromFormat(value, 'dd/MM/yyyy');
                  if (ddMMyyyySlash.isValid) {
                    student.birthDate = ddMMyyyySlash.toJSDate();
                    continue;
                  }

                  // dd/MM/yy
                  const ddMMyySlash = DateTime.fromFormat(value, 'dd/MM/yy');
                  if (ddMMyySlash.isValid) {
                    student.birthDate = ddMMyySlash.toJSDate();
                    continue;
                  }

                  // MM/yyyy
                  const MMyyyySlash = DateTime.fromFormat(value, 'MM/yyyy');
                  if (MMyyyySlash.isValid) {
                    student.birthDate = MMyyyySlash.toJSDate();
                    continue;
                  }

                  // MM/yy
                  const MMyySlash = DateTime.fromFormat(value, 'MM/yy');
                  if (MMyySlash.isValid) {
                    student.birthDate = MMyySlash.toJSDate();
                    continue;
                  }

                  // ddMMyy
                  const ddMMyy = DateTime.fromFormat(value, 'ddMMyy');
                  if (ddMMyy.isValid) {
                    student.birthDate = ddMMyy.toJSDate();
                    continue;
                  }

                  // ddMMyyyy
                  const ddMMyyyy = DateTime.fromFormat(value, 'ddMMyyyy');
                  if (ddMMyy.isValid) {
                    student.birthDate = ddMMyyyy.toJSDate();
                    continue;
                  }

                  // MMyy
                  const MMyy = DateTime.fromFormat(value, 'MMyy');
                  if (MMyy.isValid) {
                    student.birthDate = MMyy.toJSDate();
                    continue;
                  }

                  // MMyyyy
                  const MMyyyy = DateTime.fromFormat(value, 'MMyyyy');
                  if (MMyyyy.isValid) {
                    student.birthDate = MMyyyy.toJSDate();
                    continue;
                  }

                }

              } else if (property === 'Name') {

                const fullName: string = studentBody[property];

                if (fullName) {

                  const splitNames = fullName.split(' ');
                  const firstName = splitNames[0];
                  let middleName = null;
                  let lastName = splitNames[1];

                  if (splitNames.length >= 3) {

                    middleName = splitNames[1];
                    lastName = splitNames[2];

                    for (let i = 3; i < splitNames.length; i++) {
                      lastName += ' ' + splitNames[i];
                    }

                  }

                  student.firstName = firstName;
                  student.middleName = middleName;
                  student.lastName = lastName;

                }

              }

            }

            if (!student.firstName) {
              throw new Error(`Name parameter is empty at line: ${line}. We require at least one name to identify the student.`);
            }

            if (!student.birthDate) {
              throw new Error(`The birthdate does not have the right format at line: ${line}`);
            }

            students.push(student);
            line++;

          }

          subscriber.next(students);
          subscriber.complete();

        });

      }

    });

  }

  isTrialExpired() {

    if (this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.inTrial) {
      if (this.trialEndDate && this.trialEndDate.getTime() > Date.now()) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  }

  fullName(tSv: TranslateService = null): string {

    if (this.teacherId != null && tSv != null) {
      const teacherTestAccountString = tSv.instant('selectStudent.teacherTestAccountTitle');
      return teacherTestAccountString;
    }

    let name = this.firstName;
    if (!name) {
      name = '';
    }

    if (this.middleName) {
      name += ' ';
      name += this.middleName;
    }

    if (this.lastName) {
      name += ' ';
      name += this.lastName;
    }

    return name;

  }

  ageYears(): number {

    if (this.birthDate) {

      const nowDate = new Date();
      const yearDiff = nowDate.getFullYear() - this.birthDate.getFullYear();

      return yearDiff;

    }

    // If we cannot calculate the age
    return -1;
  }

  subscriptionStatus(): string {

    if (this.subscription) {

      return this.subscription.status;
    }

    return StudentModel.SubscriptionStatusTypes.NotSetup;

  }

  isBillingActive(): boolean {

    if (this.schoolAccess && this.schoolAccess.length > 0) {

      for (const access of this.schoolAccess) {

        if (access.schoolLicenseExpiresAt && access.schoolLicenseExpiresAt.getTime() >= Date.now()) {
          return true;
        }

      }

    } else if (this.subscription && this.subscription.status === StudentModel.SubscriptionStatusTypes.Active) {

      return true;

    } else if (this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.active ||
      this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.nonRenewing) {

      return true;

    } else if (this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.inTrial && this.trialEndDate) {
      if (this.trialEndDate.getTime() >= Date.now()) {
        return true;
      }
    }

    return false;
  }

  isBillingPaused(): boolean {

    if (this.schoolAccess && this.schoolAccess.length > 0) {
      return false;
    } else if (this.subscription &&
      this.subscription.status === StudentModel.SubscriptionStatusTypes.NotSetup) {
      return true;
    } else if (this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.paused ||
      this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.notSubscribed) {
      return true;
    } else if (!this.subscription) {
      return true;
    }

    return false;
  }

  isBillingCancelled(): boolean {

    if (this.schoolAccess && this.schoolAccess.length > 0) {

      let isActive = false;
      for (const access of this.schoolAccess) {
        if ( access.schoolLicenseExpiresAt && access.schoolLicenseExpiresAt.getTime() >= Date.now()) {
          isActive = true;
          break;
        }
      }

      // if we have no active
      if (isActive === false) {
        return true;
      }

    } else if (this.subscription &&
      (this.subscription.status === StudentModel.SubscriptionStatusTypes.FirstBillingFailed ||
        this.subscription.status === StudentModel.SubscriptionStatusTypes.BillingFailed) &&
      (!this.billingStatus || this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.notSubscribed)) {

      return true;

    } else if (this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.cancelled) {

      return true;

    } else if (this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.inTrial && this.trialEndDate) {

      if (this.trialEndDate.getTime() < Date.now()) {
        return true;
      }

    }

    return false;
  }

  /**
   * in_trial is also considered an active state
   * future is also considered an active state
   * non_renewing is also considered an active state
   */
  studentStatus(): string {

    if (this.schoolAccess && this.schoolAccess.length > 0) {

      for (const access of this.schoolAccess) {
        if (access.schoolLicenseExpiresAt && access.schoolLicenseExpiresAt.getTime() >= Date.now()) {
          return 'selectStudent.statusActive';
        }
      }

      return 'selectStudent.licenseExpired';

    } else if ((this.subscription && this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.notSubscribed) ||
      (this.subscription && !this.billingStatus)) {

      if (this.subscription.status === StudentModel.SubscriptionStatusTypes.NotSetup || !this.subscription) {

        return 'selectStudent.statusNotSetup';

      } else if (this.subscription.status === StudentModel.SubscriptionStatusTypes.BillingFailed ||
        this.subscription.status === StudentModel.SubscriptionStatusTypes.FirstBillingFailed) {

        return 'selectStudent.statusFailed';

      }

      return 'selectStudent.statusActive';

    } else if (this.billingStatus === StudentModel.ChargebeeSubscriptionsStatusTypes.inTrial &&
      this.trialEndDate &&
      this.trialEndDate.getTime() < Date.now()) {

      return 'selectStudent.trial_over';

    } else if (this.billingStatus) {

      return 'selectStudent.' + this.billingStatus;

    } else if (!this.subscription) {

      return 'selectStudent.statusFailed';
    }

  }

  QRCodeData(): string {

    const qrCodeJson = {
      studentId: this._id,
      qrCodePassword: this.qrCodePassword
    };

    const jsonString = JSON.stringify(qrCodeJson);

    return jsonString;

  }

}

export class EvolytesProfile {

  static CharacterTypes = {
    eva: 'Eva',
    sara: 'Sara',
    lucas: 'Lucas',
    oliver: 'Oliver'
  };

  character: string;
  coins: number;
  map: string;
  mapType: string;
  location = {x: 0, y: 0};
  unityMap: string;
  unityLocation = {x: 0, y: 0};
  unityCrystalMap: string;
  unityCrystalLocation = {x: 0, y: 0};
  crystalMap: string;
  crystalMapType: string;
  crystalLocation = {x: 0, y: 0};
  monsterOneId: string;
  monsterTwoId: string;
  monsterThreeId: string;

  onBoardingCatchMonster: boolean;
  onBoardingEvolveMonster: boolean;
  onBoardingUpgradeAbility: boolean;
  onBoardingNewAbility: boolean;
  onBoardingUseItemNavigation: boolean;
  onBoardingUseItemBattle: boolean;
  onBoardingCharacterInteraction: boolean;
  onBoardingCrystalInteraction: boolean;
  onBoardingSwitchMonster: boolean;

  isSetup = false;

  static generateModel(json: any): EvolytesProfile {

    const profile = new EvolytesProfile();
    profile.character = json.character;
    profile.coins = json.coins;
    profile.map = json.map;
    profile.mapType = json.mapType;
    profile.unityMap = json.unityMap;
    profile.unityCrystalMap = json.unityCrystalMap;
    profile.crystalMap = json.crystalMap;
    profile.crystalMapType = json.crystalMapType;
    profile.isSetup = json.isSetup;

    // Onboarding
    profile.onBoardingCatchMonster = json.onBoardingCatchMonster;
    profile.onBoardingEvolveMonster = json.onBoardingEvolveMonster;
    profile.onBoardingUpgradeAbility = json.onBoardingUpgradeAbility;
    profile.onBoardingNewAbility = json.onBoardingNewAbility;
    profile.onBoardingUseItemNavigation = json.onBoardingUseItemNavigation;
    profile.onBoardingCharacterInteraction = json.onBoardingCharacterInteraction;
    profile.onBoardingCrystalInteraction = json.onBoardingCrystalInteraction;
    profile.onBoardingSwitchMonster = json.onBoardingSwitchMonster;

    // Monster ids
    profile.monsterOneId = json.monsterTwoId;
    profile.monsterTwoId = json.monsterTwoId;
    profile.monsterThreeId = json.monsterThreeId;

    if (json.unityLocation) {
      profile.unityLocation.x = json.unityLocation.x;
      profile.unityLocation.y = json.unityLocation.y;
    }

    if (json.unityCrystalLocation) {
      profile.unityCrystalLocation.x = json.unityCrystalLocation.x;
      profile.unityCrystalLocation.y = json.unityCrystalLocation.y;
    }

    if (json.location) {
      profile.location.x = json.location.x;
      profile.location.y = json.location.y;
    }

    if (json.crystalLocation) {
      profile.crystalLocation.x = json.crystalLocation.x;
      profile.crystalLocation.y = json.crystalLocation.y;
    }



    return profile;

  }

  profileImage(): string {

    if (this.character) {
      return './assets/student/' + this.character + 'Profile.png';
    }

    return './assets/student/BackgroundProfile.png';

  }

  profileOutlineColor(): string {

    if (this.character === EvolytesProfile.CharacterTypes.eva) {

      return '#9B59B6';
    } else if (this.character === EvolytesProfile.CharacterTypes.lucas) {

      return '#D72121';
    } else if (this.character === EvolytesProfile.CharacterTypes.oliver) {

      return '#009CCC';
    } else if (this.character === EvolytesProfile.CharacterTypes.sara) {

      return '#28A745';
    }

    return '#28A745';

  }

}
