import { Injectable } from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {SchoolFilter, SchoolModel} from '../../../models/school/school.model';
import {AdminSchoolService} from '../../../services/admin/admin-school.service';

@Injectable({
  providedIn: 'root'
})
export class AdminSchoolSelectionService {

  isVisible = false;
  subscriber: Subscriber<SchoolModel[]>;

  schools: SchoolModel[] = [];
  schoolsToAdd: SchoolModel[] = [];

  filter: SchoolFilter = new SchoolFilter();
  hasMore = false;

  constructor(private schoolSv: AdminSchoolService) { }

  show(country: string, filter: SchoolFilter = null): Observable<SchoolModel[]> {

    if (filter) {
      this.filter = filter;
    } else {
      this.filter = new SchoolFilter();
    }

    if (country) {
      this.filter.country = country;
    } else {
      this.filter.country = null;
    }

    this.filter.limit = 20;
    this.isVisible = true;

    this.fetchSchools();

    return new Observable((subscriber: Subscriber<SchoolModel[]>) => {
      this.subscriber = subscriber;
    });

  }

  searchSchools(name: string) {

    this.reset();
    this.hasMore = false;
    this.filter.search = name;

    this.fetchSchools();

  }

  fetchSchools() {

    this.schoolSv.fetchSchools(this.filter).subscribe((schools) => {

      for (const s of schools) {
        if (this.schools.filter((cp) => cp._id === s._id).length === 0) {
          this.schools.push(s);
        }
      }

      this.hasMore = false;
      if (schools.length === this.filter.limit) {
        this.hasMore = true;
      }

    });

  }

  close() {

    this.subscriber.next(null);
    this.subscriber.complete();
    this.reset();
    this.isVisible = false;

  }

  addSchools() {

    this.subscriber.next(this.schoolsToAdd);
    this.subscriber.complete();
    this.reset();
    this.isVisible = false;

  }

  reset() {

    this.schools = [];
    this.schoolsToAdd = [];

    this.filter.search = null;
    this.filter.country = null;
    this.filter.skip = 0;
    this.filter.limit = 100;

  }

}
