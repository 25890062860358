import {ImageCategoryTypes} from './imageType.model';

export class MultipleImageTypesModel {

  category: string = ImageCategoryTypes.items;
  images: string[] = [];

  static generate(json: any): MultipleImageTypesModel {

    const mImgType = new MultipleImageTypesModel();

    mImgType.category = json.category;
    mImgType.images = json.images;

    return mImgType;
  }

  static generateModels(jsonList: any): MultipleImageTypesModel[] {

    const list = [];
    for (const json of jsonList) {
      list.push(this.generate(json));
    }

    return list;

  }

}
