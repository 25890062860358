import {DescriptionModel} from '../shared/description.model';
import {StringExtensionModel} from '../extensions/string-extension.model';
import {QuestionModel} from '../questions/question.model';
import {generateQuestion, generateQuestionList} from '../questions/question-generator';

export class PlacementTestModel {

  _id: string;
  name: string;
  country: string;
  translatedName = new DescriptionModel();
  grade: number;
  isArchived: boolean;
  sections: PlacementTestSectionModel[];
  preRequisiteSkillIds: string[] = [];
  preRequisiteSkills: QuestionModel[] = [];

  public createdAt: Date;
  public updatedAt: Date;

  static generateModel(json: any): PlacementTestModel {

    const placementTest = new PlacementTestModel();
    placementTest._id = json._id;
    placementTest.name = json.name;
    placementTest.country = json.country;
    placementTest.grade = json.grade;
    placementTest.isArchived = json.isArchived;

    if (json.preRequisiteSkillIds && json.preRequisiteSkillIds.length > 0) {

      const skill = json.preRequisiteSkillIds[0];
      if (typeof skill === 'string') {

        placementTest.preRequisiteSkillIds = json.preRequisiteSkillIds;

      } else {

        placementTest.preRequisiteSkills = generateQuestionList(json.preRequisiteSkillIds);
        for (const q of placementTest.preRequisiteSkills) {
          placementTest.preRequisiteSkillIds.push(q._id);
        }

      }

    }

    if (json.preRequisiteSkills && json.preRequisiteSkills.length > 0) {
      placementTest.preRequisiteSkills = json.preRequisiteSkills;
    }

    if (json.translatedName) {
      placementTest.translatedName = DescriptionModel.generateModel(json.translatedName);
    }

    if (json.sections) {
      placementTest.sections = PlacementTestSectionModel.generateList(json.sections);
    }

    if (json.createdAt) {
      placementTest.createdAt = new Date(json.createdAt);
    }

    if (json.updatedAt) {
      placementTest.updatedAt = new Date(json.updatedAt);
    }

    return placementTest;

  }

  static generateList(jsonList: any): PlacementTestModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push( PlacementTestModel.generateModel(json) );
    }

    return list;

  }

  getTwoInitials(): string {

    return StringExtensionModel.getInitials(this.name, 2);
  }

  /*
  * a list full with all question ids used in the placement test
  * sections, associated questions and pre-requisite questions or
  * skills whichever you prefer.
   */
  getQuestionIdsInUse(): string[] {

    let listIdsToExclude: string[] = [];
    for (const section of this.sections) {
      for (const sectionQ of section.orderedQuestions) {
        listIdsToExclude.push(sectionQ.questionId);
        listIdsToExclude = listIdsToExclude.concat(sectionQ.associatedQuestionIds);
      }
    }

    listIdsToExclude = listIdsToExclude.concat(this.preRequisiteSkillIds);

    return listIdsToExclude;

  }

  getSkillGroupIdsInUse() {

    const skillGroupIds = [];
    for (const section of this.sections) {
      for (const sectionQ of section.orderedQuestions) {

        if (skillGroupIds.filter((s) => s._id === sectionQ.question.skillGroupId).length === 0) {
          skillGroupIds.push(sectionQ.question.skillGroupId);
        }

      }
    }

    return skillGroupIds;

  }

}

export class PlacementTestSectionModel {

  _id: string;
  name: string;
  translatedName = new DescriptionModel();
  orderedQuestions: PlacementTestSectionQuestionModel[] = [];

  static generateModel(json: any): PlacementTestSectionModel {

    const section = new PlacementTestSectionModel();
    section._id = json._id;
    section.name = json.name;
    section.translatedName = json.translatedName;

    if (json.orderedQuestions) {
      section.orderedQuestions = PlacementTestSectionQuestionModel.generateList(json.orderedQuestions);
    }

    return section;

  }

  static generateList(jsonList: any): PlacementTestSectionModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push( PlacementTestSectionModel.generateModel(json) );
    }

    return list;

  }

}

export class PlacementTestSectionQuestionModel {

  _id: string;
  questionId: string;
  question: QuestionModel;
  associatedQuestionIds: string[] = [];
  associatedQuestions: QuestionModel[] = [];

  static generateModel(json: any): PlacementTestSectionQuestionModel {

    const sectionQ = new PlacementTestSectionQuestionModel();
    sectionQ._id = json._id;

    if (json.questionId && typeof json.questionId === 'object') {
      sectionQ.question = generateQuestion(json.questionId);
      sectionQ.questionId = sectionQ.question._id;
    } else if (json.question && typeof json.question === 'object') {
      sectionQ.question = generateQuestion(json.question);
      sectionQ.questionId = sectionQ.question._id;
    } else {
      sectionQ.questionId = json.questionId;
      sectionQ.question = null;
    }

    if (json.associatedQuestionIds && json.associatedQuestionIds.length > 0) {

      const value = json.associatedQuestionIds[0];
      if (value && typeof value === 'object') {
        sectionQ.associatedQuestions = generateQuestionList(json.associatedQuestionIds);
        for (const q of sectionQ.associatedQuestions) {
          sectionQ.associatedQuestionIds.push(q._id);
        }

      } else {

        sectionQ.associatedQuestionIds = json.associatedQuestionIds;

      }

    }

    return sectionQ;

  }

  static generateList(jsonList: any): PlacementTestSectionQuestionModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push( PlacementTestSectionQuestionModel.generateModel(json) );
    }

    return list;

  }

}

export class PlacementTestFilter {

  skip = 0;
  limit: number = undefined;
  name: string = undefined;
  country: string = undefined;

  isArchived: boolean = undefined;

  sort: number = undefined;

}
