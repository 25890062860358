import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UserModel} from '../../../models/authentication/user.model';
import {SchoolModel} from '../../../models/school/school.model';
import {UserService} from '../../../services/user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {StudentModel} from '../../../models/authentication/student.model';
import {forkJoin} from 'rxjs';
import {StudentService} from '../../../services/student/student.service';
import {StudentFilterModel} from '../../../models/filters/student-filter.model';

@Component({
  selector: 'app-select-school',
  templateUrl: './select-school.component.html',
  styleUrls: ['./select-school.component.css']
})
export class SelectSchoolComponent implements OnInit {

  schools: SchoolModel[] = [];
  students: StudentModel[] = [];
  fetchingStudents = true;
  selectAccountView = false;

  constructor(private router: Router,
              private tSv: TranslateService,
              private userSv: UserService,
              private studentSv: StudentService) { }

  ngOnInit() {

    if (!UserModel.getCurrent() || !UserModel.getCurrent().hasSchoolAccess()) {
      this.router.navigate(['selectStudent']);
    }

    const findUser = this.userSv.findUser();
    const findSchool = this.userSv.fetchSchools();

    const studentFilter = new StudentFilterModel();
    studentFilter.limit = 1;

    const findStudents = this.studentSv.fetchStudents(studentFilter);

    forkJoin([findUser, findSchool, findStudents]).subscribe((data) => {

      this.fetchingStudents = false;

      const user = data[0];
      UserModel.setCurrent(user);

      this.schools = data[1];
      this.students = data[2];

    });

  }

  getTranslatedAccessType(s: SchoolModel): string {

    const accessType = UserModel.getCurrent().findSchoolAccessType(s._id);

    return this.tSv.instant('schoolAccessTypes.' + accessType);

  }


  getAccessType(s: SchoolModel) {

    return UserModel.getCurrent().findSchoolAccessType(s._id);

  }

  onChildrenSelected() {
    SchoolModel.setCurrent(null);
    StudentModel.removeCurrent();
    this.router.navigate(['selectstudent']);
  }

  onSchoolSelect(school: SchoolModel) {

    SchoolModel.setCurrent(school);
    StudentModel.removeCurrent();

    if (this.getAccessType(school) === UserModel.SchoolAccessTypes.admin) {
      this.selectAccountView = true;
    } else {
      this.router.navigate(['selectstudent']);
    }

  }

  getAdminInitial(): string {

    return this.tSv.instant('schoolAdministration.settingsDropdown.adminView').substring(0, 1).toUpperCase();
  }

  getTeacherInitial(): string {

    return this.tSv.instant('schoolAdministration.settingsDropdown.teacherView').substring(0, 1).toUpperCase();
  }

  onTeacherViewClicked() {
    this.router.navigate(['selectstudent']);
  }

  onAdminViewClicked() {
    this.router.navigate(['schooladmin', 'licenses']);
  }

}
