import { Component, OnInit } from '@angular/core';
import {EvoCreateGroupModalService} from './evo-create-group-modal.service';

@Component({
  selector: 'app-evo-create-group-modal',
  templateUrl: './evo-create-group-modal.component.html',
  styleUrls: ['./evo-create-group-modal.component.css']
})
export class EvoCreateGroupModalComponent implements OnInit {

  constructor(public createGroupSv: EvoCreateGroupModalService) { }

  ngOnInit() {
  }

}
