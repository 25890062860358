import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Route} from '@angular/router';
import {AdminSchoolService} from '../../../../services/admin/admin-school.service';
import {SchoolModel} from '../../../../models/school/school.model';
import {PricingModel} from '../../../../models/localization/pricing.model';
import {ModalService} from '../../../../shared/modals/modal/modal.service';
import {ErrorModel} from '../../../../models/shared/error.model';
import {UserModel} from '../../../../models/authentication/user.model';
import {SchoolLicenseModel} from '../../../../models/school/school-license.model';
import {ChartDataSets} from 'chart.js';
import {TranslateService} from '@ngx-translate/core';
import {NumberExtensionModel} from '../../../../models/extensions/number-extension.model';
import {CultureModel} from '../../../../models/localization/culture.model';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-evo-admin-school-licenses',
  templateUrl: './evo-admin-school-licenses.component.html',
  styleUrls: ['./evo-admin-school-licenses.component.css']
})
export class EvoAdminSchoolLicensesComponent implements OnInit {

  schoolId: string;
  school: SchoolModel;
  license: SchoolLicenseModel = new SchoolLicenseModel();
  Currencies = PricingModel.currenciesDropdown();
  updatingLicenses = false;

  count: number;

  chartTitle = '';
  labels: string[] = [this.tSv.instant('schoolAdministration.licenses.available')];
  chartData: ChartDataSets[] = [{
    data: [0],
    backgroundColor: ['#F8F9F9']
  }];

  constructor(private route: ActivatedRoute,
              private tSv: TranslateService,
              private modalSv: ModalService,
              private schoolSv: AdminSchoolService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.schoolId = params.schoolId;
      this.fetchSchool();

    });

  }

  fetchSchool() {

    this.schoolSv.fetchSchoolById(this.schoolId).subscribe((school) => {
      this.school = school;
    });

    this.schoolSv.fetchActiveLicenses(this.schoolId).subscribe((response) => {

      this.license = response.license;

      if (this.license && this.license.numLicenses > 0 && this.license.activeLicenses > 0) {

        this.labels = [
          this.tSv.instant('schoolAdministration.licenses.assigned'),
          this.tSv.instant('schoolAdministration.licenses.available')
        ];

        this.chartTitle = this.license.numLicenses.toString(10);

        const activeLicenses = this.license.activeLicenses;
        let availableLicenses = this.license.numLicenses - this.license.activeLicenses;
        if (availableLicenses < 0) {
          availableLicenses = 0;
        }

        this.chartData = [{
          data: [activeLicenses, availableLicenses],
          backgroundColor: ['#79C570', '#F8F9F9']
        }];

      } else {

        this.labels = [
          this.tSv.instant('schoolAdministration.licenses.available')
        ];

        this.chartTitle = '0';

        this.chartData = [{
          data: [0],
          backgroundColor: ['#F8F9F9']
        }];

      }

    });


  }

  getNumActiveLicenses(): string {

    if (this.license && this.license.activeLicenses) {
      return this.license.activeLicenses.toString();
    }

    return '0';
  }

  getAvailableLicenses(): string {

    if (this.license) {

      if (this.license.numLicenses === null || this.license.numLicenses === undefined) {

        return '0';
      }

      let availableLicenses = this.license.numLicenses - this.license.activeLicenses;
      if (availableLicenses < 0) {
        availableLicenses = 0;
      }

      return availableLicenses.toString(10);
    }

    return '0';
  }

  getSchoolLicenseType(license: SchoolLicenseModel): string {

    if (license.pricePerStudent == null || license.pricePerStudent <= 0) {
      return this.tSv.instant('schoolAdministration.licenses.licenseTypes.trial');
    } else {
      return this.tSv.instant('schoolAdministration.licenses.licenseTypes.full');
    }

  }

  getSchoolPricePerStudent(license: SchoolLicenseModel): string {

    if (license && license.pricePerStudent) {

      return NumberExtensionModel.delimiterSeparatedNumber(license.pricePerStudent, CultureModel.numberDelimiterForCulture(UserModel.getCurrent().culture), 0) + ' ' + PricingModel.symbolFromCurrency(license.currency);
    }

    return '0';
  }

  getSchoolTotalCost(license: SchoolLicenseModel): string {

    if (license && license.pricePerStudent && license.numLicenses) {

      const value = license.pricePerStudent * license.numLicenses;

      return NumberExtensionModel.delimiterSeparatedNumber(value, CultureModel.numberDelimiterForCulture(UserModel.getCurrent().culture)) + ' ' + PricingModel.symbolFromCurrency(this.license.currency);
    }

    return '0';
  }

  getNumLicenses() {

    if (this.school) {

      return this.school.numLicenses;
    }

    return null;
  }

  getPricingPerStudent() {

    if (this.school) {

      return this.school.pricePerStudent;
    }

    return null;
  }

  getLicenseStart(): string {

    if (this.license && this.license.licenseStartsAt) {

      const date = DateTime.fromJSDate(this.license.licenseStartsAt);

      return date.toFormat('dd / MM / yyyy');

    }

    return '';

  }

  getLicenseEnd(): string {

    if (this.license && this.license.licenseExpiresAt) {

      const date = DateTime.fromJSDate(this.license.licenseExpiresAt);

      return date.toFormat('dd / MM / yyyy');
    }

    return '';

  }

  getLicenseStartAt() {

    if (this.school) {

      return this.school.licenseStartsAt;
    }

    return null;
  }

  getLicenseEndAt() {

    if (this.school) {
      return this.school.licenseExpiresAt;
    }

    return null;
  }

  getCurrency() {

    if (this.school) {
      return this.school.currency;
    }

    return null;
  }

  onSaveClicked() {
    this.updatingLicenses = true;
    this.schoolSv.updateSchool(this.school).subscribe((school) => {
      this.school = school;
      this.updatingLicenses = false;
      this.modalSv.showAlertModal('Success', 'Licenses successfully updated');
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
      this.updatingLicenses = false;
    });
  }

}
