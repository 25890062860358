import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-evo-segmented-control',
  templateUrl: './evo-segmented-control.component.html',
  styleUrls: ['./evo-segmented-control.component.css']
})
export class EvoSegmentedControlComponent implements OnInit {

  _list: EVOSegmentedControlItem[] = [];
  @Input('list')
  set list(list: EVOSegmentedControlItem[]) {
    this._list = list;
    // TODO check if the current item is actually in the list as well?
    if (list.length > 0 && !this.currentItem) {
      this.currentItem = list[0];
    }
  }

  get list(): EVOSegmentedControlItem[] {
    return this._list;
  }

  @Input() currentItem: EVOSegmentedControlItem;

  @Output() valueChanged = new EventEmitter<EVOSegmentedControlItem>();

  constructor() { }

  ngOnInit() {
  }

  onClick(item: EVOSegmentedControlItem) {

    this.currentItem = item;

    if (this.valueChanged) {
      this.valueChanged.emit(this.currentItem);
    }

  }

}

export class EVOSegmentedControlItem {

  constructor(key: string, translationKey: string) {
    this.key = key;
    this.translationKey = translationKey;
  }

  key: string;
  translationKey: string;

}
