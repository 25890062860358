import {Component, Input, OnInit} from '@angular/core';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {PerimeterFromShapeModel} from '../../../../../models/questions/subModels/perimeterFromShapeModel';
import {EvoListSelectorService} from '../../../../../shared/list-views/evo-list-selector/evo-list-selector.service';
import {AnswerContainerComponent} from '../../../../../platform/pages/answers/answer-container/answer-container.component';

@Component({
  selector: 'app-evo-perimeter-from-shape',
  templateUrl: './evo-perimeter-from-shape.component.html',
  styleUrls: ['./evo-perimeter-from-shape.component.css']
})
export class EvoPerimeterFromShapeComponent implements OnInit {

  AnswerContainer = AnswerContainerComponent;

  @Input() question: QuestionModel = new PerimeterFromShapeModel();

  constructor(private selectorSv: EvoListSelectorService) { }

  ngOnInit() {
  }

  castedQuestion(): PerimeterFromShapeModel {

    return <PerimeterFromShapeModel>this.question;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.perimeterFromShape) {
        return true;
      }
    }

    return false;

  }

  removeShape(index: number) {
    if (index < this.castedQuestion().model.shapes.length) {
      this.castedQuestion().model.shapes.splice(index, 1);
    }
  }

  showSelector() {
    this.selectorSv.showList(QuestionModel.PerimeterShapeTypes.list(),
      this.castedQuestion().model.shapes).subscribe((shape) => {
       if (shape) {
         this.castedQuestion().model.shapes.push(shape);
       }
    });
  }

}
