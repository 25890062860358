import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StudentModel} from '../../../models/authentication/student.model';
import {SchoolAdminStudentService} from '../../../services/school/school-admin-student.service';
import {Observable, Subscriber} from 'rxjs';
import {ErrorModel} from '../../../models/shared/error.model';
import {ModalService} from '../modal/modal.service';
import {SchoolAdminGroupService} from '../../../services/school/school-admin-group.service';

@Injectable({
  providedIn: 'root'
})
export class EvoSchoolAdminCreateStudentModalService {

  student = new StudentModel();
  isVisible = false;

  schoolId: string;
  groupId: string;

  observer: Subscriber<StudentModel>;

  constructor(private tSv: TranslateService,
              private modalSv: ModalService,
              private studentSv: SchoolAdminStudentService,
              private groupSv: SchoolAdminGroupService) { }


  /**
   * Sets up a modal to create a student. If the groupId is provided for the student the
   * student is automatically added to the group.
   *
   * @param schoolId the id of the school.
   * @param groupId the group id if it sohuld also be added to a group.
   * @param culture the culture
   */
  onCreate(culture: string, schoolId: string, groupId: string = null): Observable<StudentModel> {

    this.isVisible = true;
    this.schoolId = schoolId;
    this.groupId = groupId;

    this.student = new StudentModel();
    this.student.culture = culture;

    const observable = new Observable((observer: Subscriber<StudentModel>) => {
      this.observer = observer;
    });

    return observable;

  }

  createStudentClicked() {

    if (!this.student.firstName || this.student.firstName.length < 0) {
      return this.modalSv.showErrorModal(this.tSv.instant('reusable.error'), this.tSv.instant('createStudent.firstNameRequired'));
    }

    this.studentSv.createStudent(this.student).subscribe((newStudent) => {

      this.student = newStudent;

      if (this.groupId) {
        this.groupSv.addStudentToGroup(this.groupId, newStudent._id).subscribe(() => {
          this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.success'));
          this.onComplete();
        }, (err: ErrorModel) => {
          this.modalSv.showTranslatedErrorModal(ErrorModel.GetError(err.name, this.tSv));
        });

      } else {
        this.onComplete();
        this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.success'));
      }

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(ErrorModel.GetError(err.name, this.tSv));
    });
  }

  onComplete() {
    this.student = new StudentModel();
    this.observer.next(this.student);
    this.observer.complete();
    this.isVisible = false;
  }

  onHide() {
    this.isVisible = false;
    this.student = new StudentModel();
    this.observer.next(null);
    this.observer.complete();
  }

}
