import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EVOSegmentedControlItem} from '../../../../shared/reusable-controls/evo-segmented-control/evo-segmented-control.component';
import {ChartData, ChartDataSets, ChartOptions, ChartTooltipItem} from 'chart.js';
import {BaseChartDirective, Label} from 'ng2-charts';
import {TranslateService} from '@ngx-translate/core';
import {DropdownModel} from '../../../../models/component/dropdown.model';
import {SessionActivityModel} from '../../../../models/student/session.model';
import {SchoolGroupsService} from '../../../../services/school/school-groups.service';
import {GroupModel} from '../../../../models/school/group.model';
import {StringExtensionModel} from '../../../../models/extensions/string-extension.model';
import {AnswerActivityModel} from '../../../../models/student/answer.model';
import {DateExtensionModel} from '../../../../models/extensions/date-extension.model';

@Component({
  selector: 'app-group-dash-activity',
  templateUrl: './group-dash-activity.component.html',
  styleUrls: ['./group-dash-activity.component.css']
})
export class GroupDashActivityComponent implements OnInit {

  static tSv: TranslateService;
  static firstDay: Date;

  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective | undefined;
  today: Date = new Date();
  firstDay: Date = new Date();
  lastDay: Date = new Date();
  nbDaysToShow: number = null;

  sessions: SessionActivityModel[] = [];
  answers: AnswerActivityModel[] = [];

  @Input() numStudents = 0;
  numAnswers = 0;
  timePlayed = 0;

  groupId: string;

  maxScale = 40;

  // Time options
  optionsTime = [{
    key: 'thisW',
    value: this.tSv.instant('dashboard.chart.thisWeek'),
    iconURL: null
  }, {
    key: 'lastW',
    value: this.tSv.instant('dashboard.chart.lastWeek'),
    iconURL: null
  }, {
    key: 'lastM',
    value: this.tSv.instant('dashboard.chart.lastMonth'),
    iconURL: null
  }, {
    key: 'thisM',
    value: this.tSv.instant('dashboard.chart.thisMonth'),
    iconURL: null
  }];
  optionTimeSelected = new DropdownModel('thisW', null);

  // Top reusable-controls
  controls: EVOSegmentedControlItem[] = [
    new EVOSegmentedControlItem('time', 'recentActivity.time'),
    new EVOSegmentedControlItem('answers', 'recentActivity.answers')
  ];
  selectedControl = this.controls[0];

  /** Chart Information **/
  barChartLabels: Label[] = [];

  barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
      position: 'top',
      labels: {
        boxWidth: 25,
        fontFamily: '""Montserrat", sans-serif',
        fontStyle: '600',
        fontColor: '#80949A',
        fontSize: 10
      }
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          color: '#D8D7D7',
          zeroLineWidth: 2
        },
        ticks: {fontSize: 10, fontFamily: '"Montserrat", sans-serif', fontStyle: '600', fontColor: '#80949A'}
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          color: '#D8D7D7',
          zeroLineColor: '#D8D7D7',
          zeroLineWidth: 2
        },
        ticks: {
          stepSize: this.maxScale / 5,
          max: 40,
          beginAtZero: true,
          fontFamily: '"Montserrat", sans-serif',
          fontStyle: '600',
          fontColor: '#80949A',
          fontSize: 10,
          padding: 4
        }
      }]
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: this.toolTipTitle,
        title: this.title
      }
    }
  };

  /**
   * Time Information
   */
  barDataset: ChartDataSets[] = [{
    data: [],
    backgroundColor: '#b3e0ee',
    borderColor: '#7bcae2',
    hoverBackgroundColor: '#7bcae2',
    hoverBorderColor: '#b3e0ee',
    borderWidth: 1
  }];

  constructor(private tSv: TranslateService,
              private sGroupSv: SchoolGroupsService) { }

  ngOnInit() {
    GroupDashActivityComponent.tSv = this.tSv;
    this.updateChart();
  }

  dropdownIntervalChanged(key: string) {
    this.optionTimeSelected.key = key;
    this.updateChart();
  }

  selectionChanged(item: EVOSegmentedControlItem) {
    this.selectedControl = item;
    this.updateChart();
  }

  getTotalNumAnswers() {

    return this.numAnswers.toFixed(0);

  }

  getAvgNumAnswers() {

    if (this.numStudents > 0) {
      return (this.numAnswers / this.numStudents).toFixed(0);
    }

    return this.numAnswers.toFixed(0);

  }

  getTotalTimePlayed() {

    return StringExtensionModel.timeStringDayHoursMinutes(this.timePlayed, this.tSv);

  }

  getAvgTimePlayed() {

    if (this.numStudents > 0) {

      const time = this.timePlayed / this.numStudents;

      return StringExtensionModel.timeStringDayHoursMinutes(time, this.tSv);
    }

    return this.getTotalTimePlayed();

  }

  reInit() {
    this.barDataset[0].data = [];
    this.barChartLabels = [];
    this.sessions = [];
    this.answers = [];
  }

  setupDates() {

    this.firstDay = new Date();
    this.lastDay = new Date();
    // By default, we show the equivalent of one week of data
    // The week days start at 0 so the value should be <= 6
    this.nbDaysToShow = 7;

    if (this.optionTimeSelected.key === 'thisW') {

      // We want to start at the beginning of the week, so we subtract the days of the week which
      // start at 0 so we have to add one to make it start ona monday
      this.firstDay.setDate(this.today.getDate() - this.today.getDay() + 1);
      // Do the same thing but find the end of that same week
      this.lastDay.setDate((this.today.getDate() + 7) - this.today.getDay() + 1);

    } else if (this.optionTimeSelected.key === 'lastW') {

      // Same logic as before but 7 days behind as it is the week before.
      this.firstDay.setDate((this.today.getDate() - 7) - this.today.getDay() + 1);
      this.lastDay.setDate(this.today.getDate() - this.today.getDay() + 1);

    } else if (this.optionTimeSelected.key === 'lastM') {

      // first day of the last month
      this.firstDay = new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1);
      // We go to the next month and set the date component to 0 to find the last day of the month before
      // to know how many days are in that month.
      this.nbDaysToShow = (new Date(this.lastDay.getFullYear(), this.today.getMonth(), 0)).getDate();
      // Set the last day
      this.lastDay = new Date(this.firstDay.getFullYear(), this.today.getMonth() - 1, this.nbDaysToShow);

    } else if (this.optionTimeSelected.key === 'thisM') {

      // Get the first day of the current month
      this.firstDay = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
      // Gets the number of days in the month by setting the date component to 0 for the next month
      this.nbDaysToShow = (new Date(this.lastDay.getFullYear(), this.today.getMonth() + 1, 0)).getDate();
      // Set the last day to the end of the current month
      this.lastDay = new Date(this.firstDay.getFullYear(), this.today.getMonth(), this.nbDaysToShow);

    }

    // Set the hour, minute and second components.
    this.firstDay.setHours(0, 0, 0);
    this.lastDay.setHours(23, 59, 59);

    GroupDashActivityComponent.firstDay = this.firstDay;

  }


  updateChart() {

    if (GroupModel.getCurrent()) {

      this.groupId = GroupModel.getCurrent()._id;

      /**
       * Re initializes all the bar data so we can set it up anew.
       */
      this.reInit();

      /**
       * Sets up the date range for the chart based on the
       * selection
       */
      this.setupDates();

      if (this.selectedControl.key === 'time') {

        this.sGroupSv.fetchGroupedSessionActivity(this.groupId,
          SessionActivityModel.SessionIntervals.Day,
          this.firstDay,
          this.lastDay,
          1).subscribe((data) => {
          this.sessions = data;
          this.setupBarData();
        });

      } else if (this.selectedControl.key === 'answers') {

        this.sGroupSv.fetchGroupAnswerActivity(this.groupId,
          SessionActivityModel.SessionIntervals.Day,
          this.firstDay,
          this.lastDay,
          1).subscribe((data) => {
          this.answers = data;
          this.setupBarData();
        });

      }

      /**
       * Fetches the total amount of answers for the group
       */
      this.sGroupSv.fetchGroupNumAnswers(this.groupId, this.firstDay, this.lastDay).subscribe((numAnswers) => {
        this.numAnswers = numAnswers;
      });

      /**
       * Fetches the total amount of time played by
       * the students
       */
      this.sGroupSv.fetchGroupedSessionActivity(this.groupId, null, this.firstDay, this.lastDay, 1).subscribe((sessions) => {

        if (sessions.length > 0) {
          this.timePlayed = sessions[0].sum;
        }

      });

    }



  }


  /**
   * Time Bar Data
   */

  setupBarData() {

    /**
     * Dynamic scale for the max value of the y axis and x axis
     */
    this.calculateYScale();

    /**
     * Setup labels at the bottom of x axis
     */
    this.setupXAxisLabels();

    /**
     * Setup the bar chart data based on answers
     */
    this.setupBarChartData();

    /**
     * Update the bar chart data.
     */
    this.chart.chart.options = this.barChartOptions;
    this.chart.update();

  }

  calculateYScale() {

    if (this.selectedControl.key === 'time') {

      this.maxScale = 100;

      this.sessions.forEach((session) => {
        if ((session.sum / 60) > this.maxScale) {
          this.maxScale = Math.ceil(((session.sum / 60) / 10)) * 10;
        }
      });

      /*
      const remainder = this.maxScale % 50;
      console.log('max scale: ' + this.maxScale);
      console.log('remainder: ' + remainder);
      if (remainder !== 0) {
        this.maxScale = this.maxScale - remainder + 50;
      }

      console.log('max scale after: ' + this.maxScale);
       */

    } else if (this.selectedControl.key === 'answers') {

      this.maxScale = 40;
      this.answers.forEach((a) => {
        if (a.sum > this.maxScale) {
          this.maxScale = a.sum;
        }
      });

    }

    this.barChartOptions.scales.yAxes[0].ticks.max = this.maxScale;
    this.barChartOptions.scales.yAxes[0].ticks.stepSize = this.maxScale / 5;

  }

  setupBarChartData() {

    for (let i = 0; i < this.nbDaysToShow; i++) {

      const currentDate = new Date(this.firstDay.getFullYear(), this.firstDay.getMonth(), this.firstDay.getDate() + i);

      if (this.selectedControl.key === 'time') {

        this.barDataset[0].label = 'time';

        const barValue = this.sessions.find((s) => {

          return s._id.year === currentDate.getFullYear() &&
            s._id.month === currentDate.getMonth() + 1 &&
            s._id.dayOfMonth === currentDate.getDate();
        });

        if (barValue === undefined) {
          this.barDataset[0].data.push(0);
        } else {
          const value = Number((barValue.sum / 60.0).toFixed(2));
          this.barDataset[0].data.push(value);
        }

      } else if (this.selectedControl.key === 'answers') {

        this.barDataset[0].label = 'answers';

        const barValue = this.answers.find((a) => {

          return a._id.year === currentDate.getFullYear() &&
            a._id.month === currentDate.getMonth() + 1 &&
            a._id.dayOfMonth === currentDate.getDate();
        });

        if (barValue === undefined) {
          this.barDataset[0].data.push(0);
        } else {
          this.barDataset[0].data.push(barValue.sum);
        }

      }


    }

  }

  /**
   * Shared data
   */

  setupXAxisLabels() {

    if (this.optionTimeSelected.key === 'thisW' ||
      this.optionTimeSelected.key === 'lastW') {

      // For week based activity
      this.barChartLabels = [
        this.tSv.instant('dayNames.1'),
        this.tSv.instant('dayNames.2'),
        this.tSv.instant('dayNames.3'),
        this.tSv.instant('dayNames.4'),
        this.tSv.instant('dayNames.5'),
        this.tSv.instant('dayNames.6'),
        this.tSv.instant('dayNames.0')
      ];

    } else if (this.optionTimeSelected.key === 'thisM' ||
      this.optionTimeSelected.key === 'lastM') {

      for (let i = 0; i < this.nbDaysToShow; i++) {
        const currentDate = new Date(this.firstDay.getFullYear(), this.firstDay.getMonth(), this.firstDay.getDate() + i);
        this.barChartLabels.push( String(currentDate.getDate()) );
      }

    }

  }

  title(items: ChartTooltipItem[], data: ChartData): string {

    const tSv = GroupDashActivityComponent.tSv;
    const item = items[0];

    // The day of the month / week
    const chartIndex = Number(item.index);

    // To get the month where the activity is started
    const firstDay = GroupDashActivityComponent.firstDay;

    // When we have weekly view it can cross months
    const currentDate = DateExtensionModel.addDays(firstDay, chartIndex);

    return tSv.instant('dayShortNames.' + currentDate.getDay()) + ' ' + currentDate.getDate().toString(10) + '. ' + tSv.instant('monthNames.' + (currentDate.getMonth() + 1));

  }

  toolTipTitle(toolTipItems: ChartTooltipItem, data: ChartData): string {

    const toolTipString = toolTipItems.yLabel.toString();

    if (data.datasets[0].label === 'time') {

      const timeInSeconds = Number(toolTipString) * 60;

      // Needs to be in seconds
      return StringExtensionModel.timeStringDayHoursMinutes(timeInSeconds, GroupDashActivityComponent.tSv);

    } else if (data.datasets[0].label === 'answers') {

      const numAnswers = Number(toolTipString);

      return numAnswers.toString(10) + ' ' + GroupDashActivityComponent.tSv.instant('recentActivity.answers')

    }

    return toolTipItems.yLabel.toString();

  }

}
