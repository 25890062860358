import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentModel } from '../../../../../models/authentication/student.model';
import {StudentService} from '../../../../../services/student/student.service';
import {ErrorModel} from '../../../../../models/shared/error.model';
import {ModalService} from '../../../../../shared/modals/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {HelperModel} from '../../../../../models/extensions/helper.model';

@Component({
  selector: 'app-auth-settings',
  templateUrl: './auth-settings.component.html',
  styleUrls: ['./auth-settings.component.css']
})
export class AuthSettingsComponent implements OnInit {

  @ViewChild('qrimage', { static: false }) parent;
  student: StudentModel = StudentModel.getCurrent();
  currentPin = '';
  newPassword = '';
  confirmNewPassword = '';
  email = '';
  pinChecked = false;
  loading;

  constructor(private modalSv: ModalService,
              private tSv: TranslateService,
              private studentSv: StudentService) {
  }

  ngOnInit() {
  }

  onQRCodeEnable() {

    const qrCodeStatus = StudentModel.getCurrent().isQRCodeAuthEnabled;
    StudentModel.getCurrent().isQRCodeAuthEnabled = !qrCodeStatus;

    this.studentSv.updateStudent(StudentModel.getCurrent()).subscribe((student) => {
      StudentModel.setCurrent(student);
    }, (err: ErrorModel) => {
      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'), this.tSv.instant(''));
    });

  }

  hasQRCode() {

    if (!StudentModel.getCurrent().qrCodePassword) {

      return false;
    }

    return true;

  }

  isQRCodeEnabled() {

    return StudentModel.getCurrent().isQRCodeAuthEnabled;
  }

  newCode() {

    if (StudentModel.getCurrent()) {
      this.loading = true;
      this.studentSv.fetchQRCode().subscribe((student) => {
        StudentModel.setCurrent(student);
        this.loading = false;
      });
    }

  }

  saveAsImage(parent: any) {

    const parentElement = parent.el.nativeElement.querySelector('img').src;
    const blobData = HelperModel.convertBase64ToBlob(parentElement);

    const fileName = 'QRCode ' + StudentModel.getCurrent().fullName(this.tSv).replace('.', '') + '.png';

    HelperModel.saveAsImage(fileName, blobData);

  }

  isSavePinDisabled(): boolean {
    return !(this.currentPin.length === 4 && !isNaN(Number(this.currentPin)));
  }

  isSaveEmailDisabled(): boolean {
    // check email format
    const emailFormat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !(this.newPassword !== '' && this.confirmNewPassword !== '' && this.newPassword === this.confirmNewPassword && emailFormat.test(this.email));
  }

}
