import {Component, Input, OnInit} from '@angular/core';
import {SingleSubtractionModel} from '../../../../../models/questions/subModels/singleSubtraction.model';
import {QuestionModel} from '../../../../../models/questions/question.model';

@Component({
  selector: 'app-evo-single-subtraction',
  templateUrl: './evo-single-subtraction.component.html',
  styleUrls: ['./evo-single-subtraction.component.css']
})
export class EvoSingleSubtractionComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new SingleSubtractionModel();

  constructor() { }

  ngOnInit() {
  }

  castedQuestion(): SingleSubtractionModel {

    return <SingleSubtractionModel>this.question;
  }

  getOutcomeString(): string {

    const maxOutcome = this.castedQuestion().model.firstRange.minValue() - this.castedQuestion().model.secondRange.maxValue();
    const minOutcome = this.castedQuestion().model.secondRange.maxValue() - this.castedQuestion().model.secondRange.minValue();

    if (this.castedQuestion().model.maxOutcome !== null &&
      this.castedQuestion().model.minOutcome !== null) {

      return '[' + this.castedQuestion().model.minOutcome.toString(10) + ', ' +
        this.castedQuestion().model.maxOutcome.toString(10) + ']';

    } else if (this.castedQuestion().model.minOutcome !== null) {

      return '[' + this.castedQuestion().model.minOutcome.toString(10) + ', ' +
        maxOutcome + ']';

    } else if (this.castedQuestion().model.maxOutcome !== null) {

      return '[' + minOutcome + ', ' +
        this.castedQuestion().model.maxOutcome.toString(10) + ']';

    }

    let outcome = '[' + minOutcome.toString(10) + ', ';
    outcome += maxOutcome + ']';

    return outcome;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.singleSubtraction) {
        return true;
      }
    }

    return false;
  }

}
