import { Injectable } from '@angular/core';
import {AuthService} from '../auth.service';
import {Observable} from 'rxjs';
import {StudentModel} from '../../models/authentication/student.model';
import {map} from 'rxjs/operators';
import {SchoolModel} from '../../models/school/school.model';
import {StringExtensionModel} from '../../models/extensions/string-extension.model';
import {StudentFilterModel} from '../../models/filters/student-filter.model';

@Injectable({
  providedIn: 'root'
})
export class AdminSchoolStudentService {

  constructor(private authSv: AuthService) { }

  fetchSchoolStudents(schoolId: string, filter: AdminSchoolStudentFilter = null): Observable<StudentModel[]> {

    let url = '/admin/schools/' + schoolId + '/students';
    if (filter) {
      url += StringExtensionModel.queryString(filter);
    }

    return this.authSv.get(url).pipe(map((response) => {

      const students = StudentModel.generateModels(response.data);
      return students;

    }));

  }

  fetchSchoolStudentById(schoolId: string, studentId: string): Observable<StudentModel> {

    return this.authSv.get('/admin/schools/' + schoolId + '/students/' + studentId).pipe(map((response) => {

      const student = StudentModel.generateModel(response.data);
      return student;

    }));

  }

  fetchStudentsNotConnectedToTeacher(schoolId: string, teacherId: string, filter: StudentFilterModel = null): Observable<StudentModel[]> {

    let url = '/admin/schools/' + schoolId + '/teachers/' + teacherId + '/notIncludedStudents';
    if (filter) {
      url += StringExtensionModel.queryString(filter);
    }

    return this.authSv.get(url).pipe(map((response) => {

      const students = StudentModel.generateModels(response.data);
      return students;

    }));

  }

  updateSchoolStudents(schoolId: string, students: StudentModel[]): Observable<StudentModel[]> {

    return this.authSv.patch('/admin/schools/' + schoolId + '/students', { students }).pipe(map((response) => {

      const newStudents = StudentModel.generateModels(response.data);

      return newStudents;

    }));

  }

  createSchoolStudentsAndRelationships(schoolId: string, students: object[]): Observable<StudentModel[]> {

    return this.authSv.post('/admin/schools/' + schoolId + '/studentsAndRelationships', { students }).pipe(map((response) => {

      const createdStudents = StudentModel.generateModels(response.data);
      return createdStudents;

    }));

  }

  deleteSchoolStudents(schoolId: string, studentId: string): Observable<StudentModel> {

    return this.authSv.delete('/admin/schools/' + schoolId + '/students/' + studentId).pipe(map((response) => {

      const student = StudentModel.generateModel(response.data);
      return student;

    }));

  }

  newQRCode(schoolId: string, studentId: string): Observable<StudentModel> {

    let url = '/admin/schools/' + studentId;
    url += '/students/' + studentId;
    url += '/createQRCode';

    return this.authSv.patch(url, {}).pipe(map((response) => {

      const student = StudentModel.generateModel(response.data);
      return student;

    }));

  }

  changeSchool(schoolId: string, studentId: string, newSchoolId: string): Observable<StudentModel> {

    const url = `/admin/schools/${schoolId}/students/${studentId}/changeSchool`;

    return this.authSv.patch(url, { newSchoolId }).pipe(map((response) => {

      const student = StudentModel.generateModel(response.data);
      return student;

    }));

  }

}

export class AdminSchoolStudentFilter {

  name: string = undefined;

  limit = 20;
  skip = 0;

  sort = 1;

}
