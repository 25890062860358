import {Component, OnInit, ViewChild} from '@angular/core';
import {GroupModel} from '../../../models/school/group.model';
import {ActivatedRoute, Params} from '@angular/router';
import {SchoolGroupsService} from '../../../services/school/school-groups.service';
import {StudentModel} from '../../../models/authentication/student.model';
import {StudentFilterModel} from '../../../models/filters/student-filter.model';
import {SkillGroupModel} from '../../../models/edu-admin/skillGroup.model';
import {SkillGroupStructureModel} from '../../../models/student/skillGroupStatusModel';
import {forkJoin} from 'rxjs';
import {GroupDashSkillsOverviewComponent} from './group-dash-skills-overview/group-dash-skills-overview.component';
import {PlacementTestModel} from '../../../models/edu-admin/placement-test.model';
import {PlacementTestAttemptModel, PlacementTestGroupAttempt} from '../../../models/student/placement-test-attempt.model';

@Component({
  selector: 'app-group-dashboard',
  templateUrl: './group-dashboard.component.html',
  styleUrls: ['./group-dashboard.component.css']
})
export class GroupDashboardComponent implements OnInit {

  group: GroupModel;

  skillStatus: SkillGroupStructureModel;
  placementStatus: SkillGroupStructureModel;
  skillGroupsObjective: SkillGroupModel[] = [];
  skillGroupsPlacement: SkillGroupModel[] = [];

  placementTest: PlacementTestModel;
  attempts: PlacementTestGroupAttempt[];

  students: StudentModel[] = [];

  scrollOffset = 0;

  @ViewChild('scrollContainer', { static: true }) scrollContainer: HTMLDivElement;
  @ViewChild(GroupDashSkillsOverviewComponent, { static: true }) skillsContainer: GroupDashSkillsOverviewComponent;


  constructor(private route: ActivatedRoute,
              private schoolGroupSv: SchoolGroupsService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      const groupId = params.groupId;

      this.schoolGroupSv.fetchGroup(groupId).subscribe((g) => {
        this.group = g;
      });

      const studentFilter = new StudentFilterModel();
      studentFilter.limit = 100;
      const fetchStudents = this.schoolGroupSv.fetchStudentForGroup(groupId);
      const fetchGroupSkills = this.schoolGroupSv.fetchGroupSkills(groupId);
      const fetchPlacementTest = this.schoolGroupSv.fetchPlacementTestForGroup(groupId);


      forkJoin([
        fetchStudents,
        fetchGroupSkills,
        fetchPlacementTest
      ]).subscribe((data) => {

        this.students = data[0];
        this.skillGroupsObjective = data[1].skillGroups.sort((s1, s2) => s1.order - s2.order);

        this.skillStatus =  SkillGroupStructureModel.setupWithSkillStatus(this.students, this.skillGroupsObjective, data[1].skills);

        this.placementTest = data[2].placementTest;
        const skillGroupsInPlacement = this.placementTest.getSkillGroupIdsInUse();

        this.skillGroupsPlacement = this.skillGroupsObjective.filter((s) => skillGroupsInPlacement.includes(s._id)).sort((s1, s2) => s1.order - s2.order);
        this.placementStatus = SkillGroupStructureModel.setupWithPlacementTestAttempt(this.students, this.skillGroupsPlacement, data[2].attempts);

      });


    });

  }

  getNumStudents() {

    if (this.group) {
      return this.group.studentIds.length;
    }

  }

  scrollEvent(e) {

    this.skillsContainer.onScroll();

  }


}
