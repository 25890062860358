import {Component, Input, OnInit} from '@angular/core';
import {SkillGroupStructureModel} from '../../models/student/skillGroupStatusModel';
import {TranslateService} from '@ngx-translate/core';
import {LearningStates} from '../../models/student/skill.model';

@Component({
  selector: 'app-evo-skill-status-list-item',
  templateUrl: './evo-skill-status-list-item.component.html',
  styleUrls: ['./evo-skill-status-list-item.component.css']
})
export class EvoSkillStatusListItemComponent implements OnInit {

  @Input() skillStatus: SkillGroupStructureModel;
  /**
   * The type of progress item to show different learning states
   * notStarted
   * learning
   * practicing
   * mastering
   * mastered
   * problemAreas
   */
  @Input() learningState = 'notStarted';

  constructor(private tSv: TranslateService) { }

  ngOnInit() {
  }

  translatedTitle(): string {

    return this.tSv.instant('learningStates.' + this.learningState);

  }

  skillBoxStyling() {


    if (this.learningState === LearningStates.problemAreas) {

      return {
        backgroundColor: 'rgba(215, 33, 33, 0.3)',
        border: '2px solid #D72121'
      };

    } else if (this.learningState === LearningStates.learning) {

      return {
        backgroundColor: 'rgba(255, 203, 5, 0.3)',
        border: '2px solid #FFCB05'
      };

    } else if (this.learningState === LearningStates.practicing) {

      return {
        backgroundColor: 'rgba(155, 89, 182, 0.3)',
        border: '2px solid #9B59B6'
      };

    } else if (this.learningState === LearningStates.mastering) {

      return {
        backgroundColor: 'rgba(121, 197, 112, 0.3)',
        border: '2px solid #79C570'
      };

    } else if (this.learningState === LearningStates.mastered) {

      return {
        backgroundColor: 'rgba(0, 156, 204, 0.3)',
        border: '2px solid #009CCC'
      };

    }

    // Gray color
    return {
      backgroundColor: 'rgba(216, 216, 216, 0.3)',
      border: '2px solid #D8D8D8'
    };

  }

  learningStatePercentage(): string {

    if (this.learningState === LearningStates.problemAreas) {

      return this.skillStatus.getProblemAreasPercentageString();

    } else if (this.learningState === LearningStates.learning) {

      return this.skillStatus.getLearningPercentageString();

    } else if (this.learningState === LearningStates.practicing) {

      return this.skillStatus.getPracticingPercentageString();

    } else if (this.learningState === LearningStates.mastering) {

      return this.skillStatus.getMasteringPercentageString();

    } else if (this.learningState === LearningStates.mastered) {

      return this.skillStatus.getMasteredPercentageString();

    }

    return this.skillStatus.getNotStartedPercentageString(2);

  }

}
