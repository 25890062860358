import {TranslateService} from '@ngx-translate/core';

export class StringExtensionModel {

  /**
   * @description Creates a random string of a given length.
   *
   * @param length The length of the random string in number of characters.
   */
  static randomString(length: number): string {

    const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEGFHIJKLMNOPQRSTUVWXYZ1234567890';
    let randString = '';

    for (let i = 0; i < length; i++) {
      const randIndex = Math.random() * alphabet.length;
      randString += alphabet.substring(randIndex, randIndex + 1);
    }

    return randString;

  }

  static timeStringDayHoursMinutes(duration: number, tSv: TranslateService): string {

    if (duration < 60) {
      return '0';
    }

    const days = Math.floor(duration / (60 * 60 * 24));
    const hours = Math.floor(duration / (60 * 60)) % 24;
    const minutes = Math.floor(duration / 60) % 60;

    let timeString = '';
    if (days >= 1) {
      timeString += days.toString(10) + tSv.instant('dashboard.dayDelimeter') + ' ';
    }

    if (hours >= 1) {
      timeString += hours.toString(10) + tSv.instant('dashboard.hourDelimeter') + ' ';
    }

    timeString += minutes.toString(10) + tSv.instant('dashboard.minuteDelimeter');

    return timeString;

  }

  /**
   * @description Creates a string on the form HH delimeter mm delimeter ss for a duration in seconds.
   * @param duration The duration to change to a string
   * @param delimeter The delimeter between HH mm ss
   */
  static hoursMinutesSeconds(duration: number, delimeter: string): string {

    const hours = this.hours(duration);
    const minutes = Math.floor(this.minutes(duration) - hours * 60);
    const seconds = Math.floor(duration - hours * 60 * 60 - minutes * 60);

    return this.addZero(hours) + delimeter + this.addZero(minutes) + delimeter + this.addZero(seconds);
  }

  static days(duration: number): number {

    const days = Math.floor(duration / (24 * 60 * 60));

    return days;
  }

  static hours(duration: number): number {

    const hours = Math.floor(duration / (60 * 60));

    return hours;
  }

  static minutes(duration: number): number {

    const minutes = Math.floor( duration / 60);

    return minutes;

  }

  static addZero(duration: number): string {

    if (duration < 10 && duration > -1) {
      return '0' + duration.toString(10);
    }

    return duration.toString(10);

  }

  static copyStringToClipboard(value: string) {

    // Logic to copy for multiple browsers
    // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
    const textArea = document.createElement('textArea');
    (<HTMLTextAreaElement>textArea).value = value;
    document.body.appendChild(textArea);

    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      (<HTMLTextAreaElement>textArea).setSelectionRange(0, 999999);
    } else {
      (<HTMLTextAreaElement>textArea).select();
    }


    document.execCommand('copy');
    document.body.removeChild(textArea);

  }

  /**
   * Creates a string, with a comma separator, based on the given key.
   *
   * @param list An array containing objects
   * @param key The key to use for the comma separated string. Is null by default which just uses the value in the list.
   */
  static queryArrayString(list: any[], key: string = null) {

    let arrayString = '';

    if (!list) {
      return '';
    }

    for (let i = 0; i < list.length; i++) {

      let value;
      if (key === null) {
        value = list[i];
      } else {
        value = list[i][key];
      }

      arrayString += value;

      if (i !== list.length - 1) {
        arrayString += ',';
      }

    }

    return arrayString;

  }

  static queryString(obj: object): string {

    let query = '';

    if (obj === null || obj === undefined) {
      return '';
    }

    for (const key of Object.keys(obj)) {

      const value = obj[key];

      if (value !== null && value !== undefined) {

        let valueString = null;
        if (typeof value === 'boolean') {

          if (value === false) {
            valueString = '0';
          } else {
            valueString = '1';
          }

        } else if (typeof value === 'number') {
          valueString = value.toString(10);
        } else if (typeof value === 'string' && value !== 'none') {
          valueString = value;
        } else if (value instanceof Date) {
          valueString = value.toISOString();
        }

        if (valueString !== null && valueString !== undefined) {

          if (query.indexOf('?') < 0) {
            query += '?' + key;
            query += '=' + valueString;
          } else {
            query += '&' + key;
            query += '=' + valueString;
          }

        }

      }

    }

    return query;

  }

  static getInitials(value: string, numInitials: number): string {

    if (!value) {
      return '';
    }

    const values = value.split(' ');

    if (values.length === 0) {
      return '';
    }

    let initials = '';
    for (let i = 0; i < numInitials && i < values.length; i++) {
      if (values[i].length > 0) {
        initials += values[i].substring(0, 1);
      }
    }

    return initials;

  }

}
