import {Component, Input, OnInit} from '@angular/core';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {SingleDivisionModel} from '../../../../../models/questions/subModels/singleDivision.model';

@Component({
  selector: 'app-evo-single-division',
  templateUrl: './evo-single-division.component.html',
  styleUrls: ['./evo-single-division.component.css']
})
export class EvoSingleDivisionComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new SingleDivisionModel();

  constructor() { }

  ngOnInit() {
  }

  getCastedQuestion(): SingleDivisionModel {

    return <SingleDivisionModel>this.question;

  }

  getOutcomeString(): string {

    let outcome = '[';

    outcome += this.getCastedQuestion().model.firstRange.minValue() * this.getCastedQuestion().model.secondRange.minValue();
    outcome += ', ' + this.getCastedQuestion().model.firstRange.maxValue() * this.getCastedQuestion().model.secondRange.maxValue() + ']';

    return outcome;
  }

}
