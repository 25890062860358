import { Injectable } from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {
  PlacementTestModel,
  PlacementTestSectionModel,
  PlacementTestSectionQuestionModel
} from '../../../../../../models/edu-admin/placement-test.model';
import {StringExtensionModel} from '../../../../../../models/extensions/string-extension.model';
import {AdminQuestionSelectionListService} from '../../../../../shared/admin-question-selection-list/admin-question-selection-list.service';
import {QuestionModel} from '../../../../../../models/questions/question.model';
import {generateQuestion, generateQuestionList} from '../../../../../../models/questions/question-generator';
import {AdminPlacementTestService} from '../../../../../../services/admin/admin-placement-test.service';

@Injectable({
  providedIn: 'root'
})
export class AdminPlacementTestQuestionModalService {

  isVisible = false;
  subscriber: Subscriber<PlacementTestModel>;

  test: PlacementTestModel;
  section: PlacementTestSectionModel;
  sectionQ: PlacementTestSectionQuestionModel;

  constructor(public selectQSv: AdminQuestionSelectionListService,
              private placementSv: AdminPlacementTestService) { }

  show(test: PlacementTestModel, section: PlacementTestSectionModel, q: PlacementTestSectionQuestionModel): Observable<PlacementTestModel> {

    this.test = test;
    this.section = section;
    this.sectionQ = new PlacementTestSectionQuestionModel();
    this.sectionQ._id = q._id;
    this.sectionQ.questionId = q.questionId;
    this.sectionQ.question = generateQuestion(JSON.parse(JSON.stringify(q.question)));
    this.sectionQ.associatedQuestions = generateQuestionList(JSON.parse(JSON.stringify(q.associatedQuestions)));
    this.sectionQ.associatedQuestionIds = q.associatedQuestionIds.concat([]);

    this.isVisible = true;

    return new Observable((subscriber: Subscriber<PlacementTestModel>) => {
      this.subscriber = subscriber;
    });

  }

  onCloseClicked() {

    this.resetState();

  }

  onSavePlacementTestSectionQuestion(deleteSectionQ = false) {

    // The index of the section and ordered question which have changed
    const ci = this.findSectionAndQuestionIndexes(this.test);
    const parsedJson = JSON.parse(JSON.stringify(this.test));

    // have to set the associated question ids to the associated questions
    // so the models are populated
    parsedJson.sections[ci.sectionIndex].orderedQuestions[ci.questionIndex].associatedQuestionIds = this.sectionQ.associatedQuestions;

    const copiedTest = PlacementTestModel.generateModel(parsedJson);

    if (deleteSectionQ) {
      copiedTest.sections[ci.sectionIndex].orderedQuestions.splice(ci.questionIndex, 1);
    } else {
      copiedTest.sections[ci.sectionIndex].orderedQuestions.splice(ci.questionIndex, 1, this.sectionQ);
    }

    this.placementSv.updatePlacementTestSections(copiedTest, true).subscribe((placementTest) => {

      this.subscriber.next(placementTest);
      this.subscriber.complete();
      this.resetState();

    });


  }

  findSectionIndex(placementTest: PlacementTestModel): number {

    for (let i = 0; i < placementTest.sections.length; i++) {
      if (placementTest.sections[i]._id === this.section._id) {
        return i;
      }
    }

    return -1;
  }

  findSectionAndQuestionIndexes(placementTest: PlacementTestModel): { sectionIndex: number, questionIndex: number } {

    let sectionIndex = -1;
    let questionIndex = -1;
    for (let i = 0; i < placementTest.sections.length; i++) {

      const cpSection = placementTest.sections[i];
      if (cpSection._id === this.section._id) {
        sectionIndex = i;

        for (let j = 0; j < cpSection.orderedQuestions.length; j++) {

          const cpQuestion = cpSection.orderedQuestions[j];

          if (cpQuestion._id === this.sectionQ._id) {
            questionIndex = j;
            break;
          }

        }
      }
    }

    return { sectionIndex, questionIndex };

  }

  onAddAssociatedQuestion() {

    const excludedQIdsList = this.test.getQuestionIdsInUse();

    // we have to exclude the current section q as well
    // even though there may be duplicates as it is the
    // section being changed.
    excludedQIdsList.push(this.sectionQ.questionId);
    for (const qId of this.sectionQ.associatedQuestionIds) {
      excludedQIdsList.push(qId);
    }

    const excludedIdsString = StringExtensionModel.queryArrayString(excludedQIdsList);

    this.selectQSv.show(excludedIdsString).subscribe((questions) => {

      if (questions && questions.length > 0) {
        for (const q of questions) {
          this.sectionQ.associatedQuestionIds.push(q._id);
          this.sectionQ.associatedQuestions.push(q);
        }
      }

    });

  }

  onRemoveAssociatedQuestion(q: QuestionModel, index: number) {

    this.sectionQ.associatedQuestions.splice(index, 1);
    this.sectionQ.associatedQuestionIds.splice(index, 1);

  }

  resetState() {
    this.isVisible = false;
    this.test = null;
    this.section = null;
    this.sectionQ = null;
  }

}
