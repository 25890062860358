import { Component, OnInit } from '@angular/core';
import {SkillGroupModel} from '../../../../models/edu-admin/skillGroup.model';
import {QuestionModel} from '../../../../models/questions/question.model';
import {AdminSkillGroupService} from '../../../../services/admin/admin-skill-group.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ModalService} from '../../../../shared/modals/modal/modal.service';
import {ErrorModel} from '../../../../models/shared/error.model';
import {CultureModel} from '../../../../models/localization/culture.model';

@Component({
  selector: 'app-admin-skill-group-settings',
  templateUrl: './admin-skill-group-settings.component.html',
  styleUrls: ['./admin-skill-group-settings.component.css']
})
export class AdminSkillGroupSettingsComponent implements OnInit {

  skillGroup: SkillGroupModel;
  CategoryTypes = QuestionModel.CategoryTypes;
  updatingSkillGroup = false;

  CultureModel = CultureModel;
  password: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService,
              private skillGroupSv: AdminSkillGroupService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      const skillGroupId = params.skillGroupId;
      this.skillGroupSv.fetchSkillGroupById(skillGroupId).subscribe((skillGroup) => {

        this.skillGroup = skillGroup;

      });

    });

  }

  onSaveClicked() {

    this.updatingSkillGroup = true;
    this.skillGroupSv.updateSkillGroup(this.skillGroup).subscribe((skillGroup) => {

      this.updatingSkillGroup = false;
      this.skillGroup = skillGroup;
      this.modalSv.showAlertModal('Success', 'Skill group was updated');

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onDeleteSkillGroupClicked() {

    this.modalSv.showChoiceModal('Warning', 'You are about to delete a skill group, are you sure you want to proceed?').subscribe((result) => {
      if (result) {
        this.skillGroupSv.deleteSkillGroup(this.skillGroup._id, this.password).subscribe((skillGroup) => {
          this.modalSv.showAlertModal('Success', 'Skill group successfully deleted').subscribe(() => {
            this.router.navigate(['admin', 'skillGroups']);
          });
        }, (err: ErrorModel) => {
          this.modalSv.showTranslatedErrorModal(err);
        });
      }
    });

  }

}
