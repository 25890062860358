import {Component, Input, OnInit} from '@angular/core';
import {SkillGroupStructureModel} from '../../models/student/skillGroupStatusModel';
import {LearningStates} from '../../models/student/skill.model';

@Component({
  selector: 'app-evo-skill-status-list-view',
  templateUrl: './evo-skill-status-list-view.component.html',
  styleUrls: ['./evo-skill-status-list-view.component.css']
})
export class EvoSkillStatusListViewComponent implements OnInit {

  @Input() skillStatus: SkillGroupStructureModel;
  LearningStates = LearningStates;

  constructor() { }

  ngOnInit() {
  }

}
