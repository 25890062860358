import {RangeModel} from '../shared/range.model';
import {QuestionModel} from '../question.model';

export class FractionSetupFromImageModel extends QuestionModel {

  static MissingFactorTypes = {
    numerator: 'numerator',
    denominator: 'denominator',
    both: 'both',
    list: function() {
      return [this.numerator, this.denominator, this.both];
    },
    dropdownList: function () {

      const list = [];

      for (const key of this.list()) {
        list.push({ key: key, value: key, iconURL: null });
      }

      return list;

    }
  };

  model = {
    numeratorRange: new RangeModel(),
    denominatorRange: new RangeModel(),
    missingFactor: FractionSetupFromImageModel.MissingFactorTypes.numerator
  };

  static generateModel(json: any): FractionSetupFromImageModel {

    const q = new FractionSetupFromImageModel();

    if (json.model.numeratorRange) {
      q.model.numeratorRange = RangeModel.generateModel(json.model.numeratorRange);
    }

    if (json.model.denominatorRange) {
      q.model.denominatorRange = RangeModel.generateModel(json.model.denominatorRange);
    }

    q.model.missingFactor = json.model.missingFactor;

    return q;

  }

}
