import { Component, OnInit } from '@angular/core';
import {EvoSchoolAdminCreateStudentModalService} from './evo-school-admin-create-student-modal.service';

@Component({
  selector: 'app-evo-school-admin-create-student-modal',
  templateUrl: './evo-school-admin-create-student-modal.component.html',
  styleUrls: ['./evo-school-admin-create-student-modal.component.css']
})
export class EvoSchoolAdminCreateStudentModalComponent implements OnInit {

  constructor(public createService: EvoSchoolAdminCreateStudentModalService) { }

  ngOnInit() {
  }

}
