import {StudentModel} from '../authentication/student.model';

export class PlacementTestAttemptModel {

  _id: string;
  studentId: string;

  /**
   * The id of the assessment test this attempt is associated with.
   * All placement tests are a form of assessment. We will introduce
   * new ones in the future.
   */
  assessmentTestId: string;

  /**
   * The session identifier to find out which answers were associated with
   * the placement test
   */
  sessionId: string;

  /**
   * The date when the placement test was finished.
   */
  finishedAt: Date;

  /**
   * The ids of the answers associated with this placement test.
   */
  answerIds: string[];

  createdAt: Date;
  updatedAt: Date;

  /**
   * The sections the student went through in the placement test
   * and how they faired.
   */
  sections: PlacementTestAttemptSectionModel[] = [];

  /**
   * Indicates whether this is a placement test created by Evolytes (the system)
   * or a test made by a school or admin.
   */
  isSystemTest: boolean;

  /**
   * Indicates whether the assessment test was considered a placement test or not.
   */
  isPlacementTest: boolean;

  static generate(json: any): PlacementTestAttemptModel {

    const attempt = new PlacementTestAttemptModel();

    attempt._id = json._id;
    attempt.studentId = json.studentId;
    attempt.assessmentTestId = json.assessmentTestId;
    attempt.sessionId = json.sessionId;
    attempt.answerIds = json.answerIds;
    attempt.isSystemTest = json.isSystemTest;
    attempt.isPlacementTest = json.isPlacementTest;

    if (json.finishedAt) {
      attempt.finishedAt = new Date(json.finishedAt);
    }

    if (json.createdAt) {
      attempt.createdAt = new Date(json.createdAt);
    }

    if (json.updatedAt) {
      attempt.updatedAt = new Date(json.updatedAt);
    }

    if (json.sections) {
      attempt.sections = PlacementTestAttemptSectionModel.generateList(json.sections);
    }

    return attempt;

  }

}

export class PlacementTestAttemptSectionModel {

  _id: string;
  sectionId: string;
  orderedQuestions: PlacementTestAttemptSectionQuestionModel[] = [];

  static generate(json: any): PlacementTestAttemptSectionModel {

    const section = new PlacementTestAttemptSectionModel();

    section._id = json._id;
    section.sectionId = json.sectionId;
    if (json.orderedQuestions) {
      section.orderedQuestions = PlacementTestAttemptSectionQuestionModel.generateList(json.orderedQuestions);
    }

    return section;

  }

  static generateList(jsonList: any[]): PlacementTestAttemptSectionModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push(PlacementTestAttemptSectionModel.generate(json));
    }

    return list;

  }


}

export class PlacementTestAttemptSectionQuestionModel {

  _id: string;
  questionId: string;
  mastery = 0;
  numCorrect = 0;
  numWrong = 0;

  static generate(json: any): PlacementTestAttemptSectionQuestionModel {

    const sectionQuestion = new PlacementTestAttemptSectionQuestionModel();

    sectionQuestion._id = json._id;
    sectionQuestion.questionId = json.questionId;
    sectionQuestion.mastery = json.mastery;
    sectionQuestion.numCorrect = json.numCorrect;
    sectionQuestion.numWrong = json.numWrong;

    return sectionQuestion;

  }

  static generateList(jsonList: any[]): PlacementTestAttemptSectionQuestionModel[] {

    const list = [];
    for (const json of jsonList) {
      list.push(PlacementTestAttemptSectionQuestionModel.generate(json));
    }

    return list;

  }

}

/**
 * This model is to be used for endpoitns which fetch placement tests for a group of students
 *
 */
export class PlacementTestGroupAttempt {

  /**
   * Represent the student id.
   */
  _id: string;

  /**
   * The placement test associated with the student.
   */
  placementTestAttempt: PlacementTestAttemptModel;

  /**
   * The student model is not populated by the endpoint associated with
   * assessments. They are to be manually associated.
   */
  student: StudentModel;

  static generate(json: any): PlacementTestGroupAttempt {

    const groupAttempt = new PlacementTestGroupAttempt();

    groupAttempt._id = json._id;
    if (json.placementTest) {
      groupAttempt.placementTestAttempt = PlacementTestAttemptModel.generate(json.placementTest);
    }

    return groupAttempt;

  }

  static generateList(jsonList: any[]): PlacementTestGroupAttempt[] {

    const list = [];

    for (const json of jsonList) {

      list.push(PlacementTestGroupAttempt.generate(json));

    }

    return list;

  }

  findMastery(questionId: string): PlacementTestAttemptSectionQuestionModel {

    for (const section of this.placementTestAttempt.sections) {
      for (const q of section.orderedQuestions) {
        if (q.questionId === questionId) {
          return q;
        }
      }
    }

    return null;

  }

}
