import { Injectable } from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {PlacementTestFilter, PlacementTestModel} from '../../../models/edu-admin/placement-test.model';
import {AdminPlacementTestService} from '../../../services/admin/admin-placement-test.service';
import {EVOQuestionFilter} from '../../../services/admin/admin-question.service';

@Injectable({
  providedIn: 'root'
})
export class AdminPlacementTestSelectionListService {

  isVisible = false;
  subscriber: Subscriber<PlacementTestModel[]>;

  placementTests: PlacementTestModel[] = [];
  placementTestsToAdd: PlacementTestModel[] = [];

  filter: PlacementTestFilter = new PlacementTestFilter();
  hasMore = false;

  constructor(private placementSv: AdminPlacementTestService) { }

  show(country: string, filter: PlacementTestFilter = null): Observable<PlacementTestModel[]> {

    if (filter) {
      this.filter = filter;
    } else {
      this.filter = new PlacementTestFilter();
    }

    this.filter.limit = 20;
    this.filter.country = country;
    this.isVisible = true;

    this.fetchPlacementTests();

    return new Observable((subscriber: Subscriber<PlacementTestModel[]>) => {
      this.subscriber = subscriber;
    });

  }

  searchPlacementTests(name: string) {

    this.reset();
    this.hasMore = false;
    this.filter.name = name;

    this.fetchPlacementTests();

  }

  fetchPlacementTests() {

    this.placementSv.fetchPlacementTests(this.filter).subscribe((placements) => {
      this.placementTests = placements;

      for (const p of placements) {
        if (this.placementTests.filter((cp) => cp._id === p._id).length === 0) {
          this.placementTests.push(p);
        }
      }

      this.hasMore = false;
      if (placements.length === this.filter.limit) {
        this.hasMore = true;
      }

    });

  }

  close() {

    this.subscriber.next(null);
    this.subscriber.complete();
    this.reset();
    this.isVisible = false;

  }

  addPlacementTests() {

    this.subscriber.next(this.placementTestsToAdd);
    this.subscriber.complete();
    this.reset();
    this.isVisible = false;

  }

  reset() {

    this.placementTests = [];
    this.placementTestsToAdd = [];

    this.filter.name = null;
    this.filter.country = null;
    this.filter.skip = 0;
    this.filter.limit = 100;

  }

}
