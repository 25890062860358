import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GroupQueryModel} from '../../../../../../models/school/group.model';
import {EVOStudentListSort} from '../../../../../../shared/list-views/evo-student-list-view/evo-student-list-view.component';
import {ModalService} from '../../../../../../shared/modals/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {SchoolAdminGroupService} from '../../../../../../services/school/school-admin-group.service';
import {forkJoin} from 'rxjs';
import {ErrorModel} from '../../../../../../models/shared/error.model';
import {UserModel} from '../../../../../../models/authentication/user.model';
import {SchoolModel} from '../../../../../../models/school/school.model';

@Component({
  selector: 'app-school-admin-group-detail-add-teachers',
  templateUrl: './school-admin-group-detail-add-teachers.component.html',
  styleUrls: ['./school-admin-group-detail-add-teachers.component.css']
})
export class SchoolAdminGroupDetailAddTeachersComponent implements OnInit {

  _show = false;
  @Input('show')
  set show(show: boolean) {
    this._show = show;
    this.fetchTeachersForGroup();
  }

  get show(): boolean {
    return this._show;
  }

  _groupId: string;
  @Input('groupId')
  set groupId(groupId: string) {
    this._groupId = groupId;
    this.fetchTeachersForGroup();
  }

  get groupId(): string {
    return this._groupId;
  }

  hasMoreData = false;
  teachers: UserModel[] = [];
  selectedTeachers: UserModel[] = [];
  SchoolModel = SchoolModel;

  /**
   * Used when netowkring to disable the button
   */
  addingTeachers = false;

  @Output() close = new EventEmitter();
  @Output() teachersAdded = new EventEmitter();

  filter = new GroupQueryModel();

  sort = new EVOStudentListSort();

  constructor(private modalSv: ModalService,
              private tSv: TranslateService,
              private groupSv: SchoolAdminGroupService) { }

  ngOnInit() {
    this.filter.sort = 1;
    this.filter.limit = 20;
  }

  fetchTeachersForGroup() {

    if (this._groupId && this._show) {

      this.filter.skip = this.teachers.length;

      this.groupSv.fetchTeachersWithNoRelationshipToGroup(this.groupId, this.filter).subscribe((teachers) => {

        this.hasMoreData = teachers.length === this.filter.limit;

        for (const teacher of teachers) {
          if (this.teachers.filter((s) => s._id === teacher._id).length === 0) {
            this.teachers.push(teacher);
          }
        }

      });

    }

  }

  onSearchTeachers(teacherNameOrEmail: string) {

    if (teacherNameOrEmail === '' || !teacherNameOrEmail) {
      this.filter.name = null;
    } else {
      this.filter.name = teacherNameOrEmail;
    }

    this.teachers = [];
    this.selectedTeachers = [];

    this.fetchTeachersForGroup();

  }

  onSortChanged(sort: EVOStudentListSort) {

    this.filter.sort = sort.name;
    this.teachers = [];
    this.selectedTeachers = [];

    this.fetchTeachersForGroup();

  }

  onAddClicked() {

    this.addingTeachers = true;
    const teachersToAdd = [];
    for (const student of this.selectedTeachers) {
      teachersToAdd.push(this.groupSv.addTeacherToGroup(this.groupId, student._id));
    }

    forkJoin(teachersToAdd).subscribe((teachers) => {

      this.addingTeachers = false;
      this.selectedTeachers = [];
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.successMessage'));

      if (this.teachersAdded) {
        this.teachersAdded.emit();
      }

    }, (err: ErrorModel) => {
      this.addingTeachers = false;
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onCloseClicked() {
    this._show = false;
    if (this.close) {
      this.close.emit();
    }
  }

}
