import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BookVersionIntegrationModel, BookVersionModel } from '../../../../models/edu-admin/book-version.model';
import { BookExamAttemptModel } from '../../../../models/student/book-exam-attempt.model';
import { AnswerQueryModel } from '../../../../models/student/answer.model';
import {BookExamsService} from '../../../../services/student/book-exams.service';

@Component({
  selector: 'app-book-detail',
  templateUrl: './book-detail.component.html',
  styleUrls: ['./book-detail.component.css']
})
export class BookDetailComponent implements OnInit {
  versionId: string;
  currentIntegration: BookVersionIntegrationModel = null;
  currentBookVersion: BookVersionModel = null;
  currentAttempts: BookExamAttemptModel[] = [];
  currentAttempt: BookExamAttemptModel = null;
  notEmptyAttempts: Boolean = false;
  notEmptyAnswers: Boolean = false;
  notEmpty: Boolean = false;
  filter = new AnswerQueryModel();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private bookSelectSv: BookExamsService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.versionId = params.bookVersion;
    });
    this.bookSelectSv.fetchBookExamsGrouped().subscribe((bookVersions) => {

      const bookVersion = bookVersions.filter((v) => v._id === this.versionId)[0];

      this.currentBookVersion = bookVersion;
      this.currentBookVersion.integrations = bookVersion.integrations.sort((intOne, intTwo) => {
        return intOne.chapter - intTwo.chapter;
      });
      if (this.currentBookVersion !== null) {
        this.notEmpty = true;
      }
      this.route.queryParams.subscribe((params: Params) => {
        if (params.chapter !== undefined) {
          if (this.currentBookVersion !== undefined) {
            this.showAttemptsOfChapter(params.chapter);
            this.updateFilter(params.attempt);
            this.showAnswersOfChapter();
          }
        }
      });
    });

  }

  goBackBooks() {
    this.router.navigate(['platform', 'books']);
  }

  showAnswersOfChapter() {
    this.notEmptyAnswers = true;
  }

  showAttemptsOfChapter(chapter: string) {
    this.notEmptyAnswers = false;
    this.notEmptyAttempts = false;
    this.currentAttempt = null;
    this.currentAttempts = [];
    this.currentIntegration = this.currentBookVersion.integrations.find((integration) => {
      return integration._id === chapter;
    });
    if (this.currentIntegration.attempts.length > 0) {
      this.currentAttempts = this.currentIntegration.attempts;
      this.notEmptyAttempts = true;

    }
  }

  updateFilter(attemptId: string) {

    const filter = new AnswerQueryModel();
    filter.skip = 0;
    if (this.currentIntegration.attempts.length > 0) {
      this.currentAttempt = this.currentIntegration.attempts.find((attempt) => {
        return attempt._id === attemptId;
      });
      if (this.currentIntegration !== null) {
        filter.integrationId = this.currentIntegration._id;
        filter.sessionId = this.currentAttempt.sessionId;
      }
    }
    this.filter = filter;
  }
}
