import { Component, OnInit } from '@angular/core';
import {StudentModel} from '../../../../../../models/authentication/student.model';
import {StudentFilterModel} from '../../../../../../models/filters/student-filter.model';
import {AdminSchoolUserService} from '../../../../../../services/admin/admin-school-user.service';
import {ActivatedRoute, Params} from '@angular/router';
import {EvoAdminUserSelectStudentService} from '../evo-admin-user-select-student/evo-admin-user-select-student.service';
import {forkJoin} from 'rxjs';
import {ErrorModel} from '../../../../../../models/shared/error.model';
import {ModalService} from '../../../../../../shared/modals/modal/modal.service';

@Component({
  selector: 'app-evo-admin-school-teachers-edit-students',
  templateUrl: './evo-admin-school-teachers-edit-students.component.html',
  styleUrls: ['./evo-admin-school-teachers-edit-students.component.css']
})
export class EvoAdminSchoolTeachersEditStudentsComponent implements OnInit {

  hasMoreStudents = true;
  filter = new StudentFilterModel();
  students: StudentModel[] = [];

  selectedStudents: StudentModel[] = [];

  schoolId: string;
  userId: string;

  constructor(private route: ActivatedRoute,
              private modalSv: ModalService,
              private selectStudentSv: EvoAdminUserSelectStudentService,
              private adminSchoolStudentSv: AdminSchoolUserService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.schoolId = params.schoolId;
      this.userId = params.userId;

      this.filter.limit = 20;
      this.fetchStudents();

    });

  }

  showAddStudent() {
    this.selectStudentSv.show(this.schoolId, this.userId).subscribe(() => {

      this.students = [];
      this.fetchStudents();

    });
  }

  fetchStudents() {

    this.filter.skip = this.students.length;
    this.adminSchoolStudentSv.fetchSchoolUserStudents(this.schoolId, this.userId, this.filter).subscribe((students) => {
      this.students = this.students.concat(students);

      if (students.length === 0 || students.length < this.filter.limit) {
        this.hasMoreStudents = false;
      } else {
        this.hasMoreStudents = true;
      }

    });

  }

  searchFilteredStudents(searchString: string) {

    if (searchString !== null && searchString.length > 0) {
      this.filter.name = searchString;
    } else {
      this.filter.name = undefined;
    }

    this.students = [];

    this.fetchStudents();

  }

  removeStudents(students: StudentModel[]) {

    const relationshipsToRemove = [];
    for (const student of students) {
      const relationship = this.adminSchoolStudentSv.deleteSchoolUserStudentRelationshipByStudentId(this.schoolId, this.userId, student._id);
      relationshipsToRemove.push(relationship);
    }

    forkJoin(relationshipsToRemove).subscribe(() => {

      this.modalSv.showAlertModal('Success', 'The relationships were removed');
      this.students = [];
      this.selectedStudents = [];
      this.fetchStudents();

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    })


  }

}
