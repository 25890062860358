import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {CultureModel} from '../../../models/localization/culture.model';
import {TranslateService} from '@ngx-translate/core';
import {CountryModel} from '../../../models/localization/country.model';

@Component({
  selector: 'app-app-link',
  templateUrl: './app-link.component.html',
  styleUrls: ['./app-link.component.css']
})
export class AppLinkComponent implements OnInit {

  CultureModel = CultureModel;

  token = '';
  vendor = '';
  type = '';
  region = '';

  os = null;

  constructor(private route: ActivatedRoute,
              private tSv: TranslateService) { }

  ngOnInit() {

    this.route.queryParams.subscribe((params: Params) => {

      this.token = params.token;
      this.type = params.type;
      this.vendor = params.vendor;
      this.region = params.region;

      const culture = params.culture;

      // Only change the language if a translation has not been picked for
      // the homepage
      if (!CultureModel.getHomepageCulture() && culture) {

        if (CultureModel.list().includes(culture)) {
          CultureModel.setHomepageCulture(culture);
          CountryModel.setHomepageCountry(CountryModel.countryForCulture(culture));
          this.tSv.use(culture);
        }

      }

      console.log(`token: ${this.token} type: ${this.type} vendor: ${this.vendor}`);
      console.log('link is: ' + this.getLink());
    });

    this.os = this.checkOS();

  }

  getLink(): string {

    return `evolytesdl://launch?token=${this.token}&type=${this.type}&vendor=${this.vendor}&region=${this.region}`;
  }

  onLaunch() {

    // Fix to launch right app via deep link logic.
    window.location.href = this.getLink();

    let redirect = true;

    // Fix for the redirect: https://stackoverflow.com/questions/56528191/how-to-fix-deep-linking-in-ios-when-the-application-has-not-installed-in-the-dev
    // Handle event to check app installed or not
    window.onblur = function() {
      console.log('on blur');
      redirect = false;
    };

    window.onfocus = function() {
      console.log('on focus');
      redirect = true;
    };

    const fallbackUrl = this.fallbackURL();
    if (fallbackUrl) {
      setTimeout(function() {

        if (redirect) {
          // Link to the App Store should go here -- only fires if deep link fails
          window.location.href = fallbackUrl;
        }

      }, 500);
    }

  }


  fallbackURL() {

    if (this.os === 'Mac' || this.os === 'iOS') {
      return 'https://apps.apple.com/tt/app/evolytes/id1469773602';
    } else if (this.os === 'Android') {
      return `https://play.google.com/store/apps/details?id=com.Evolytes.Evolytes`;
    } else if (this.os === 'Windows') {
      // TODO: Update to our deep link
      return 'https://apps.microsoft.com/en-gb/detail/9wzdncrfj4q7';
    }

  }

  // How to check for platform
  checkOS(): string {

    // https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js

    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (macosPlatforms.indexOf(platform) !== -1) {
      return 'Mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      return 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'Windows';
    } else if (/Android/.test(userAgent)) {
      return 'Android';
    } else if (/Linux/.test(platform)) {
      return 'Linux';
    }

    return 'Unknown';

  }

  isiOSMacOrUnknown() {

    return this.os === 'Mac' || this.os === 'Windows' || this.os === 'Unknown';
  }

  isWindowsOrUnknown() {

    return this.os === 'Windows' || this.os === 'Unknown';
  }

  isAndroidOrUnknown() {

    return this.os === 'Android' || this.os === 'Unknown';
  }

}
