import {Component, Input, OnInit} from '@angular/core';
import {MultipleImageTypesModel} from '../../../../../models/questions/shared/multipleImageTypes.model';
import {
  EvoAbilityIconImages,
  EvoItemImages,
  EvoMonsterImages,
  ImageCategoryTypes, ImageTypeModel
} from '../../../../../models/questions/shared/imageType.model';

@Component({
  selector: 'app-evo-reusable-image-type',
  templateUrl: './evo-reusable-image-type.component.html',
  styleUrls: ['./evo-reusable-image-type.component.css']
})
export class EvoReusableImageTypeComponent implements OnInit {

  ImageCategoryTypes = ImageCategoryTypes;

  @Input() title = '';
  @Input() image: ImageTypeModel = new ImageTypeModel();

  propagateChange = (_: any) => {};

  constructor() { }

  ngOnInit() {
  }

  onCategoryChanged(cat: string) {

    if (cat !== this.image.category) {

      this.image.category = cat;
      // select a default value
      this.image.image = this.getCategoryImages()[0];

    }

  }

  getCategoryImages() {

    if (this.image) {

      if (this.image.category === ImageCategoryTypes.items) {
        return EvoItemImages.list();
      } else if (this.image.category === ImageCategoryTypes.monsters) {
        return EvoMonsterImages.list();
      } else if (this.image.category === ImageCategoryTypes.monsterThumbnails) {
        return EvoMonsterImages.list();
      } else if (this.image.category === ImageCategoryTypes.abilities) {
        return EvoAbilityIconImages.list();
      }

    }

  }

  getCategoryDropdownImages() {

    if (this.image) {

      if (this.image.category === ImageCategoryTypes.items) {
        return EvoItemImages.dropdownList();
      } else if (this.image.category === ImageCategoryTypes.monsters) {
        return EvoMonsterImages.dropdownList();
      } else if (this.image.category === ImageCategoryTypes.monsterThumbnails) {
        return EvoMonsterImages.thumbnailDropdownList();
      } else if (this.image.category === ImageCategoryTypes.abilities) {
        return EvoAbilityIconImages.dropdownList();
      }

    }

    return [];

  }

  writeValue(obj: any): void {

    if (obj !== null &&
      obj !== undefined &&
      obj instanceof ImageTypeModel) {
      this.image = obj;
      this.propagateChange(this.image);
    } else {
      this.propagateChange(this.image);
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

}
