import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';
import {SkillGroupFilter, SkillGroupModel} from '../../models/edu-admin/skillGroup.model';
import {QuestionModel} from '../../models/questions/question.model';
import {generateQuestionList} from '../../models/questions/question-generator';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StringExtensionModel} from '../../models/extensions/string-extension.model';
import {EVOQuestionFilter} from './admin-question.service';

@Injectable({
  providedIn: 'root'
})

export class AdminSkillGroupService {

  constructor(private authSv: AuthService) {
  }

  fetchSkillGroups(filter: SkillGroupFilter): Observable<SkillGroupModel[]> {

    let url = '/skillGroups';

    if (filter) {
      url += StringExtensionModel.queryString(filter);
    }

    return this.authSv.get(url).pipe(map((response) => {

      return SkillGroupModel.generateModels(response.data);

    }));
  }

  fetchSkillGroupById(skillGroupId: string): Observable<SkillGroupModel> {
    return this.authSv.get('/skillGroups/' + skillGroupId).pipe(map((response) => {
      return SkillGroupModel.generateModel(response.data);
    }));
  }


  createSkillGroup(skillGroup: SkillGroupModel): Observable<SkillGroupModel> {
    return this.authSv.post('/skillGroups', skillGroup).pipe(map((response) => {
      return SkillGroupModel.generateModel(response.data.skillGroup);
    }));
  }

  fetchOtherQuestions(skillGroupId: string, filter: EVOQuestionFilter = null): Observable<QuestionModel[]> {

    let url = '/skillGroups/' + skillGroupId + '/otherQuestions';
    if (filter) {
      url += StringExtensionModel.queryString(filter);
    }

    return this.authSv.get(url).pipe(map((response) => {
      return generateQuestionList(response.data);
    }));
  }

  updateSkillGroup(skillGroup: SkillGroupModel, populateQuestions = false): Observable<SkillGroupModel> {

    let url = '/skillGroups/' + skillGroup._id;
    if (populateQuestions) {
      url += '?populateQuestions=1';
    }

    return this.authSv.patch(url, skillGroup).pipe(map((response) => {
      return SkillGroupModel.generateModel(response.data);
    }));
  }

  deleteSkillGroup(skillGroupId: string, password: string): Observable<SkillGroupModel> {
    return this.authSv.post('/skillGroups/' + skillGroupId + '/delete', { password }).pipe(map((response) => {
      return SkillGroupModel.generateModel(response.data);
    }));
  }

  fetchSkillGroupsNotInList(list: string[]): Observable<SkillGroupModel[]> {
    return this.authSv.get('/skillGroups').pipe(map(skillGroupsReceived => {
      const skillGroups: SkillGroupModel[] = SkillGroupModel.generateModels(skillGroupsReceived.data);
      return skillGroups.filter(item => !list.includes(item._id));
    }));
  }
}
