import { Component, OnInit } from '@angular/core';
import {SchoolGroupsService} from '../../../services/school/school-groups.service';
import {ActivatedRoute, Params} from '@angular/router';
import {StudentFilterModel} from '../../../models/filters/student-filter.model';
import {forkJoin} from 'rxjs';
import {SkillGroupStructureModel} from '../../../models/student/skillGroupStatusModel';
import {StudentModel} from '../../../models/authentication/student.model';
import {SkillGroupModel} from '../../../models/edu-admin/skillGroup.model';
import {TranslateService} from '@ngx-translate/core';
import {StringExtensionModel} from '../../../models/extensions/string-extension.model';

@Component({
  selector: 'app-group-skills',
  templateUrl: './group-skills.component.html',
  styleUrls: ['./group-skills.component.css']
})
export class GroupSkillsComponent implements OnInit {

  groupId: string;

  students: StudentModel[] = [];
  skillGroups: SkillGroupModel[] = [];

  skillStatus: SkillGroupStructureModel;
  placementStatus: SkillGroupStructureModel;

  numAnswers = 0;
  timePlayed = 0;

  constructor(private route: ActivatedRoute,
              private schoolGroupSv: SchoolGroupsService,
              private tSv: TranslateService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.groupId = params.groupId;

      const studentFilter = new StudentFilterModel();
      studentFilter.limit = 100;
      const fetchStudents = this.schoolGroupSv.fetchStudentForGroup(this.groupId);
      const fetchGroupSkills = this.schoolGroupSv.fetchGroupSkills(this.groupId);
      const fetchPlacementTest = this.schoolGroupSv.fetchPlacementTestForGroup(this.groupId);

      forkJoin([
        fetchStudents,
        fetchGroupSkills,
        fetchPlacementTest
      ]).subscribe((data) => {

        this.students = data[0];
        this.skillGroups = data[1].skillGroups.sort((s1, s2) => s1.order - s2.order);

        this.skillStatus =  SkillGroupStructureModel.setupWithSkillStatus(this.students, this.skillGroups, data[1].skills);
        this.placementStatus = SkillGroupStructureModel.setupWithPlacementTestAttempt(this.students, this.skillGroups, data[2].attempts);

        this.fetchActivity();

      });

    });

  }

  fetchActivity() {

    const fetchNumAnswersForGroup = this.schoolGroupSv.fetchGroupNumAnswers(this.groupId);
    const fetchSessionsForGroup = this.schoolGroupSv.fetchGroupedSessionActivity(this.groupId);

    forkJoin([
      fetchNumAnswersForGroup,
      fetchSessionsForGroup
    ]).subscribe((data) => {

      this.numAnswers = data[0];
      this.timePlayed = data[1][0].sum;

    });

  }

  getTotalAnswers(): string {

    return this.numAnswers.toFixed(0);
  }

  getAvgAnswers(): string {

    if (this.students.length > 0) {
      return (this.numAnswers / this.students.length).toFixed(0);
    }

    return '0';

  }

  getTimePlayed(): string {

    return StringExtensionModel.timeStringDayHoursMinutes(this.timePlayed, this.tSv);
  }

  getAvgTimePlayed(): string {

    if (this.students.length > 0) {
      const avgTime = this.timePlayed / this.students.length;
      return StringExtensionModel.timeStringDayHoursMinutes(avgTime, this.tSv);
    }

    return '0';
  }

}
