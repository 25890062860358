import {Component, Input, OnInit} from '@angular/core';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {Translation2DModel} from '../../../../../models/questions/subModels/translation2D.model';

@Component({
  selector: 'app-evo-translate2d',
  templateUrl: './evo-translate2d.component.html',
  styleUrls: ['./evo-translate2d.component.css']
})
export class EvoTranslate2dComponent implements OnInit {

  translationTypeDropdownList = Translation2DModel.TranslationTypes.dropdownList();
  @Input() question: QuestionModel = new Translation2DModel();

  constructor() { }

  ngOnInit() {
  }

  castQuestion(): Translation2DModel {

    return <Translation2DModel>this.question;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.translation2D) {
        return true;
      }
    }

    return false;
  }

}
