import { Injectable } from '@angular/core';
import {AuthService} from '../auth.service';
import {Observable} from 'rxjs';
import {PlacementTestFilter, PlacementTestModel, PlacementTestSectionModel} from '../../models/edu-admin/placement-test.model';
import {StringExtensionModel} from '../../models/extensions/string-extension.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminPlacementTestService {

  constructor(private authSv: AuthService) { }

  fetchPlacementTests(filter: PlacementTestFilter): Observable<PlacementTestModel[]> {

    let url = `/admin/fetchPlacementTests`;

    if (filter) {
      url += StringExtensionModel.queryString(filter);
    }

    return this.authSv.get(url).pipe(map((response) => {

      return PlacementTestModel.generateList(response.data);

    }));

  }

  fetchPlacementTestById(testId: string, populate: boolean = false): Observable<PlacementTestModel> {

    const url = `/admin/fetchPlacementTests/${testId}` + StringExtensionModel.queryString({ populate });

    return this.authSv.get(url).pipe(map((response) => {

      return PlacementTestModel.generateModel(response.data);

    }));

  }

  createPlacementTest(placement: PlacementTestModel): Observable<PlacementTestModel> {

    const url = '/admin/createPlacementTest';

    return this.authSv.post(url, placement).pipe(map((response) => {

      return PlacementTestModel.generateModel(response.data);

    }));

  }

  /**
   * Does not update the sections which require separate logic. Can update other
   * parameters.
   *
   * @param placement The placement model to update.
   */
  updatePlacementTest(placement: PlacementTestModel): Observable<PlacementTestModel> {

    const url = `/admin/updatePlacementTest/${placement._id}`;

    return this.authSv.patch(url, placement).pipe(map((response) => {

      return PlacementTestModel.generateModel(response.data);

    }));

  }

  updatePlacementTestSections(placement: PlacementTestModel, populate: boolean = false): Observable<PlacementTestModel> {

    const url = `/admin/updatePlacementTestSection/${placement._id}` + StringExtensionModel.queryString({ populate });

    return this.authSv.patch(url, placement).pipe(map((response) => {

      return PlacementTestModel.generateModel(response.data);

    }));

  }

  updatePlacementTestSectionName(placement: PlacementTestModel, section: PlacementTestSectionModel): Observable<PlacementTestModel> {

    const url = `/admin/updatePlacementTestSectionName/${placement._id}`;

    return this.authSv.patch(url, section).pipe(map((response) => {

      return PlacementTestModel.generateModel(response.data);

    }));

  }

  archivePlacementTest(placementId: string, password: string, isArchived: boolean): Observable<PlacementTestModel> {

    const url = `/admin/placementTest/${placementId}/archive`;

    return this.authSv.patch(url, { password, isArchived }).pipe(map((response) => {

      const placementTest = PlacementTestModel.generateModel(response.data);
      return placementTest;

    }));

  }

  deletePlacementTest(placementId: string, password: string, secret: string): Observable<PlacementTestModel> {

    const url = `/admin/deletePlacementTest/${placementId}`;

    return this.authSv.post(url, { password, secret }).pipe(map((response) => {

      return PlacementTestModel.generateModel(response.data);

    }));

  }

}
