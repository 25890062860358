import {QuestionModel} from '../questions/question.model';
import {generateQuestionList} from '../questions/question-generator';
import {DescriptionModel} from '../shared/description.model';
import {StringExtensionModel} from '../extensions/string-extension.model';
import {UserModel} from '../authentication/user.model';

export class SkillGroupModel {

  public _id: string;

  public name: string;
  public translatedName = new DescriptionModel();
  public category: string;
  public questionIds: string[] = [];
  public questions: QuestionModel[] = [];
  public order: number;

  public createdAt: Date;
  public updatedAt: Date;

  static generateModel(json: any): SkillGroupModel {

    const skillGroup = new SkillGroupModel();
    skillGroup._id = json._id;
    skillGroup.category = json.category;
    skillGroup.order = json.order;

    if (typeof json.name === 'string') {
      skillGroup.name = json.name;
    }

    if (typeof json.translatedName === 'object' && json.translatedName) {
      skillGroup.translatedName = DescriptionModel.generateModel(json.translatedName)
    }

    if (json.questions) {

      if (json.questions.length > 0 && typeof json.questions[0] === 'object') {

        skillGroup.questions = generateQuestionList(json.questions);
        for (const q of skillGroup.questions) {
          skillGroup.questionIds.push(q._id);
        }

      } else if (json.questions.length > 0 && typeof json.questions[0] === 'string') {
        skillGroup.questionIds = json.questions;
      }

    }

    if (json.createdAt) {
      skillGroup.createdAt = new Date(json.createdAt);
    }

    if (json.updatedAt) {
      skillGroup.updatedAt = new Date(json.updatedAt);
    }

    return skillGroup;

  }

  static generateModels(jsonList: any[]): SkillGroupModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push(this.generateModel(json));
    }

    return list;

  }

  getTwoInitials(): string {

    return StringExtensionModel.getInitials(this.name, 2);
  }

  getCategoryIconURL(): string {

    return QuestionModel.categoryIconURL(this.category);
  }

  getTranslatedName(): string {

    if (UserModel.getCurrent() && this.translatedName) {
      return this.translatedName[UserModel.getCurrent().culture];
    }

    return this.translatedName['en-US'];

  }

}

export class SkillGroupFilter {

  skip = 0;
  limit: number;

  name: string;
  category: string;
  skillGroupIds: string;
  excludeSkillGroupIds: string;

  sortByName = 1;
  sortByOrder = 1;

}
