import {Component, OnInit} from '@angular/core';
import {EVOSegmentedControlItem} from '../../../../shared/reusable-controls/evo-segmented-control/evo-segmented-control.component';

@Component({
  selector: 'app-student-settings-page',
  templateUrl: './new-settings-page.component.html',
  styleUrls: ['./new-settings-page.component.css']
})
export class NewSettingsPageComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
