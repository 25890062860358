import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StudentModel} from '../../../../../../models/authentication/student.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-school-admin-group-detail-student-container',
  templateUrl: './school-admin-group-detail-student-container.component.html',
  styleUrls: ['./school-admin-group-detail-student-container.component.css']
})
export class SchoolAdminGroupDetailStudentContainerComponent implements OnInit {

  @Input() student: StudentModel;

  constructor(public tSv: TranslateService) { }

  ngOnInit() {
  }

}
