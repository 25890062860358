import {QuestionModel} from '../question.model';
import {RangeModel} from '../shared/range.model';
import {MultipleImageTypesModel} from '../shared/multipleImageTypes.model';
import {DropdownModel} from '../../component/dropdown.model';

export class BarChartModel extends QuestionModel {

  static DirectionTypes = {
    vertical: 'vertical',
    horizontal: 'horizontal',
    list: function() {

      return [
        this.vertical,
        this.horizontal
      ];

    },
    dropdownList: function() {

      return [
        new DropdownModel(this.vertical, this.vertical),
        new DropdownModel(this.horizontal, this.horizontal)
      ];

    }
  };

  /**
   * How the bar graph should be interpreted, whether we are inputing numbers 'none'
   * or finding min value or max value.
   */
  static InterpretationTypes = {
    none: 'none',
    min: 'min',
    max: 'max',
    list: function () {

      return [
        this.none,
        this.min,
        this.max
      ];

    },
    dropdownList: function() {

      return [
        new DropdownModel(this.none, this.none),
        new DropdownModel(this.min, this.min),
        new DropdownModel(this.max, this.max)
      ];

    }
  };

  static NumBarSeparatorTypes = {
    '5': '5',
    '10': '10',
    list: function() {

      return [
        this['5'],
        this['10']
      ];

    },
    dropdownList: function() {

      return [
        new DropdownModel('5', this['5']),
        new DropdownModel('10', this['10'])
      ];

    }
  }

  model = {
    numColumns: new RangeModel(2, 4),
    directionType: BarChartModel.DirectionTypes.vertical,
    numBarSeparators: 10, // can be 5 or 10
    firstRange: new RangeModel(0, 20),
    interpretationType: BarChartModel.InterpretationTypes.none,
    columnImages: new MultipleImageTypesModel()
  };

  static generateModel(json: any): BarChartModel {

    const q = new BarChartModel();

    const model = json.model;
    if (model) {

      model.directionType = json.directionType;
      model.numBarSeparators = json.numBarSeparators;
      model.interpretationType = json.interpretationType;

      if (model.numColumns) {
        q.model.numColumns = RangeModel.generateModel(model.numColumns);
      }

      if (model.firstRange) {
        q.model.firstRange = RangeModel.generateModel(model.firstRange);
      }

      if (model.columnImages) {
        q.model.columnImages = MultipleImageTypesModel.generate(model.columnImages);
      }

    }

    return q;

  }

}
