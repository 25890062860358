import {DescriptionModel} from '../shared/description.model';
import {StringExtensionModel} from '../extensions/string-extension.model';

export class QuestionModel {

  static AnswerTypes = {
    multipleChoice: 'multipleChoice',
    input: 'input',
    interaction: 'interaction',
    list : () => {
      return [QuestionModel.AnswerTypes.multipleChoice,
        QuestionModel.AnswerTypes.input,
        QuestionModel.AnswerTypes.interaction];
    },
    dropdownList: function(includeNone = false): { key: string, value: string, iconURL: string }[] {

      const list = [];

      if (includeNone) {
        list.push({
          key: 'none', value: 'reusable.none', iconURL: null
        });
      }

      for (const key of this.list()) {
        list.push({ key: key, value: key, iconURL: null });
      }

      return list;

    }
  };

  static RepresentationTypes = {
    equation: 'equation',
    images: 'images',
    equationAndImages: 'equationAndImages',
    grid: 'grid',
    barChart: 'barChart',
    numberLine: 'numberLine',
    list: () => {
      return [QuestionModel.RepresentationTypes.equation,
        QuestionModel.RepresentationTypes.images,
        QuestionModel.RepresentationTypes.equationAndImages,
        QuestionModel.RepresentationTypes.grid,
        QuestionModel.RepresentationTypes.barChart,
        QuestionModel.RepresentationTypes.numberLine
      ];
    },
    dropdownList: function(includeNone = false): { key: string, value: string, iconURL: string }[] {

      const list = [];

      if (includeNone) {
        list.push({
          key: 'none', value: 'reusable.none', iconURL: null
        });
      }

      for (const key of this.list()) {
        list.push({ key: key, value: key, iconURL: null });
      }

      return list;

    }
  };

  static SubjectTypes = {
    math: 'math',
    list: () => {
      return [
        QuestionModel.SubjectTypes.math
      ];
    },
    dropdownList: function(): { key: string, value: string, iconURL: string }[] {
      const list = [];
      for (const key of this.list()) {
        list.push({ key: key, value: key, iconURL: null });
      }

      return list;

    }
  };

  static TopicTypes = {
    arithmetic: 'arithmetic',
    patterns: 'patterns',
    finance: 'finance',
    algebra: 'algebra',
    statistics: 'statistics',
    geometry: 'geometry',
    list: () => {
      return [QuestionModel.TopicTypes.arithmetic,
        QuestionModel.TopicTypes.patterns,
        QuestionModel.TopicTypes.finance,
        QuestionModel.TopicTypes.algebra,
        QuestionModel.TopicTypes.statistics,
        QuestionModel.TopicTypes.geometry
      ];
    },
    dropdownList: function(): { key: string, value: string, iconURL: string }[] {
      const list = [];
      for (const key of this.list()) {
        list.push({ key: key, value: key, iconURL: null });
      }

      return list;

    }
  };

  static CategoryTypes = {
    count: 'count',
    addition: 'addition',
    subtraction: 'subtraction',
    multiplication: 'multiplication',
    sizeComparison: 'sizeComparison',
    numberPatterns: 'numberPatterns',
    imagePatterns: 'imagePatterns',
    placeValue: 'placeValue',
    shapes: 'shapes',
    division: 'division',
    transformations: 'transformations',
    fractions: 'fractions',
    negativeNumbers: 'negativeNumbers',
    orderingNumbers: 'orderingNumbers',
    perimeter: 'perimeter',
    area: 'area',
    rounding: 'rounding',
    list: () => {
      return [
        QuestionModel.CategoryTypes.count,
        QuestionModel.CategoryTypes.addition,
        QuestionModel.CategoryTypes.subtraction,
        QuestionModel.CategoryTypes.multiplication,
        QuestionModel.CategoryTypes.placeValue,
        QuestionModel.CategoryTypes.sizeComparison,
        QuestionModel.CategoryTypes.numberPatterns,
        QuestionModel.CategoryTypes.imagePatterns,
        QuestionModel.CategoryTypes.transformations,
        QuestionModel.CategoryTypes.shapes,
        QuestionModel.CategoryTypes.fractions,
        QuestionModel.CategoryTypes.division,
        QuestionModel.CategoryTypes.perimeter,
        QuestionModel.CategoryTypes.area,
        QuestionModel.CategoryTypes.rounding
      ];
    },
    dropdownList: function(includeNone = false): { key: string, value: string, iconURL: string }[] {

      const list = [];

      if (includeNone) {
        list.push({
          key: 'none', value: 'categories.none', iconURL: null
        });
      }

      for (const key of this.list()) {
        list.push({ key: key, value: 'categories.' + key, iconURL: null });
      }

      return list;

    }
  };

  static SubCategoryTypes = {
    cornerNameFromImage: 'cornerNameFromImage',
    countNumbers: 'countNumbers',
    countMultipleNumbers: 'countMultipleNumbers',
    countDiscrimination: 'countDiscrimination',
    countPairs: 'countPairs',
    fractionSetupFromImage: 'fractionSetupFromImage',
    proportionSetupFromImage: 'proportionSetupFromImage',
    translation2D: 'translation2D',
    singleAddition: 'singleAddition',
    doubleAddition: 'doubleAddition',
    singleAdditionNeededAmount: 'singleAdditionNeededAmount',
    doubleAdditionNeededAmount: 'doubleAdditionNeededAmount',
    singleSubtraction: 'singleSubtraction',
    doubleSubtraction: 'doubleSubtraction',
    singleSubtractionNeededAmount: 'singleSubtractionNeededAmount',
    sizeComparisonNumbers: 'sizeComparisonNumbers',
    sizeComparisonSymbols: 'sizeComparisonSymbols',
    sizeComparisonWords: 'sizeComparisonWords',
    numberPatternSingleFill: 'numberPatternSingleFill',
    imagePatternSingleFill: 'imagePatternSingleFill',
    countShapes: 'countShapes',
    shapeNameFromImage: 'shapeNameFromImage',
    shapeSingleDiscriminationCount: 'shapeSingleDiscriminationCount',
    repeatedAddition: 'repeatedAddition',
    singleMultiplication: 'singleMultiplication',
    unitHouse: 'unitHouse',
    singleDivision: 'singleDivision',
    perimeterFromShape: 'perimeterFromShape',
    areaFromShape: 'areaFromShape',
    roundingWholeNumber: 'roundingWholeNumber',
    barChart: 'barChart',
    list: () => {
      return [
        QuestionModel.SubCategoryTypes.cornerNameFromImage,
        QuestionModel.SubCategoryTypes.countNumbers,
        QuestionModel.SubCategoryTypes.countMultipleNumbers,
        QuestionModel.SubCategoryTypes.countDiscrimination,
        QuestionModel.SubCategoryTypes.countPairs,
        QuestionModel.SubCategoryTypes.fractionSetupFromImage,
        QuestionModel.SubCategoryTypes.proportionSetupFromImage,
        QuestionModel.SubCategoryTypes.singleAddition,
        QuestionModel.SubCategoryTypes.doubleAddition,
        QuestionModel.SubCategoryTypes.singleAdditionNeededAmount,
        QuestionModel.SubCategoryTypes.doubleAdditionNeededAmount,
        QuestionModel.SubCategoryTypes.singleSubtraction,
        QuestionModel.SubCategoryTypes.singleSubtractionNeededAmount,
        QuestionModel.SubCategoryTypes.doubleSubtraction,
        QuestionModel.SubCategoryTypes.sizeComparisonNumbers,
        QuestionModel.SubCategoryTypes.sizeComparisonSymbols,
        QuestionModel.SubCategoryTypes.sizeComparisonWords,
        QuestionModel.SubCategoryTypes.numberPatternSingleFill,
        QuestionModel.SubCategoryTypes.imagePatternSingleFill,
        QuestionModel.SubCategoryTypes.countShapes,
        QuestionModel.SubCategoryTypes.shapeNameFromImage,
        QuestionModel.SubCategoryTypes.shapeSingleDiscriminationCount,
        QuestionModel.SubCategoryTypes.repeatedAddition,
        QuestionModel.SubCategoryTypes.singleMultiplication,
        QuestionModel.SubCategoryTypes.unitHouse,
        QuestionModel.SubCategoryTypes.singleDivision,
        QuestionModel.SubCategoryTypes.perimeterFromShape,
        QuestionModel.SubCategoryTypes.areaFromShape,
        QuestionModel.SubCategoryTypes.roundingWholeNumber,
        QuestionModel.SubCategoryTypes.barChart
      ];
    },
    dropdownList: function(includeNone = false): { key: string, value: string, iconURL: string }[] {

      const list = [];

      if (includeNone) {
        list.push({
          key: 'none', value: 'reusable.none', iconURL: null
        });
      }

      for (const key of this.list()) {
        list.push({ key: key, value: key, iconURL: null });
      }

      return list;

    }
  };

  static ImageTypes = {
    Apple: 'Apple',
    'Apple Yellow': 'Apple Yellow',
    Aubergine: 'Aubergine',
    'Band Aid 20 Pack': 'Band Aid 20 Pack',
    'Band Aid Big': 'Band Aid Big',
    'Band Aid Small': 'Band Aid Small',
    Bolt: 'Bolt',
    Bone: 'Bone',
    'Bone Green': 'Bone Green',
    Branch: 'Branch',
    'Branch Green': 'Branch Green',
    Brick: 'Brick',
    'Butternut Squash': 'Butternut Squash',
    Cantalope: 'Cantalope',
    'Donut Toy': 'Donut Toy',
    'Donut Toy Blue': 'Donut Toy Blue',
    'Double Wood': 'Double Wood',
    'Electroshock Cream': 'Electroshock Cream',
    'Fat Fish': 'Fat Fish',
    'Frostbite Cream': 'Frostbite Cream',
    Gauze: 'Gauze',
    Hammer: 'Hammer',
    'Health Pack Big': 'Health Pack Big',
    'Health Pack Small': 'Health Pack Small',
    'Herb Bowl': 'Herb Bowl',
    'Honey Pot': 'Honey Pot',
    'Light Blue Crystal': 'Light Blue Crystal',
    'Lizard Toy': 'Lizard Toy',
    'Lizard Toy Green': 'Lizard Toy Green',
    Mango: 'Mango',
    'Mango Green': 'Mango Green',
    Meat: 'Meat',
    'Mouse Toy': 'Mouse Toy',
    'Nail Box': 'Nail Box',
    Nail: 'Nail',
    Onions: 'Onions',
    Papaya: 'Papaya',
    Pepper: 'Pepper',
    Pineapple: 'Pineapple',
    'Pineapple Green': 'Pineapple Green',
    'Poison Rash Cream': 'Poison Rash Cream',
    Potato: 'Potato',
    'Potion Blue': 'Potion Blue',
    'Potion Green': 'Potion Green',
    'Potion Red': 'Potion Red',
    'Purple Crystal': 'Purple Crystal',
    Screw: 'Screw',
    'Small Blueberry Branch': 'Small Blueberry Branch',
    Spade: 'Spade',
    Spinach: 'Spinach',
    Strawberry: 'Strawberry',
    'Sunburn Cream': 'Sunburn Cream',
    Teddy: 'Teddy',
    'Tennis Ball': 'Tennis Ball',
    'Tennis Ball Green': 'Tennis Ball Green',
    Watercress: 'Watercress',
    Watermelon: 'Watermelon',
    Wood: 'Wood',
    'Yarn Ball': 'Yarn Ball',
    'Yarn Ball Green': 'Yarn Ball Green',
    list: function(): string[] {
      return [this.Apple,
        this['Apple Yellow'],
        this.Aubergine,
        this['Band Aid 20 Pack'],
        this['Band Aid Small'],
        this['Band Aid Big'],
        this.Bolt,
        this.Bone,
        this['Bone Green'],
        this.Branch,
        this['Branch Green'],
        this.Brick,
        this['Butternut Squash'],
        this.Cantalope,
        this['Donut Toy'],
        this['Donut Toy Blue'],
        this['Double Wood'],
        this['Electroshock Cream'],
        this['Fat Fish'],
        this['Fat Fish'],
        this['Frostbite Cream'],
        this.Gauze,
        this.Hammer,
        this['Health Pack Big'],
        this['Health Pack Small'],
        this['Herb Bowl'],
        this['Honey Pot'],
        this['Light Blue Crystal'],
        this['Lizard Toy'],
        this['Lizard Toy Green'],
        this.Mango,
        this['Mango Green'],
        this.Meat,
        this['Mouse Toy'],
        this['Nail Box'],
        this.Nail,
        this.Onions,
        this.Papaya,
        this.Pineapple,
        this['Pineapple Green'],
        this['Poison Rash Cream'],
        this.Potato,
        this['Potion Blue'],
        this['Potion Green'],
        this['Potion Red'],
        this['Purple Crystal'],
        this.Screw,
        this['Small Blueberry Branch'],
        this.Spade,
        this.Spinach,
        this.Strawberry,
        this['Sunburn Cream'],
        this.Teddy,
        this['Tennis Ball'],
        this['Tennis Ball Green'],
        this.Watercress,
        this.Watermelon,
        this.Wood,
        this['Yarn Ball'],
        this['Yarn Ball Green']
      ];
    },
    dropdownList: function(): { key: string, value: string, iconURL: string }[] {
      const list = [];
      for (const key of this.list()) {
        list.push({ key: key, value: key, iconURL: null });
      }

      return list;

    }
  };

  static SizeComparisonTypes = {
    equal: 'equal',
    greater: 'greater',
    less: 'less',
    list: function() {
      return [this.equal, this.greater, this.less];
    },
    dropdownList: function(): { key: string, value: string, iconURL: string }[] {
      const list = [];
      for (const key of this.list()) {
        list.push({ key: key, value: key, iconURL: null });
      }

      return list;
    }
  };

  static PerimeterShapeTypes = {
    Arrow1: 'Arrow1',
    Diamond1: 'Diamond1',
    Pentagon1: 'Pentagon1',
    Pentagon2: 'Pentagon2',
    OddShape1: 'OddShape1',
    OddShapeSquare1: 'OddShapeSquare1',
    Rectangle1x2: 'Rectangle1x2',
    Rectangle2x3: 'Rectangle2x3',
    Square1x1: 'Square1x1',
    Triangle1: 'Triangle1',
    list: function() {

      return [
        this.Arrow1,
        this.Diamond1,
        this.Pentagon1,
        this.Pentagon2,
        this.OddShape1,
        this.OddShapeSquare1,
        this.Rectangle1x2,
        this.Rectangle2x3,
        this.Square1x1,
        this.Triangle1
      ];

    }
  };

  static RoundingTypes = {
    '10': 10,
    '100': 100,
    '1000': 1000,
    list: function() {
      return [
        this['10'],
        this['100'],
        this['1000']];
    },
    dropdownList: function(): { key: string, value: string, iconURL: string }[] {

      const list = [];
      for (const key of this.list()) {
        list.push({ key: key.toString(), value: key.toString(), iconURL: null });
      }

      return list;

    }
  };

  static AreaShapeTypes = {
    OddRectangle1_4x5: 'OddRectangle1_4x5',
    OddShape1_3x4: 'OddShape1_3x4',
    OddShape1_3x5: 'OddShape1_3x5',
    OddShape1_4x4: 'OddShape1_4x4',
    OddShape1_4x5: 'OddShape1_4x5',
    OddShape2_3x4: 'OddShape2_3x4',
    OddShape2_3x5: 'OddShape2_3x5',
    OddShape2_4x4: 'OddShape2_4x4',
    OddShape2_4x5: 'OddShape2_4x5',
    OddShape3_3x4: 'OddShape3_3x4',
    OddShape3_3x5: 'OddShape3_3x5',
    OddShape3_4x4: 'OddShape3_4x4',
    OddShape3_4x5: 'OddShape3_4x5',
    OddShape4_3x5: 'OddShape4_3x5',
    OddShape4_4x4: 'OddShape4_4x4',
    OddShape4_4x5: 'OddShape4_4x5',
    OddShape5_3x4: 'OddShape5_3x4',
    OddShape5_3x5: 'OddShape5_3x5',
    OddShape5_4x4: 'OddShape5_4x4',
    OddShape5_4x5: 'OddShape5_4x5',
    Rectangle1_3x4: 'Rectangle1_3x4',
    Rectangle1_3x5: 'Rectangle1_3x5',
    Rectangle1_4x5: 'Rectangle1_4x5',
    Square1_4x4: 'Square1_4x4',
    list: function() {

      return [
        this.OddRectangle1_4x5,
        this.OddShape1_3x4,
        this.OddShape1_3x5,
        this.OddShape1_4x4,
        this.OddShape1_4x5,
        this.OddShape2_3x4,
        this.OddShape2_3x5,
        this.OddShape2_4x4,
        this.OddShape2_4x5,
        this.OddShape3_3x4,
        this.OddShape3_3x5,
        this.OddShape3_4x4,
        this.OddShape3_4x5,
        this.OddShape4_3x5,
        this.OddShape4_4x4,
        this.OddShape4_4x5,
        this.OddShape5_3x4,
        this.OddShape5_3x5,
        this.OddShape5_4x4,
        this.OddShape5_4x5,
        this.Rectangle1_3x4,
        this.Rectangle1_3x5,
        this.Rectangle1_4x5,
        this.Square1_4x4
      ];

    }
  };

  static CornerTypes = {
    right: 'right',
    acute: 'acute',
    obtuse: 'obtuse',
    list: function(): string[] {
      return [this.right, this.acute, this.obtuse];
    },
    dropdownList: function(): { key: string, value: string, iconURL: string }[] {

      const list = [];

      for (const key of this.list()) {
        list.push({ key, value: key, iconURL: null });
      }

      return list;

    }
  };

  static ShapeTypes = {
    circle: 'circle',
    square: 'square',
    rectangle: 'rectangle',
    parallelogram: 'parallelogram',
    trapezium: 'trapezium',
    triangle: 'triangle',
    pentagon: 'pentagon',
    hexagon: 'hexagon',
    octagon: 'octagon',
    list: function(): string[] {
      return [this.circle,
        this.square,
        this.rectangle,
        this.parallelogram,
        this.trapezium,
        this.triangle,
        this.pentagon,
        this.hexagon,
        this.octagon];
    },
    dropdownList: function(): { key: string, value: string, iconURL: string }[] {

      const list = [];

      for (const key of this.list()) {
        list.push({ key, value: key, iconURL: null });
      }

      return list;

    }
  };

  static ShapeImageTypes = {
    circle: {
      yellow: 'CircleYellow',
      red: 'CircleRed',
      green: 'CircleGreen',
      blue: 'CircleBlue',
      purple: 'CirclePurple',
      orange: 'CircleOrange'
    },
    square: {
      yellow: 'SquareYellow',
      red: 'SquareRed',
      green: 'SquareGreen',
      blue: 'SquareBlue',
      purple: 'SquarePurple',
      orange: 'SquareOrange'
    },
    rectangle: {
      yellow: 'RectangleYellow',
      red: 'RectangleRed',
      green: 'RectangleGreen',
      blue: 'RectangleBlue',
      purple: 'RectanglePurple',
      orange: 'RectangleOrange'
    },
    parallelogram: {
      yellow: 'ParallelogramYellow',
      red: 'ParallelogramRed',
      green: 'ParallelogramGreen',
      blue: 'ParallelogramBlue',
      purple: 'ParallelogramPurple',
      orange: 'ParallelogramOrange'
    },
    trapezium: {
      yellow: 'TrapeziumYellow',
      red: 'TrapeziumRed',
      green: 'TrapeziumGreen',
      blue: 'TrapeziumBlue',
      purple: 'TrapeziumPurple',
      orange: 'TrapeziumOrange'
    },
    triangle: {
      yellow: 'TriangleYellow',
      red: 'TriangleRed',
      green: 'TriangleGreen',
      blue: 'TriangleBlue',
      purple: 'TrianglePurple',
      orange: 'TriangleOrange'
    },
    pentagon: {
      yellow: 'PentagonYellow',
      red: 'PentagonRed',
      green: 'PentagonGreen',
      blue: 'PentagonBlue',
      purple: 'PentagonPurple',
      orange: 'PentagonOrange'
    },
    hexagon: {
      yellow: 'HexagonYellow',
      red: 'HexagonRed',
      green: 'HexagonGreen',
      blue: 'HexagonBlue',
      purple: 'HexagonPurple',
      orange: 'HexagonOrange'
    },
    octagon: {
      yellow: 'OctagonYellow',
      red: 'OctagonRed',
      green: 'OctagonGreen',
      blue: 'OctagonBlue',
      purple: 'OctagonPurple',
      orange: 'OctagonOrange'
    },
    list: function(): string[] {
      const array = [];
      for (const key of Object.keys(this)) {
        if (key !== 'list' && key !== 'dropdownList') {
          const value = this[key];
          for (const subKey of Object.keys(value)) {
            array.push(value[subKey]);
          }
        }
      }

      return array;
    },
    dropdownList: function(): { key: string, value: string, iconURL: string }[] {
      const array = [];
      for (const key of this.list()) {
        array.push({ key: key, value: key, iconURL: null });
      }

      return array;
    }
  };

  static OperationTypes = {
    addition: '+',
    subtraction: '-',
    multiplication: '*',
    division: '-',
    list: function() {
      return [this.addition,
        this.subtraction,
        this.multiplication,
        this.division];
    },
    dropdownList: function(): { key: string, value: string, iconURL: string }[] {
      const array = [];
      for (const key of this.list()) {
        array.push({ key: key, value: key, iconURL: null });
      }

      return array;
    }
  };

  static BooleanTypes = {
    'true': 'true',
    'false': 'false',
    list: function() {
      return [this.true, this.false];
    },
    dropdownList: function(includeNone = false): { key: string, value: string, iconURL: string }[] {

      const list = [];

      if (includeNone) {
        list.push({ key: 'none', value: 'reusable.none', iconURL: null });
      }

      for (const key of this.list()) {
        list.push({ key: key, value: key, iconURL: null });
      }

      return list;

    }
  };

  static ParameterNameTypes = {
    firstParam: 'firstParam',
    secondParam: 'secondParam',
    thirdParam: 'thirdParam',
    fourthParam: 'fourthParam',
    fifthParam: 'fifthParam',
    sixthParam: 'sixthParam',
    seventhParam: 'seventhParam',
    eighthParam: 'eighthParam',
    ninthParam: 'ninthParam',
    paramNameByIndex: function(index) {

      if (index === 0) {

        return this.firstParam;

      } else if (index === 1) {

        return this.secondParam;

      } else if (index === 2) {

        return this.thirdParam;

      } else if (index === 3) {

        return this.fourthParam;

      } else if (index === 4) {

        return this.fifthParam;

      } else if (index === 5) {

        return this.sixthParam;

      } else if (index === 6) {

        return this.seventhParam;

      } else if (index === 7) {

        return this.eighthParam;

      } else if (index === 8) {

        return this.ninthParam;

      }

    }
  };

  public _id: string;

  public name: string;
  public subject: string;
  public topic: string;
  public category: string;
  public subCategory: string;
  public country: string;
  public isStarterQuestion: boolean;
  public skillGroupId: string;

  /**
   * @description The answer type of the question.
   * It can be multipleChoice, input and interaction.
   */
  public answerType: string;
  public representation: string;

  public model: object;

  public isPublished = false;
  public isArchived = false;

  public decidedRank = 0;
  public rank = -1200;
  public rankCountry = new CountryBasedNumberModel();
  public numCorrectCountry = new CountryBasedNumberModel();
  public numWrongCountry = new CountryBasedNumberModel();
  public numCorrect = 0;
  public numWrong = 0;

  public majorVersion = 1;
  public minorVersion = 0;
  public revisionVersion = 0;

  public createdAt: Date;
  public updatedAt: Date;

  descriptions = new DescriptionModel();
  audioHelp = new DescriptionModel();
  videoHelp = new DescriptionModel();

  constructor() {
    this.subject = 'math';
  }

  static answerTypeDropdownList(): { key: string, value: string, iconURL: string }[] {

    return [{
      key: 'none',
      value: 'answerTypes.none',
      iconURL: null
    }, {
      key: QuestionModel.AnswerTypes.input,
      value: 'answerTypes.' + QuestionModel.AnswerTypes.input,
      iconURL: null
    }, {
      key: QuestionModel.AnswerTypes.multipleChoice,
      value: 'answerTypes.' + QuestionModel.AnswerTypes.multipleChoice,
      iconURL: null
    }];
  }

  static categoryIconURL(category: string): string {

    const baseUrl = './assets/skill-icons/';

    switch (category) {

      case QuestionModel.CategoryTypes.numberPatterns:
      case QuestionModel.CategoryTypes.imagePatterns:

        return baseUrl + 'patterns.png';

      case QuestionModel.CategoryTypes.shapes:

        return baseUrl + 'forms.png';

      case QuestionModel.CategoryTypes.count:

        return baseUrl + 'count.png';

      case QuestionModel.CategoryTypes.placeValue:

        return baseUrl + 'placeValue.png';

      case QuestionModel.CategoryTypes.addition:

        return baseUrl + 'addition.png';

      case QuestionModel.CategoryTypes.subtraction:

        return baseUrl + 'subtraction.png';

      case QuestionModel.CategoryTypes.multiplication:

        return baseUrl + 'multiplication.png';

      case QuestionModel.CategoryTypes.division:

        return baseUrl + 'division.png';

      case QuestionModel.CategoryTypes.sizeComparison:

        return baseUrl + 'sizes.png';

      case QuestionModel.CategoryTypes.fractions:

        return baseUrl + 'fractions.png';

      case QuestionModel.CategoryTypes.transformations:

        return baseUrl + 'GeometricTransformations.png';

      default:

        return './assets/sidebar/questions-circular-gray.svg';
    }

  }

  versionNumber(): string {

    if (this.majorVersion != null && this.minorVersion != null && this.revisionVersion != null) {

      const major = this.majorVersion.toString(10);
      const minor = this.minorVersion.toString(10);
      const revision = this.revisionVersion.toString(10);

      return major + '.' + minor + '.' + revision;

    }

    return 'Undefined';

  }

  numCorrectForCountry(country: string): number {

    if (this.numCorrectCountry) {

      return this.numCorrectCountry[country];

    } else {

      let total = 0;
      for (const key of Object.keys(this.numCorrectCountry)) {
        total += this.numCorrectCountry[key];
      }

      return total;

    }

    return 0;
  }

  numWrongForCountry(country: string): number {

    if (this.numWrongCountry) {

      return this.numWrongCountry[country];

    } else {

      let total = 0;
      for (const key of Object.keys(this.numWrongCountry)) {
        total += this.numWrongCountry[key];
      }

    }

    return 0;
  }

  numAnswers(country: string): number {

    const numCorrect = this.numCorrectForCountry(country);
    const numWrong = this.numWrongForCountry(country);

    return numCorrect + numWrong;
  }

  correctnessRatio(country: string): number {

    const total = this.numAnswers(country);
    if (total > 0) {
      return this.numCorrectForCountry(country) / total;
    }

    return 0;

  }

  correctnessRatioPercentage(country: string): number {

    return this.correctnessRatio(country) * 100;

  }

  correctnessRatioPercentageString(country: string): string {

    const ratio = this.correctnessRatioPercentage(country);

    return ratio.toFixed(2).toString() + ' %';
  }

  categoryIconURL(): string {

    return QuestionModel.categoryIconURL(this.category);
  }

}

export class CountryBasedNumberModel {

  public ISL = 0;
  public GBR = 0;
  public FRA = 0;
  public BRA = 0;
  public SWE = 0;
  public NOR = 0;
  public LTU = 0;
  public DEU = 0;

  static generate(json: any): CountryBasedNumberModel {

    const country = new CountryBasedNumberModel();
    country.ISL = json.ISL;
    country.GBR = json.GBR;
    country.FRA = json.FRA;
    country.BRA = json.BRA;
    country.SWE = json.SWE;
    country.NOR = json.NOR;
    country.LTU = json.LTU;
    country.DEU = json.DEU;

    return country;

  }

  valueForCountry(country: string): number {

    const value = this[country];

    if (value === null || value === undefined) {

      return 0;
    }

    return value;
  }

  setValueForCountry(country: string, value: number) {

    this[country] = value;

  }

}
