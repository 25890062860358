import {QuestionModel} from '../question.model';

export class CornerNameFromImageModel extends QuestionModel {

  model = {
    corner: QuestionModel.CornerTypes.right,
    possibleAnswers: [],
    numAnswers: 3
  };

  static generateModel(json: any): QuestionModel {

    const q = new CornerNameFromImageModel();

    const model = json.model;

    if (model) {

      if (model.possibleAnswers instanceof Array) {
        q.model.possibleAnswers = model.possibleAnswers;
      }

      q.model.corner = model.corner;
      q.model.numAnswers = model.numAnswers;

    }

    return q;

  }

}
