import { Component, OnInit } from '@angular/core';
import {EvoPassModalService} from './evo-pass-modal.service';

@Component({
  selector: 'app-evo-pass-modal',
  templateUrl: './evo-pass-modal.component.html',
  styleUrls: ['./evo-pass-modal.component.css']
})
export class EvoPassModalComponent implements OnInit {

  public pass: string;

  constructor(public passModalSv: EvoPassModalService) { }

  ngOnInit() {
  }

  onCancelClicked() {

    this.passModalSv.onCancel();
    this.pass = null;

  }

  onConfirmClicked() {

    this.passModalSv.onConfirmation(this.pass);
    this.pass = null;

  }

}
