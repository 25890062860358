import { Injectable } from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {QuestionModel} from '../../../models/questions/question.model';
import {EVOQuestionFilter, AdminQuestionService} from '../../../services/admin/admin-question.service';

@Injectable({
  providedIn: 'root'
})
export class AdminQuestionSelectionListService {

  isVisible = false;
  subscriber: Subscriber<QuestionModel[]>;

  filter: EVOQuestionFilter = new EVOQuestionFilter();
  questionsToAdd: QuestionModel[] = [];
  questions: QuestionModel[] = [];
  hasMoreQuestions = false;

  constructor(private adminQSv: AdminQuestionService) { }

  show(questionIdsToExclude: string = null, filter: EVOQuestionFilter = null): Observable<QuestionModel[]> {

    this.isVisible = true;

    if (filter) {
      this.filter = filter;
    } else {
      this.filter = new EVOQuestionFilter();
    }

    this.filter.limit = 100;
    this.filter.isArchived = false;
    this.filter.isPublished = true;
    this.filter.excludeQuestionIds = questionIdsToExclude;

    this.fetchQuestions();

    return new Observable((subscriber: Subscriber<QuestionModel[]>) => {
      this.subscriber = subscriber;
    });

  }

  searchQuestions(name: string) {

    this.reset();
    this.hasMoreQuestions = false;
    this.filter.name = name;

    this.fetchQuestions();

  }

  onFilterChanged(filter: EVOQuestionFilter) {
    this.reset();
    this.hasMoreQuestions = false;
    this.filter = filter;
    this.fetchQuestions();
  }

  fetchQuestions() {

    this.filter.skip = this.questions.length;

    this.adminQSv.fetchAdminQuestions(this.filter).subscribe((questions) => {

      for (const q of questions) {
        if (this.questions.filter((cq) => cq._id === q._id).length === 0) {
          this.questions.push(q);
        }
      }

      this.hasMoreQuestions = false;
      if (questions.length === this.filter.limit) {
        this.hasMoreQuestions = true;
      }

    });

  }

  close() {

    this.subscriber.next(null);
    this.subscriber.complete();
    this.fullReset();
    this.isVisible = false;

  }

  addQuestions() {

    this.subscriber.next(this.questionsToAdd);
    this.subscriber.complete();
    this.fullReset();
    this.isVisible = false;

  }

  fullReset() {
    this.filter.name = null;
    this.filter.questionIds = null;
    this.filter.excludeQuestionIds = null;
    this.reset();
  }

  reset() {

    this.questions = [];
    this.questionsToAdd = [];

    this.filter.skip = 0;
    this.filter.limit = 100;

  }

}
