import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NumberExtensionModel} from '../../../../../../models/extensions/number-extension.model';
import {StudentModel} from '../../../../../../models/authentication/student.model';
import {CsvImportService} from '../../../../../../services/reusable/csv-import.service';
import {SchoolModel} from '../../../../../../models/school/school.model';
import {ModalService} from '../../../../../../shared/modals/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorModel} from '../../../../../../models/shared/error.model';
import {SchoolAdminGroupService} from '../../../../../../services/school/school-admin-group.service';
import {SchoolAdminLicenseService} from '../../../../../../services/school/school-admin-license.service';
import {SchoolAdminStudentService} from '../../../../../../services/school/school-admin-student.service';

@Component({
  selector: 'app-school-admin-group-add-new-student',
  templateUrl: './school-admin-group-add-new-student.component.html',
  styleUrls: ['./school-admin-group-add-new-student.component.css']
})
export class SchoolAdminGroupAddNewStudentComponent implements OnInit {

  groupId: string;
  availableLicenses = 0;
  students: StudentModel[] = [];

  showLoadingStudents = false;
  loadingTitle = '';
  loadingMessage = '';

  constructor(private route: ActivatedRoute,
              private modalSv: ModalService,
              private csvSv: CsvImportService,
              private router: Router,
              private schoolLicenseAdminSv: SchoolAdminLicenseService,
              private schoolStudentAdminSv: SchoolAdminStudentService,
              private groupSv: SchoolAdminGroupService,
              private tSv: TranslateService) { }

  ngOnInit() {
    this.route.parent.params.subscribe((params: Params) => {

      this.groupId = params.groupId;
      this.schoolLicenseAdminSv.fetchActiveLicenses(SchoolModel.getCurrent()._id).subscribe((response) => {
        this.availableLicenses = response.license.numLicenses - response.license.activeLicenses;
      });

    });
  }

  getScrollBarWidth(): string {

    return NumberExtensionModel.getScrollBarWidth().toString() + 'px';
  }

  onFileChanged(event) {

    StudentModel.importStudentsViaCSV(event, this.csvSv, SchoolModel.getCurrent().country).subscribe((students) => {

      this.students = students;

      // TODO: Needs Translation
      if (this.students.length > this.availableLicenses) {
        this.modalSv.showAlertModal(this.tSv.instant('reusable.error'), 'You do not have enough licenses to add all of the students imported contact evolytes@evolytes.com to increase your license limit.');
      }

    }, (err: ErrorModel) => {
      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'), err.message);

    });

  }

  canAddStudents(): boolean {

    if (this.students.length > 0 && (this.availableLicenses - this.students.length) >= 0) {
      return true;
    }

    return false;
  }

  onDownloadTemplateClicked() {

    // TODO: different templates based on country
    const a = document.createElement('a');
    a.href = 'assets/templates/Student-Import-Template.csv';
    a.download = 'Student-Import-Template.csv';
    // start download
    a.click();
    a.remove();

  }

  onImportStudents() {

    this.showLoadingStudents = true;

    this.loadingTitle = this.tSv.instant('schoolAdministration.groups.importingStudentsLoadingTitle');
    this.loadingMessage = this.tSv.instant('schoolAdministration.groups.importingStudentsLoadingMessage');

    this.schoolStudentAdminSv.importStudents(this.students).subscribe((students) => {

      this.loadingTitle = this.tSv.instant('schoolAdministration.groups.addingStudentsToGroupLoadingTitle');
      this.loadingMessage = this.tSv.instant('schoolAdministration.groups.addingStudentsToGroupLoadingMessage');

      const studentIds = [];
      for (const s of students) {
        studentIds.push(s._id);
      }

      this.groupSv.addStudentsToGroup(this.groupId, studentIds).subscribe((group) => {

        this.showLoadingStudents = false;
        const successTitle = this.tSv.instant('reusable.success');
        const successMessage = this.tSv.instant('schoolAdministration.groups.successfullyImportedStudentsToGroupMessage');

        this.modalSv.showAlertModal(successTitle, successMessage).subscribe(() => {
          this.onCloseClicked();
        });

      });

    });

  }

  onCloseClicked() {
    this.router.navigate(['schooladmin', 'groups', this.groupId, 'students']);
  }

}
