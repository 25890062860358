import { Component, OnInit } from '@angular/core';
import {ObjectiveFilter, ObjectiveModel} from '../../../models/edu-admin/objective.model';
import {CountryModel} from '../../../models/localization/country.model';
import {Router} from '@angular/router';
import {AdminObjectiveService} from '../../../services/admin/admin-objective.service';

@Component({
  selector: 'app-admin-objective-archived',
  templateUrl: './admin-objective-archived.component.html',
  styleUrls: ['./admin-objective-archived.component.css']
})
export class AdminObjectiveArchivedComponent implements OnInit {

  objectives: ObjectiveModel[] = [];
  objectiveFilter = new ObjectiveFilter();
  hasMoreData = true;

  countryDropdownList = CountryModel.privateDropdownList(true);

  constructor(private router: Router,
              private objectiveSv: AdminObjectiveService) {
  }

  ngOnInit() {

    this.objectiveFilter.isArchived = true;
    this.onFetchMoreObjectives();

  }

  searchFilteredObjectives(searchString: string, country: string) {

    this.objectiveFilter.skip = 0;
    this.objectiveFilter.name = searchString;
    this.objectiveFilter.country = country;
    this.hasMoreData = true;

    this.objectives = [];

    this.onFetchMoreObjectives();

  }

  onFetchMoreObjectives() {

    this.objectiveFilter.skip = this.objectives.length;
    this.objectiveSv.fetchObjectives(this.objectiveFilter).subscribe((objectives) => {

      this.hasMoreData = true;
      if (objectives.length !== this.objectiveFilter.limit) {
        this.hasMoreData = false;
      }

      for (const objective of objectives) {

        if (this.objectives.filter((o) => o._id === objective._id).length === 0) {
          this.objectives.push(objective);
        }

      }

    });
  }

  onObjectiveClicked(obj: ObjectiveModel) {

    if (obj) {
      this.router.navigate(['admin', 'objectives', obj._id, 'detail', 'skillGroups']);
    }

  }

  viewLiveObjectives() {
    this.router.navigate(['admin', 'objectives']);
  }

}
