import {QuestionModel} from '../question.model';

export class Translation2DModel extends QuestionModel {

  static TranslationTypes = {
    x: 'x',
    y: 'y',
    both: 'both',
    list: function() {
      return [this.x, this.y, this.both];
    },
    dropdownList: function () {

      const list = [];

      for (const key of this.list()) {
        list.push({ key: key, value: key, iconURL: null });
      }

      return list;

    }
  };

  model = {
    translationType: 'both',
    gridSize: 5,
    numAnswers: 4
  };

  static generateModel(json: any): Translation2DModel {

    const q = new Translation2DModel();

    const model = json.model;

    if (model) {

      q.model.translationType = model.translationType;
      q.model.gridSize = model.gridSize;
      q.model.numAnswers = model.numAnswers;

    }

    return q;

  }

}
