import {CultureModel} from './culture.model';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

export class CountryModel {

  public static BRA = 'BRA';
  public static CHL = 'CHL';
  public static COL = 'COL';
  public static DEU = 'DEU';
  public static DNK = 'DNK';
  public static ESP = 'ESP';
  public static FRA = 'FRA';
  public static GBR = 'GBR';
  public static IND = 'IND';
  public static ISL = 'ISL';
  public static LTU = 'LTU';
  public static MEX = 'MEX';
  public static NOR = 'NOR';
  public static PRT = 'PRT';
  public static SWE = 'SWE';
  public static USA = 'USA';

  private static homepageCountryKey = 'homepageCountryKey';

  public static list(): string[] {

    return [this.BRA, this.CHL, this.COL, this.DNK, this.DEU, this.FRA, this.GBR, this.IND, this.ISL, this.LTU, this.MEX, this.NOR, this.SWE, this.USA];
  }

  public static privateList(): string[] {

    return [this.BRA, this.CHL, this.COL, this.DNK, this.DEU, this.ESP, this.FRA, this.GBR, this.IND, this.ISL, this.LTU, this.MEX, this.NOR, this.PRT, this.SWE, this.USA];
  }

  public static EUCountriesPublic(): string[] {

    return [this.DNK, this.DEU, this.ESP, this.FRA, this.GBR, this.ISL, this.LTU, this.NOR, this.SWE];
  }

  public static EUCountriesPrivate(): string[] {

    let publicCountries = this.EUCountriesPublic();
    publicCountries = publicCountries.concat([this.PRT]);

    return publicCountries;
  }

  public static NACountriesPrivate(): string[] {

    const publicCountries = this.NACountriesPublic();

    return publicCountries;
  }

  public static NACountriesPublic(): string[] {

    return [this.USA];
  }

  public static LACountriesPublic(): string[] {

    return [this.BRA, this.CHL, this.COL, this.MEX];
  }

  public static LACountriesPrivate(): string[] {

    let publicCountries = this.LACountriesPublic();
    publicCountries = publicCountries.concat([this.BRA]);

    return publicCountries;

  }

  public static INCountriesPublic(): string[] {

    return [this.IND];
  }

  public static isEUCountry(country: string): boolean {

    const euList = this.EUCountriesPrivate();
    for (let i = 0; i < euList.length; i++) {
      const value = euList[i];
      if (value === country) {
        return true;
      }
    }

    return false;
  }

  public static isNACountry(country: string): boolean {

    const naList = this.NACountriesPrivate();
    for (let i = 0; i < naList.length; i++) {
      const value = naList[i];
      if (value === country) {
        return true;
      }
    }

    return false;
  }

  public static isLACountry(country: string): boolean {

    const saList = this.LACountriesPrivate();
    for (const value of saList) {
      if (value === country) {
        return true;
      }
    }

    return false;
  }

  public static isINCountry(country: string): boolean {

    const inList = this.INCountriesPublic();
    for (const value of inList) {
      if (value === country) {
        return true;
      }
    }

    return false;
  }

  public static title(country: string) {

    switch (country) {

      case this.BRA:
        return 'Brazil';

      case this.CHL:
        return 'Chile';

      case this.COL:
        return 'Colombia';

      case this.DNK:
        return 'Danmark';

      case this.DEU:
        return 'Deutschland';

      case this.ESP:
        return 'España';

      case this.FRA:
        return 'France';

      case this.IND:
        return 'India';

      case this.ISL:
        return 'Ísland';

      case this.LTU:
        return 'Lietuva';

      case this.MEX:
        return 'Mexico';

      case this.NOR:
        return 'Norge';

      case this.PRT:
        return 'Portugal';

      case this.SWE:
        return 'Sverige';

      case this.USA:
        return 'United States of America';

        // Default is GBR
      default:
        return 'Great Britain';

    }

  }

  public static iconURL(country: string) {

    switch (country) {

      case this.BRA:
        return './assets/country/rounded/brazil.png';

      case this.CHL:
        return './assets/country/rounded/chile.png';

      case this.COL:
        return './assets/country/rounded/colombia.png';

      case this.DNK:
        return './assets/country/rounded/denmark.png';

      case this.DEU:
        return './assets/country/rounded/germany.png';

      case this.ESP:
        return './assets/country/rounded/spain.png';

      case this.FRA:
        return './assets/country/rounded/france.png';

      case this.IND:
        return './assets/country/rounded/india.png';

      case this.ISL:
        return './assets/country/rounded/iceland.png';

      case this.LTU:
        return './assets/country/rounded/lithuania.png';

      case this.MEX:
        return './assets/country/rounded/mexico.png';

      case this.NOR:
        return './assets/country/rounded/norway.png';

      case this.PRT:
        return './assets/country/rounded/portugal.png';

      case this.SWE:
        return './assets/country/rounded/sweden.png';

      case this.USA:
        return './assets/country/rounded/us.png';

      default:
        return './assets/country/rounded/uk.png';

    }

  }

  public static countryForCulture(culture: string): string {

    switch (culture) {

      case CultureModel.daDK:
        return this.DNK;

      case CultureModel.deDE:
        return this.DEU;

      case CultureModel.enUS:
        return this.USA;

      case CultureModel.esCL:
        return this.CHL;

      case CultureModel.esCO:
        return this.COL;

      case CultureModel.esES:
        return this.ESP;

      case CultureModel.esMX:
        return this.MEX;

      case CultureModel.esUS:
        return this.USA;

      case CultureModel.frFR:
        return this.FRA;

      case CultureModel.isIS:
        return this.ISL;

      case CultureModel.ltLT:
        return this.LTU;

      case CultureModel.nbNO:
        return this.NOR;

      case CultureModel.ptBR:
        return this.BRA;

      case CultureModel.ptPT:
        return this.PRT;

      case CultureModel.svSE:
        return this.SWE;

      default:
        return this.GBR;

    }
  }

  public static cultureForCountry(country: string): string {

    switch (country) {

      case this.BRA:
        return CultureModel.ptBR;

      case this.CHL:
        return CultureModel.esCL;

      case this.COL:
        return CultureModel.esCL;

      case this.DNK:
        return CultureModel.daDK;

      case this.DEU:
        return CultureModel.deDE;

      case this.ESP:
        return CultureModel.esES;

      case this.GBR:
        return CultureModel.enGB;

      case this.FRA:
        return CultureModel.frFR;

      case this.IND:
        return CultureModel.enGB;

      case this.ISL:
        return CultureModel.isIS;

      case this.LTU:
        return CultureModel.ltLT;

      case this.MEX:
        return CultureModel.esMX;

      case this.NOR:
        return CultureModel.nbNO;

      case this.PRT:
        return CultureModel.ptPT;

      case this.SWE:
        return CultureModel.svSE;

      case this.USA:
        return CultureModel.enUS;

      default:
        return CultureModel.enGB;

    }

  }

  public static cultureTitle(country: string): string {

    const culture = this.cultureForCountry(country);
    return CultureModel.title(culture);

  }

  public static priceStartingAt(country: string): string {

    switch (country) {

      case this.GBR:

        return '£5';

      case this.ISL:

        return '1.125 kr.';

      default:
        return '6€';

    }

  }

  public static dropdownList(countries: string[] = null): { key: string, value: string, iconURL: string }[] {

    const list = [];

    let countryList = this.list();
    if (countries) {
      countryList = countries;
    }

    for (const key of countryList) {
      list.push({ key: key, value: this.title(key), iconURL: this.iconURL(key) });
    }

    return list;

  }

  public static privateDropdownList(includeNone = false): { key: string, value: string, iconURL: string }[] {

    const list = this.dropdownList(this.privateList());

    if (includeNone) {
      list.splice(0, 0, { key: null, value: 'All', iconURL: './assets/school-admin/world.png'});
    }

    return list;

  }

  public static dropdownItem(countryKey: string): { key: string, value: string, iconURL: string } {

    const dp = { key: countryKey, value: this.title(countryKey), iconURL: this.iconURL(countryKey) };

    return dp;

  }

  public static getHomepageCountry(): string {

    const country = localStorage.getItem(this.homepageCountryKey);
    if (country !== null && country !== undefined) {
      return country;
    }

    return null;
  }

  public static setHomepageCountry(country: string) {

    if (this.list().includes(country)) {
      localStorage.setItem(this.homepageCountryKey, country);
      return true;
    }

    return false;

  }

  public static onCountryOrCultureRedirection(router: Router, paths: string[], country: string, culture: string, tSv: TranslateService) {

    const countryHomePath = ['home', this.cultureForCountry(country), 'landing'];

    if (CountryModel.isNACountry(country) && CountryModel.localhostSubstringCheck('us.')) {
      this.navigateInApp(router, paths, country, culture, tSv);
      return;
    } else if (CountryModel.isNACountry(country)) {
      this.redirect('https://us.evolytes.com', countryHomePath);
      return;
    } else if (CountryModel.isEUCountry(country) && CountryModel.localhostSubstringCheck('www.evolytes.com')) {
      this.navigateInApp(router, paths, country, culture, tSv);
      return;
    } else if (CountryModel.isEUCountry(country)) {
      this.redirect('https://www.evolytes.com', countryHomePath);
      return;
    } else if (CountryModel.isLACountry(country) && CountryModel.localhostSubstringCheck('la.')) {
      this.navigateInApp(router, paths, country, culture, tSv);
      return;
    } else if (CountryModel.isLACountry(country)) {
      this.redirect('https://la.evolytes.com', countryHomePath);
      return;
    } else if (CountryModel.isINCountry(country) && CountryModel.localhostSubstringCheck('in.')) {
      this.navigateInApp(router, paths, country, culture, tSv);
      return;
    } else if (CountryModel.isINCountry(country)) {
      this.redirect('https://in.evolytes.com', countryHomePath);
      return;
    }

  }

  static localhostSubstringCheck(subdomain: string): boolean {

    const inLocalhost = window.location.hostname.includes('localhost');
    const inSubdomain = window.location.hostname.includes(subdomain);
    const inIO = window.location.hostname.includes('.io');

    if (inLocalhost ||
      inSubdomain ||
      inIO) {

      return true;
    }

    return false;

  }

  public static redirect(baseUrl: string, paths: string[]) {

    let path = '';
    for (const p of paths) {
      path += '/' + p;
    }

    window.open(baseUrl + path, '_self');

  }

  public static navigateInApp(router: Router, paths: string[], country: string, culture: string, tSv: TranslateService) {

    CountryModel.setHomepageCountry(country);
    CultureModel.setHomepageCulture(culture);
    tSv.use(culture);

    router.navigate(['home', CultureModel.getHomepageCulture(), 'landing']);

  }

  public static getTeacherInvitationLinkForCountry(country: string, linkInvitationId: string): string {

    const serverUrl = this.getServerUrlForCountry(country) + '/linkInvite/' + linkInvitationId;

    return serverUrl;

  }

  /**
   * This is the same url as created in emails in created invitations not the old link invitation logic
   * This specifically created to invite administrators
   * @param country the country
   * @param invitationToken the token for the invitation
   */
  public static getCreatedTeacherInvitationLinkForCountry(country: string, invitationToken: string): string {

    const serverUrl = this.getServerUrlForCountry(country) + '/inviteTeacher/' + invitationToken;

    return serverUrl;

  }

  public static getServerUrlForCountry(country: string) {

    if (CountryModel.isNACountry(country)) {
      return 'https://us.evolytes.com';
    } else if (CountryModel.isLACountry(country)) {
      return 'https://la.evolytes.com';
    } else if (CountryModel.isINCountry(country)) {
      return 'https://in.evolytes.com';
    } else {
      return 'https://www.evolytes.com';
    }

  }

}
