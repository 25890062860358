import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SchoolAdminGroupService} from '../../../../../services/school/school-admin-group.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GroupModel, GroupQueryModel} from '../../../../../models/school/group.model';
import {StudentModel} from '../../../../../models/authentication/student.model';
import {ErrorModel} from '../../../../../models/shared/error.model';
import {ModalService} from '../../../../../shared/modals/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {EVOStudentListSort} from '../../../../../shared/list-views/evo-student-list-view/evo-student-list-view.component';
import {
  EvoSchoolAdminCreateStudentModalService
} from '../../../../../shared/modals/evo-school-admin-create-student-modal/evo-school-admin-create-student-modal.service';
import {SchoolModel} from '../../../../../models/school/school.model';

@Component({
  selector: 'app-school-admin-group-detail-students',
  templateUrl: './school-admin-group-detail-students.component.html',
  styleUrls: ['./school-admin-group-detail-students.component.css']
})
export class SchoolAdminGroupDetailStudentsComponent implements OnInit {

  groupId: string;
  group: GroupModel;

  students: StudentModel[] = [];
  filter = new GroupQueryModel();
  hasMoreData = false;

  selectedStudents: StudentModel[] = [];
  isFetchingStudents = true;

  showManageStudentSelection = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService,
              private createStudentSv: EvoSchoolAdminCreateStudentModalService,
              private tSv: TranslateService,
              private cd: ChangeDetectorRef,
              private groupSv: SchoolAdminGroupService) { }

  ngOnInit() {

    this.filter.sort = 1;
    this.filter.limit = 50;

    this.route.parent.params.subscribe((params: Params) => {
      this.groupId = params.groupId;
      this.fetchStudentsForGroup();
      this.fetchGroup();
    });

  }

  onFilterApplied(filter: GroupQueryModel) {
    this.filter = filter;
    this.filter.skip = 0;
    this.students = [];
    this.fetchStudentsForGroup();
  }

  // Get all the students in the group
  fetchStudentsForGroup() {

    this.filter.skip = this.students.length;
    this.isFetchingStudents = true;

    this.groupSv.fetchGroupStudents(this.filter, this.groupId).subscribe((students) => {

      this.hasMoreData = students.length === this.filter.limit;
      this.isFetchingStudents = false;

      for (const student of students) {
        if (this.students.filter((s) => s._id === student._id).length === 0) {
          this.students.push(student);
        }
      }

    });
  }

  fetchGroup() {

    this.groupSv.fetchGroup(this.groupId).subscribe((group) => {
      this.group = group;
    });

  }

  onSearchStudents(studentName: string) {

    if (studentName === '' || !studentName) {
      this.filter.name = null;
    } else {
      this.filter.name = studentName;
    }

    this.students = [];
    this.selectedStudents = [];

    this.fetchStudentsForGroup();

  }

  studentsSelected(students: StudentModel[]) {

    this.selectedStudents = students;

  }

  onRemoveClicked() {

    const studentIds = [];
    for (const s of this.selectedStudents) {
      studentIds.push(s._id);
    }

    this.groupSv.removeStudentsFromGroup(this.groupId, studentIds).subscribe(() => {
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'),
        this.tSv.instant('schoolAdministration.groups.deleteStudentFromGroupModalSuccess'));
      this.selectedStudents = [];
      this.students = [];
      this.fetchStudentsForGroup();
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onSortChanged(sort: EVOStudentListSort) {

    this.filter.sort = sort.name;
    this.students = [];
    this.selectedStudents = [];

    this.fetchStudentsForGroup();

  }

  onManageStudentsClicked() {
    this.showManageStudentSelection = true;
  }

  /** Existing Account Logic **/

  onAddExisting() {
    this.showManageStudentSelection = false;
    this.router.navigate(['schooladmin', 'groups', this.groupId, 'addExistingStudents']);
  }

  onImportStudents() {
    this.showManageStudentSelection = false;
    this.router.navigate(['schooladmin', 'groups', this.groupId, 'addNewStudents']);
  }

  onAddSingleStudent() {
    this.showManageStudentSelection = false;
    this.createStudentSv.onCreate(SchoolModel.getCurrent().culture, SchoolModel.getCurrent()._id, this.groupId).subscribe((s) => {
      if (s) {
        this.fetchStudentsForGroup();
      }
    });
  }

}
