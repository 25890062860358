import {UserModel} from '../authentication/user.model';
import {StudentModel} from '../authentication/student.model';
import {ObjectiveModel} from '../edu-admin/objective.model';

export class GroupModel {

  static currentGroupKey = 'currentGroupKey';
  static currentGroup: GroupModel = null;

  public _id: string;

  public name: string;
  public grade: number;

  public teacherIds: string[];
  public studentIds: string[];

  public objective: ObjectiveModel;
  public objectiveId: string;

  public students: StudentModel[] = [];
  public teachers: UserModel[] = [];

  public createdAt: Date;
  public updatedAt: Date;

  /**
   * Used for list showing.
   */
  public hasMoreData = false;

  /**
   * Used for list showing
   */
  public searchString: string = null;

  static generateModel(json: any): GroupModel {

    const group = new GroupModel();
    group._id = json._id;

    group.name = json.name;
    group.grade = json.grade;

    group.teacherIds = json.teacherIds;
    group.studentIds = json.studentIds;

    if (json.objectiveId && typeof json.objectiveId === 'object') {
      group.objective = ObjectiveModel.generateModel(json.objectiveId);
      group.objectiveId = group.objective._id;
    } else {
      group.objectiveId = json.objectiveId;
    }

    if (json.createdAt) {
      group.createdAt = new Date(json.createdAt);
    }

    if (json.updatedAt) {
      group.updatedAt = new Date(json.updatedAt);
    }

    return group;
  }

  static generateModels(jsonList: any[]): GroupModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push(this.generateModel(json));
    }

    return list;

  }

  static getCurrent(): GroupModel {

    if (this.currentGroup) {
      return this.currentGroup;
    }

    const groupJson = localStorage.getItem(GroupModel.currentGroupKey);
    if (groupJson) {
      this.currentGroup = GroupModel.generateModel(JSON.parse(groupJson));
    }

    return this.currentGroup;
  }

  static setCurrent(group: GroupModel) {

    this.currentGroup = group;

    if (this.currentGroup) {
      localStorage.setItem(this.currentGroupKey, JSON.stringify(this.currentGroup));
    } else {
      this.removeCurrent();
    }

  }

  static removeCurrent() {
    this.currentGroup = null;
    localStorage.removeItem(this.currentGroupKey);
  }

  getTwoInitials(): string {

    if (!this.name) {
      return '';
    }

    return this.name.substring(0, 2).toUpperCase();

  }
}

export class GroupQueryModel {

  name: string;
  email: string = undefined;
  fromAge: number;
  toAge: number;
  isActive: undefined;
  skip = 0;
  limit: number = undefined;
  /**
   * Sorts by name
   */
  sort = -1;
}


