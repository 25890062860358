import { Component, OnInit } from '@angular/core';
import {AdminUserService} from '../../../../services/admin/admin-user.service';
import {ActivatedRoute, Params, Route, Router} from '@angular/router';
import {UserModel} from '../../../../models/authentication/user.model';
import {ModalService} from '../../../../shared/modals/modal/modal.service';
import {ErrorModel} from '../../../../models/shared/error.model';

@Component({
  selector: 'app-admin-user-settings',
  templateUrl: './admin-user-settings.component.html',
  styleUrls: ['./admin-user-settings.component.css']
})
export class AdminUserSettingsComponent implements OnInit {

  userId: string;
  user: UserModel;

  password: string;
  secret: string;

  constructor(private adminUserSv: AdminUserService,
              private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.userId = params.userId;
      this.fetchUser();

    });

  }

  fetchUser() {

    this.adminUserSv.fetchUserById(this.userId).subscribe((user) => {
      this.user = user;
    });

  }

  deleteUser() {

    this.modalSv.showChoiceModal('Warning', 'Are you sure you want to permanently delete this user and all of his connected data?').subscribe((response) => {

      if (response === true) {

        this.adminUserSv.deleteUser(this.userId, this.password, this.secret).subscribe((success) => {

          this.password = '';
          this.secret = '';

          this.modalSv.showAlertModal('Success', 'The operation was successful');
          this.router.navigate(['admin', 'users']);

        }, (err: ErrorModel) => {
          this.modalSv.showErrorModal('Error', err.message);
        });

      }

    });

  }

}
