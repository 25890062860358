import {Component, Input, OnInit} from '@angular/core';
import {SingleAdditionNeededAmountModel} from '../../../../../models/questions/subModels/singleAdditionNeededAmount.model';
import {QuestionModel} from '../../../../../models/questions/question.model';

@Component({
  selector: 'app-evo-single-addition-needed-amount',
  templateUrl: './evo-single-addition-needed-amount.component.html',
  styleUrls: ['./evo-single-addition-needed-amount.component.css']
})
export class EvoSingleAdditionNeededAmountComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new SingleAdditionNeededAmountModel();

  constructor() { }

  ngOnInit() {
  }

  castedQuestion(): SingleAdditionNeededAmountModel {

    return <SingleAdditionNeededAmountModel>this.question;
  }

  getOutcomeString(): string {

    let rangeMinOutcome = this.castedQuestion().model.total.minValue() - this.castedQuestion().model.firstRange.minValue();
    let rangeMaxOutcome = this.castedQuestion().model.total.maxValue() - this.castedQuestion().model.firstRange.minValue();

    const minOutcome = this.castedQuestion().model.minOutcome;
    if (minOutcome) {

      // The minimum value which can occur which is 1 + minOutcome = x,
      // If x is in the range we know we can create such a range that satisfies
      // the constraints, if not we keep the original values computed and do not alter
      // the min outcome.
      if (rangeMinOutcome < minOutcome) {
        rangeMinOutcome = minOutcome;
      }

    }

    const maxOutcome = this.castedQuestion().model.maxOutcome;
    if (maxOutcome) {

      // The maximum value which can occur which is 1 + minOutcome = x,
      // If x is in the range we know we can create such a range that satisfies
      // the constraints, if not we keep the original values computed and do not alter
      // the max outcome.

      if (rangeMaxOutcome > maxOutcome) {
        rangeMaxOutcome = maxOutcome;
      }

    }


    let outcome = '[' + rangeMinOutcome.toString(10) + ', ';
    outcome += rangeMaxOutcome.toString(10) + ']';

    return outcome;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.singleAdditionNeededAmount) {
        return true;
      }
    }

    return false;
  }

}
