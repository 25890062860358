import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Route, Router} from '@angular/router';
import {UserModel} from '../../../models/authentication/user.model';
import {SchoolModel} from '../../../models/school/school.model';
import {StudentModel} from '../../../models/authentication/student.model';
import {UserService} from '../../../services/user/user.service';
import {AuthService} from '../../../services/auth.service';
import {ErrorModel} from '../../../models/shared/error.model';
import {ModalService} from '../../../shared/modals/modal/modal.service';
import {TokenModel} from '../../../models/authentication/token.model';
import {TranslateService} from '@ngx-translate/core';
import {CultureModel} from '../../../models/localization/culture.model';
import {SchoolAdminLinkInvitationService} from '../../../services/school/school-admin-link-invitation.service';
import {SchoolAdminService} from '../../../services/school/school-admin.service';
import {environment} from '../../../../environments/environment';
import {RecaptchaComponent} from 'ng-recaptcha';

@Component({
  selector: 'app-teacher-link-invitation',
  templateUrl: './teacher-link-invitation.component.html',
  styleUrls: ['./teacher-link-invitation.component.css']
})
export class TeacherLinkInvitationComponent implements OnInit {

  state = 'loggedin';
  UserModel = UserModel;
  school: SchoolModel;

  showCreateAccount = false;
  showLogin = false;

  // for signup modal
  signupUser = new UserModel();

  linkId: string;

  // email and password
  email: string;
  password: string;
  confirmPassword: string;

  captcha: string;
  cultures = CultureModel.dropdownList();

  @ViewChild('captchaElem', { static: true })  captchaElem: RecaptchaComponent;

  constructor(private router: Router,
              private authSv: AuthService,
              private userSv: UserService,
              private schoolSv: SchoolAdminService,
              private schoolLinkInvSv: SchoolAdminLinkInvitationService,
              private modalSv: ModalService,
              private tSv: TranslateService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {

    if (UserModel.getCurrent()) {
      this.state = 'loggedin';
    } else {
      this.state = 'authenticate';
    }

    this.route.params.subscribe((params: Params) => {

      this.linkId = params.linkId;
      this.schoolSv.fetchSchoolByLinkId(this.linkId).subscribe((school) => {

        this.school = school;

        if (!UserModel.getCurrent() && this.school.culture) {
          this.tSv.use(this.school.culture);
        }

        if (!this.school.linkInvitationEnabled) {
          this.state = 'LinkInvitationDisabled';
        }

        this.signupUser.country = this.school.country;
        this.signupUser.culture = this.school.culture;

      }, (err: ErrorModel) => {

        if (err.name === 'SchoolNotFound') {
          this.state = 'LinkIncorrect';
        } else {
          this.modalSv.showTranslatedErrorModal(err);
        }

      });

    });

  }

  onDifferentAccountClicked() {
    this.state = 'authenticate';
  }

  onShowCreateAccountClicked() {
    this.showCreateAccount = true;
  }

  onShowLoginClicked() {
    this.showLogin = true;
  }

  onLoginClicked() {

    this.email = this.email.toLowerCase();
    this.authSv.signIn(this.email, this.password).subscribe((response) => {

      UserModel.setCurrent(response.user);
      TokenModel.setCurrent(response.token);
      SchoolModel.setCurrent(null);
      StudentModel.setCurrent(null);

      this.email = '';
      this.password = '';

      this.showLogin = false;
      this.state = 'loggedin';

      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.successMessage'));

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  captchaKey(): string {

    return environment.recaptchaV2Key;
  }

  captchaResponse(response: string) {

    this.captcha = response;

  }

  onSignupClicked() {

    if (UserModel.passwordErraenous(this.password, this.confirmPassword, this.modalSv, this.tSv)) {

      return ;
    }

    if (this.signupUser.country === null || this.signupUser.country === undefined) {

      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'),
        this.tSv.instant('signup.selectCountry'),
        this.tSv.instant('reusable.done'));
      return ;

    }

    if (this.signupUser.culture === null || this.signupUser.culture === undefined)  {

      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'),
        this.tSv.instant('signup.selectLanguage'),
        this.tSv.instant('reusable.done'));
      return ;

    }

    if (this.signupUser.email) {
      this.signupUser.email = this.signupUser.email.toLowerCase();
    }

    if (!this.captcha) {

      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'),
        this.tSv.instant('reusable.unknownError'));
      return;

    }

    this.authSv.signUp(this.signupUser, this.password, this.captcha).subscribe((response) => {

      UserModel.setCurrent(response.user);
      TokenModel.setCurrent(response.token);
      SchoolModel.setCurrent(null);
      StudentModel.setCurrent(null);

      this.password = null;
      this.confirmPassword = null;
      this.signupUser = new UserModel();
      this.signupUser.country = this.school.country;
      this.signupUser.culture = this.school.culture;

      this.showCreateAccount = false;
      this.state = 'loggedin';

      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.successMessage'));

    }, (err: ErrorModel) => {
      this.captchaElem.reset();
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  navigateHome() {

    if (StudentModel.getCurrent()) {
      this.router.navigate(['platform', 'dash']);
    } else if (UserModel.getCurrent()) {
      this.router.navigate(['selectstudent']);
    } else {
      this.router.navigate(['']);
    }
  }

  onJoinNowClicked() {

    this.schoolLinkInvSv.acceptLinkInvitation(this.linkId).subscribe((user) => {

      UserModel.setCurrent(user);
      SchoolModel.setCurrent(null);
      StudentModel.setCurrent(null);

      this.router.navigate(['selectschool']);

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onCancelClicked() {
    this.state = 'loggedin';
    this.email = '';
    this.password = '';
    this.confirmPassword = '';
    this.signupUser = new UserModel();
  }

  clearSigninInformation() {
    this.password = '';
    this.showLogin = false;
  }

  clearSignupInformation() {
    this.password = '';
    this.confirmPassword = '';
    this.showCreateAccount = false;
  }

}
