import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';
import { SchoolFilter, SchoolModel } from '../../models/school/school.model';
import { map } from 'rxjs/operators';
import { StringExtensionModel } from '../../models/extensions/string-extension.model';
import {SchoolLicenseModel} from '../../models/school/school-license.model';
import {UserModel} from '../../models/authentication/user.model';

@Injectable({
  providedIn: 'root'
})
export class AdminSchoolService {

  constructor(private authSv: AuthService) {
  }

  fetchSchools(filter?: SchoolFilter): Observable<SchoolModel[]> {

    let url = '/admin/schools';

    if (filter) {
      url += StringExtensionModel.queryString(filter);
    }

    return this.authSv.get(url).pipe(map((response) => {
      return SchoolModel.generateList(response.data.schools);

    }));

  }

  fetchSchoolById(schoolId: string): Observable<SchoolModel> {

    return this.authSv.get('/admin/schools/' + schoolId).pipe(map((response) => {

      const school = SchoolModel.generate(response.data);

      return school;

    }));

  }

  updateSchool(school: SchoolModel): Observable<SchoolModel> {

    return this.authSv.patch('/admin/schools', school).pipe(map((response) => {

      const createdSchool = SchoolModel.generate(response.data);

      return createdSchool;

    }));

  }

  fetchActiveLicenses(schoolId: string): Observable<{ license: SchoolLicenseModel, school: SchoolModel }> {

    if (schoolId) {

      const url = `/admin/schools/${schoolId}/activeStudentLicenses`;

      return this.authSv.get(url.toString()).pipe(map((response) => {

        const data = {
          ...response.data.school,
          activeStudents: response.data.activeStudents
        };

        const license = SchoolLicenseModel.generate(data);
        const school = SchoolModel.generate(response.data.school);

        return {
          school,
          license
        };

      }));

    }
  }

  enableLinkInvitation(schoolId: string, value: boolean): Observable<SchoolModel> {

    return this.authSv.patch(`/admin/schools/${schoolId}/enableLinkInvitation`, { linkInvitationEnabled: value }).pipe(map((response) => {

      const school = SchoolModel.generate(response.data);
      return school;

    }));

  }

  generateNewLink(schoolId: string): Observable<SchoolModel> {

    return this.authSv.patch(`/admin/schools/${schoolId}/generateNewLink`, {}).pipe(map((response) => {

      const school = SchoolModel.generate(response.data);
      return school;

    }));

  }

  deleteSchool(schoolId: string, password: string, secret: string): Observable<boolean> {

    return this.authSv.post('/admin/schools/' + schoolId + '/deleteSchool', { password, secret }).pipe(map((response) => {

      return true;

    }));

  }

}
