import {Component, Input, OnInit} from '@angular/core';
import {ChartConfiguration} from 'chart.js';
import {SkillGroupStructureModel} from '../../../models/student/skillGroupStatusModel';
import {TranslateService} from '@ngx-translate/core';
import {NumberExtensionModel} from '../../../models/extensions/number-extension.model';

@Component({
  selector: 'app-radar-progress-chart',
  templateUrl: './radar-progress-chart.component.html',
  styleUrls: ['./radar-progress-chart.component.css']
})
export class RadarProgressChartComponent implements OnInit {

  _placementStructure: SkillGroupStructureModel;
  @Input('placementStructure')
  set placementStructure(placementStructure: SkillGroupStructureModel) {
    this._placementStructure = placementStructure;
    this.setupChartData();
  }

  get placementStructure(): SkillGroupStructureModel {

    return this._placementStructure;
  }

  _skillStructure: SkillGroupStructureModel;
  @Input('skillStructure')
  set skillStructure(skillStructure: SkillGroupStructureModel) {
    this._skillStructure = skillStructure;
    this.setupChartData();
  }

  get skillStructure(): SkillGroupStructureModel {

    return this._skillStructure;
  }

  public radarChartLabels: string[] = [];

  public radarChartDatasets: ChartConfiguration['data']['datasets'] = [];

  /**
   * Styling https://stackoverflow.com/questions/59932763/how-to-increase-size-and-family-in-a-radars-chartjs-label
   */
  public radarChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'top',
      labels: {
        boxWidth: 25,
        padding: 20,
        fontFamily: '"Montserrat", sans-serif',
        fontStyle: '600',
        fontColor: '#80949A',
        fontSize: 10
      }
    },
    scale: {
      ticks: {
        beginAtZero: true,
        max: 100,
        min: 0,
        stepSize: 20,
        fontFamily: '"Montserrat", sans-serif',
        fontStyle: '600',
        fontColor: '#80949A',
        fontSize: 10,
        padding: 4
      },
      pointLabels: {
        fontFamily: '"Montserrat", sans-serif',
        fontStyle: '600',
        fontColor: '#54676E',
        fontSize: 10,
      }
    }
  };

  constructor(private tSv: TranslateService) { }

  ngOnInit() {
  }

  setupChartData() {

    if (this._skillStructure && this._placementStructure) {
      const translatedLabels = [];
      for (const cat of this.skillStructure.categories) {
        translatedLabels.push(this.tSv.instant('categories.' + cat));
      }

      this.radarChartLabels = translatedLabels;

      // Datasets
      const placementLabel = this.tSv.instant('groupOverview.skillReportComparisonStart');
      const statusLabel = this.tSv.instant('groupOverview.skillReportComparisonCurrent');

      const placementData = [];
      const statusData = [];

      for (const cat of this.skillStructure.categories) {

        const placementMastery = this.placementStructure.getCategoryMasteryPercentage(cat);
        const statusMastery = this.skillStructure.getCategoryMasteryPercentage(cat);

        placementData.push(NumberExtensionModel.truncate(placementMastery, 2));
        statusData.push(NumberExtensionModel.truncate(statusMastery, 2));
      }

      this.radarChartDatasets = [
        {
          data: placementData,
          label: placementLabel,
          fill: true,
          backgroundColor: 'rgba(0, 156, 204, 0.3)',
          borderColor: '#009CCC',
          borderWidth: 1,
          pointBackgroundColor: '#009CCC',
          pointBorderColor: '#ffffff',
          pointHoverBorderColor: '#009CCC',
          pointHoverBackgroundColor: '#ffffff'
        },
        {
          data: statusData,
          label: statusLabel,
          fill: true,
          backgroundColor: 'rgba(121, 197, 112, 0.3)',
          borderColor: '#79C570',
          borderWidth: 1,
          pointBackgroundColor: '#79C570',
          pointBorderColor: '#ffffff',
          pointHoverBorderColor: '#79C570',
          pointHoverBackgroundColor: '#ffffff'
        }
      ];
    }

  }
}
