import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-evo-info-cards',
  templateUrl: './evo-info-cards.component.html',
  styleUrls: ['./evo-info-cards.component.css']
})
export class EvoInfoCardsComponent implements OnInit {

  @Input() titleTranslationString: string;
  @Input() value: string;

  constructor() { }

  ngOnInit() {
  }

}
