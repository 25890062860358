import {DropdownModel} from '../../component/dropdown.model';

export const EvoItemImages = {
  Apple: 'Apple',
  'Apple Yellow': 'Apple Yellow',
  Aubergine: 'Aubergine',
  'Band Aid 20 Pack': 'Band Aid 20 Pack',
  'Band Aid Big': 'Band Aid Big',
  'Band Aid Small': 'Band Aid Small',
  Bolt: 'Bolt',
  Bone: 'Bone',
  'Bone Green': 'Bone Green',
  Branch: 'Branch',
  'Branch Green': 'Branch Green',
  Brick: 'Brick',
  'Butternut Squash': 'Butternut Squash',
  Cantalope: 'Cantalope',
  'Donut Toy': 'Donut Toy',
  'Donut Toy Blue': 'Donut Toy Blue',
  'Double Wood': 'Double Wood',
  'Electroshock Cream': 'Electroshock Cream',
  'Fat Fish': 'Fat Fish',
  'Frostbite Cream': 'Frostbite Cream',
  Gauze: 'Gauze',
  Hammer: 'Hammer',
  'Health Pack Big': 'Health Pack Big',
  'Health Pack Small': 'Health Pack Small',
  'Herb Bowl': 'Herb Bowl',
  'Honey Pot': 'Honey Pot',
  'Light Blue Crystal': 'Light Blue Crystal',
  'Lizard Toy': 'Lizard Toy',
  'Lizard Toy Green': 'Lizard Toy Green',
  Mango: 'Mango',
  'Mango Green': 'Mango Green',
  Meat: 'Meat',
  'Mouse Toy': 'Mouse Toy',
  'Nail Box': 'Nail Box',
  Nail: 'Nail',
  Onions: 'Onions',
  Papaya: 'Papaya',
  Pepper: 'Pepper',
  Pineapple: 'Pineapple',
  'Pineapple Green': 'Pineapple Green',
  'Poison Rash Cream': 'Poison Rash Cream',
  Potato: 'Potato',
  'Potion Blue': 'Potion Blue',
  'Potion Green': 'Potion Green',
  'Potion Red': 'Potion Red',
  'Purple Crystal': 'Purple Crystal',
  Screw: 'Screw',
  'Small Blueberry Branch': 'Small Blueberry Branch',
  Spade: 'Spade',
  Spinach: 'Spinach',
  Strawberry: 'Strawberry',
  'Sunburn Cream': 'Sunburn Cream',
  Teddy: 'Teddy',
  'Tennis Ball': 'Tennis Ball',
  'Tennis Ball Green': 'Tennis Ball Green',
  Watercress: 'Watercress',
  Watermelon: 'Watermelon',
  Wood: 'Wood',
  'Yarn Ball': 'Yarn Ball',
  'Yarn Ball Green': 'Yarn Ball Green',
  list: function(): string[] {

    const values = [];
    for (const key of Object.keys(this)) {
      if (key !== 'list' && key !== 'dropdownList' && key !== 'imgUrl') {
        values.push(this[key]);
      }
    }

    return values;

  },
  dropdownList: function(includeNone: boolean = false): DropdownModel[] {

    const list = [];

    if (includeNone) {
      list.push(new DropdownModel('none', 'none', null));
    }

    for (const item of this.list()) {
      list.push(new DropdownModel(item, item, this.imgUrl(item)));
    }

    return list;

  },
  imgUrl: function(imgName: string): string {

    return `/assets/gameIcons/items/${imgName}.png`;

  }
};

export const EvoMonsterImages = {
  'Aceno': 'Aceno',
  'Amarie': 'Amarie',
  'Amoria': 'Amoria',
  'Aqua': 'Aqua',
  'Aquaria': 'Aquaria',
  'Aurus': 'Aurus',
  'Ava': 'Ava',
  'Azul': 'Azul',
  'Badger': 'Badger',
  'Bloom': 'Bloom',
  'Bran': 'Bran',
  'Bulk': 'Bulk',
  'Buster': 'Buster',
  'Cartus': 'Cartus',
  'Chi Chi': 'Chi Chi',
  'Cinder': 'Cinder',
  'Dart': 'Dart',
  'Duster': 'Duster',
  'Fable': 'Fable',
  'Fate': 'Fate',
  'Fennec': 'Fennec',
  'Fernobat': 'Fernobat',
  'Fieree': 'Fieree',
  'Frost Mouse': 'Frost Mouse',
  'Funus': 'Funus',
  'Fyrbat': 'Fyrbat',
  'Garrada': 'Garrada',
  'Harpie': 'Harpie',
  'Kangagroo': 'Kangagroo',
  'Kayla': 'Kayla',
  'Kindle': 'Kindle',
  'Kyra': 'Kyra',
  'Larvo': 'Larvo',
  'Leela': 'Leela',
  'Leva': 'Leva',
  'Leya': 'Leya',
  'Ligher': 'Ligher',
  'Maine': 'Maine',
  'Nala': 'Nala',
  'Paglie': 'Paglie',
  'Shockz': 'Shockz',
  'Slender': 'Slender',
  'Slider': 'Slider',
  'Spring': 'Spring',
  'Sprinter': 'Sprinter',
  'Squirrelo': 'Squirrelo',
  'Stampy': 'Stampy',
  'Storm': 'Storm',
  'Swirly': 'Swirly',
  'Terruz': 'Terruz',
  'Torch': 'Torch',
  'Tremor': 'Tremor',
  'Ventus': 'Ventus',
  'Wick': 'Wick',
  'Yoko': 'Yoko',
  list: function() {

    const values = [];
    for (const key of Object.keys(this)) {
      if (key !== 'list' &&
        key !== 'dropdownList' &&
        key !== 'imgUrl' &&
        key !== 'thumbnailDropdownList' &&
        key !== 'imgUrlThumbnail') {
        values.push(this[key]);
      }
    }

    return values;

  },
  dropdownList: function(includeNone = false): DropdownModel[] {

    const values = [];

    if (includeNone) {
      values.push(new DropdownModel('none', 'none', null));
    }

    for (const key of this.list()) {
      values.push( new DropdownModel(key, key , `/assets/gameIcons/monsters/${key}.png`) );
    }

    return values;

  },
  thumbnailDropdownList: function(includeNone = false): DropdownModel[] {

    const values = [];

    if (includeNone) {
      values.push(new DropdownModel('none', 'none', null));
    }

    for (const key of this.list()) {
      values.push( new DropdownModel(key, key , `/assets/gameIcons/monsterThumbnails/${key}.png`) );
    }

    return values;

  },
  imgUrl: function(imgName): string {

    return `/assets/gameIcons/monsters/${imgName}.png`;

  },
  imgUrlThumbnail: function (imgName): string {

    return `/assets/gameIcons/monsterThumbnails/${imgName}.png`;

  }
};

export const EvoAbilityIconImages = {
  'Acorn': 'Acorn',
  'Air Attack': 'Air Attack',
  'Air Frenzy': 'Air Frenzy',
  'Anger': 'Anger',
  'Bark': 'Bark',
  'Bellows Breath': 'Bellows Breath',
  'Bite': 'Bite',
  'Bubbles': 'Bubbles',
  'Charge': 'Charge',
  'Charm Shield': 'Charm Shield',
  'Chirp': 'Chirp',
  'Confuse': 'Confuse',
  'Crunch': 'Crunch',
  'Crystal Scratch': 'Crystal Scratch',
  'Distract': 'Distract',
  'Double Kick': 'Double Kick',
  'Drain Beam': 'Drain Beam',
  'Electric Blast': 'Electric Blast',
  'Electric Scratch': 'Electric Scratch',
  'Electric Shock': 'Electric Shock',
  'Electric Slam': 'Electric Slam',
  'Electric Tackle': 'Electric Tackle',
  'Ember Shield': 'Ember Shield',
  'Energy Boulder': 'Energy Boulder',
  'Energy Drain': 'Energy Drain',
  'Energy Shield': 'Energy Shield',
  'Energy Slam': 'Energy Slam',
  'Energy Uptake': 'Energy Uptake',
  'Explosive Seed': 'Explosive Seed',
  'Fire Bark': 'Fire Bark',
  'Fire Hit': 'Fire Hit',
  'Fire Run': 'Fire Run',
  'Fire Scratch': 'Fire Scratch',
  'Fire Spit': 'Fire Spit',
  'Fire Star': 'Fire Star',
  'Fire Torso Ball': 'Fire Torso Ball',
  'Flare': 'Flare',
  'Flying Charge': 'Flying Charge',
  'Flying Punch': 'Flying Punch',
  'Fox Howl': 'Fox Howl',
  'Fox Roar': 'Fox Roar',
  'Friendship': 'Friendship',
  'Gail': 'Gail',
  'Gnaw': 'Gnaw',
  'Golden Shield': 'Golden Shield',
  'Green Beam': 'Green Beam',
  'Green Explosion': 'Green Explosion',
  'Ground Slam': 'Ground Slam',
  'Ground Slam Shake': 'Ground Slam Shake',
  'Ground Spike': 'Ground Spike',
  'Ground Spikes': 'Ground Spikes',
  'Guard': 'Guard',
  'Headbutt': 'Headbutt',
  'Heart Tail Spin': 'Heart Tail Spin',
  'Hidden Energy': 'Hidden Energy',
  'Hidden Orb': 'Hidden Orb',
  'Hug': 'Hug',
  'Hum': 'Hum',
  'Ice Scratch': 'Ice Scratch',
  'Ice Tail Spin': 'Ice Tail Spin',
  'Icicles': 'Icicles',
  'Ignite': 'Ignite',
  'Jet': 'Jet',
  'Justice Punch': 'Justice Punch',
  'Kick': 'Kick',
  'Kindle': 'Kindle',
  'Leaf Scratches': 'Leaf Scratches',
  'Life Drain': 'Life Drain',
  'Light Feet': 'Light Feet',
  'Live Aura': 'Live Aura',
  'Love': 'Love',
  'Low Blow': 'Low Blow',
  'Magma Drops': 'Magma Drops',
  'Magma Fissure': 'Magma Fissure',
  'Magma Fury': 'Magma Fury',
  'Magma Rain': 'Magma Rain',
  'Magma Rock': 'Magma Rock',
  'Magma Throw': 'Magma Throw',
  'Mock': 'Mock',
  'Mud Toss': 'Mud Toss',
  'Multi Peck': 'Multi Peck',
  'Novillos': 'Novillos',
  'Peck': 'Peck',
  'Photosynthesis': 'Photosynthesis',
  'Plasma Bark': 'Plasma Bark',
  'Plasma Beam': 'Plasma Beam',
  'Plasma Blast': 'Plasma Blast',
  'Plasma Charge': 'Plasma Charge',
  'Plasma Charges': 'Plasma Charges',
  'Plasma Fusion': 'Plasma Fusion',
  'Plasma Pulse': 'Plasma Pulse',
  'Plasma Ray': 'Plasma Ray',
  'Plasma Reaction': 'Plasma Reaction',
  'Plasma Scratch': 'Plasma Scratch',
  'Plasma Shield': 'Plasma Shield',
  'Play Dirty': 'Play Dirty',
  'Poison Sting': 'Poison Sting',
  'Pomegrenade': 'Pomegrenade',
  'Pounce': 'Pounce',
  'Pound': 'Pound',
  'Punches': 'Punches',
  'Quick Attack': 'Quick Attack',
  'Rage': 'Rage',
  'Roast': 'Roast',
  'Sand Spit': 'Sand Spit',
  'Sand Toss': 'Sand Toss',
  'Scorch Shield': 'Scorch Shield',
  'Scorch': 'Scorch',
  'Scratch': 'Scratch',
  'Sharp Leaves': 'Sharp Leaves',
  'Sharp Wind': 'Sharp Wind',
  'Shielding Aura': 'Shielding Aura',
  'Shooting Star': 'Shooting Star',
  'Shriek': 'Shriek',
  'Sing': 'Sing',
  'Sip': 'Sip',
  'Source Of Life': 'Source Of Life',
  'Sparkle': 'Sparkle',
  'Spike Attack 6': 'Spike Attack 6',
  'Spike Attack': 'Spike Attack',
  'Spike Explosion': 'Spike Explosion',
  'Spike Strike': 'Spike Strike',
  'Spin': 'Spin',
  'Spittle': 'Spittle',
  'Spring': 'Spring',
  'Sprinkle': 'Sprinkle',
  'Squeeze': 'Squeeze',
  'Sting': 'Sting',
  'Storm': 'Storm',
  'Tail Spin Electric': 'Tail Spin Electric',
  'Tail Spin Fire': 'Tail Spin Fire',
  'Tail Spin Plasma': 'Tail Spin Plasma',
  'Tail Spin': 'Tail Spin',
  'Taunt': 'Taunt',
  'The BLOB': 'The BLOB',
  'Torrero': 'Torrero',
  'Trail Mix': 'Trail Mix',
  'Twinkle': 'Twinkle',
  'Vomit': 'Vomit',
  'Warden': 'Warden',
  'Water Hit': 'Water Hit',
  'Water Scratch': 'Water Scratch',
  'Wave': 'Wave',
  'Whirl': 'Whirl',
  'Wild Fire': 'Wild Fire',
  list: function() {

    const values = [];
    for (const key of Object.keys(this)) {
      if (key !== 'list' && key !== 'dropdownList' && key !== 'imgUrl') {
        values.push(this[key]);
      }
    }

    return values;

  },
  dropdownList: function(includeNone = false): DropdownModel[] {

    const values = [];

    if (includeNone) {
      values.push(new DropdownModel('none', 'none', null));
    }

    for (const key of this.list()) {
      values.push( new DropdownModel(key, key , `/assets/gameIcons/monsters/${key}.png`) );
    }

    return values;

  },
  imgUrl: function(imgName: string): string {

    return `/assets/gameIcons/monsters/${imgName}.png`;

  }
};

export const ImageCategoryTypes = {
  items: 'items',
  monsters: 'monsters',
  monsterThumbnails: 'monsterThumbnails',
  abilities: 'abilities',
  list: function() {

    return [
      this.items,
      this.monsters,
      this.monsterThumbnails,
      this.abilities
    ];

  },
  dropdownList(): DropdownModel[] {

    const list = [];
    for (const item of this.list()) {
      list.push(new DropdownModel(item, item, null));
    }

    return list;
  }
};

export class ImageTypeModel {

  category: string = ImageCategoryTypes.items;
  image: string;

  static generate(json: any): ImageTypeModel {

    const imgType = new ImageTypeModel();
    imgType.category = json.category;
    imgType.image = json.image;

    return imgType;

  }

  static generateModels(jsonList: any): ImageTypeModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push(this.generate(json));
    }

    return list;

  }

}
