import {Component, Input, OnInit} from '@angular/core';
import {SkillGroupStructureModel} from '../../models/student/skillGroupStatusModel';

@Component({
  selector: 'app-evo-skill-status-progress-bar',
  templateUrl: './evo-skill-status-progress-bar.component.html',
  styleUrls: ['./evo-skill-status-progress-bar.component.css']
})
export class EvoSkillStatusProgressBarComponent implements OnInit {

  @Input() status: SkillGroupStructureModel;
  @Input() showTotalProgress = false;

  constructor() { }

  ngOnInit() {
  }



}
