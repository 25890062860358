import {RangeModel} from '../shared/range.model';
import {QuestionModel} from '../question.model';
import {FractionSetupFromImageModel} from './fractionSetupFromImage.model';

export class ProportionsSetupFromImageModel extends QuestionModel {

  model = {
    numeratorRange: new RangeModel(),
    denominatorRange: new RangeModel(),
    missingFactor: FractionSetupFromImageModel.MissingFactorTypes.numerator,
    numeratorImage: '',
    denominatorImage: ''
  };

  static generateModel(json: any): ProportionsSetupFromImageModel {

    const q = new ProportionsSetupFromImageModel();

    if (json.model.numeratorRange) {
      q.model.numeratorRange = RangeModel.generateModel(json.model.numeratorRange);
    }

    if (json.model.denominatorRange) {
      q.model.denominatorRange = RangeModel.generateModel(json.model.denominatorRange);
    }

    q.model.missingFactor = json.model.missingFactor;
    q.model.numeratorImage = json.model.numeratorImage;
    q.model.denominatorImage = json.model.denominatorImage;

    return q;

  }

}
