import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionModel} from '../../../../../../models/questions/question.model';

@Component({
  selector: 'app-admin-placement-test-question-container',
  templateUrl: './admin-placement-test-question-container.component.html',
  styleUrls: ['./admin-placement-test-question-container.component.css']
})
export class AdminPlacementTestQuestionContainerComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() showDeleteButton = false;
  @Input() showLeftArrow = false;
  @Input() showRightArrow = false;

  @Output() editSectionClicked = new EventEmitter();
  @Output() rightButtonClicked = new EventEmitter();
  @Output() leftButtonClicked = new EventEmitter();
  @Output() deleteButtonClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onEditSectionClicked(event: Event) {

    event.stopPropagation();

    if (this.editSectionClicked) {
      this.editSectionClicked.emit();
    }

  }

  onRightButtonClicked(event: Event) {

    event.stopPropagation();

    if (this.rightButtonClicked) {
      this.rightButtonClicked.emit();
    }

  }

  onLeftButtonClicked(event: Event) {

    event.stopPropagation();

    if (this.leftButtonClicked) {
      this.leftButtonClicked.emit();
    }

  }

  onDeleteButtonClicked(event: Event) {

    event.stopPropagation();

    if (this.deleteButtonClicked) {
      this.deleteButtonClicked.emit();
    }

  }

}
