import { Injectable } from '@angular/core';
import {AuthService} from '../auth.service';
import {Observable} from 'rxjs';
import {SchoolModel} from '../../models/school/school.model';
import {map} from 'rxjs/operators';
import {UserModel} from '../../models/authentication/user.model';

@Injectable({
  providedIn: 'root'
})
export class SchoolAdminLinkInvitationService {

  constructor(private authSv: AuthService) { }

  enableLinkInvitation(schoolId: string, value: boolean): Observable<SchoolModel> {

    return this.authSv.patch(`/schools/${schoolId}/enableLinkInvitation`, { linkInvitationEnabled: value }).pipe(map((response) => {

      const school = SchoolModel.generate(response.data);
      return school;

    }));

  }

  generateNewLink(schoolId: string): Observable<SchoolModel> {

    return this.authSv.patch(`/schools/${schoolId}/generateNewLink`, {}).pipe(map((response) => {

      const school = SchoolModel.generate(response.data);
      return school;

    }));

  }

  acceptLinkInvitation(linkId: string): Observable<UserModel> {

    return this.authSv.post(`/schools/${linkId}/acceptLinkInvitation`, {}).pipe(map((response) => {

      const user = UserModel.generate(response.data.user);
      return user;

    }));

  }

}
