import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SkillGroupModel} from '../../../models/edu-admin/skillGroup.model';
import {AdminSkillGroupService} from '../../../services/admin/admin-skill-group.service';
import {CultureModel} from '../../../models/localization/culture.model';
import {ModalService} from '../../../shared/modals/modal/modal.service';
import {EVOSegmentedControlItem} from '../../../shared/reusable-controls/evo-segmented-control/evo-segmented-control.component';

@Component({
  selector: 'app-school-skill-group-edit',
  templateUrl: './admin-skill-group-edit.component.html',
  styleUrls: ['./admin-skill-group-edit.component.css']
})
export class AdminSkillGroupEditComponent implements OnInit {

  isEditMode = false;
  skillGroup: SkillGroupModel = new SkillGroupModel();

  cultures = CultureModel.privateDropdownList();

  controls: EVOSegmentedControlItem[] = [
    new EVOSegmentedControlItem('questions', 'Questions'),
    new EVOSegmentedControlItem('settings', 'sidebar.settings')
  ];

  currentItem = this.controls[0];


  constructor(private router: Router,
              private route: ActivatedRoute,
              private modalSv: ModalService,
              private skillGroupSv: AdminSkillGroupService) {
  }

  ngOnInit() {

    const urlParams = this.router.url.split('/');
    const page = urlParams[urlParams.length - 1];

    for (const item of this.controls) {
      if (item.key === page) {
        this.currentItem = item;
      }
    }

    this.route.params.subscribe((params) => {

      const skillGroupId = params.skillGroupId;

      this.skillGroupSv.fetchSkillGroupById(skillGroupId).subscribe((skillGroup) => {
        this.skillGroup = skillGroup;
      });

    });
  }

  onPageChanged(item: EVOSegmentedControlItem) {
    this.currentItem = item;
    this.router.navigate(['admin', 'skillGroups', this.skillGroup._id, 'detail', item.key]);
  }

}
