import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EvoDatePickerComponent} from '../evo-date-picker/evo-date-picker.component';

@Component({
  selector: 'app-evo-month-year-picker',
  templateUrl: './evo-month-year-picker.component.html',
  styleUrls: ['./evo-month-year-picker.component.css']
})
export class EvoMonthYearPickerComponent implements OnInit {

  _currentValue: Date = new Date();

  @Input('currentValue')
  set currentValue(value: Date) {

    if (!value) {
      this._currentValue = new Date();
    } else {
      this._currentValue = value;
    }

    this._currentValue.setDate(1);
    this._currentValue.setHours(0);
    this._currentValue.setMinutes(0);
    this._currentValue.setSeconds(0);
    this._currentValue.setMilliseconds(0);

  }

  get currentValue(): Date {

    return this._currentValue;
  }

  @Output() valueChanged = new EventEmitter<Date>();

  MonthsDropdown = EvoDatePickerComponent.MonthsDropdown;
  YearDropdown: { key: string, value: string, iconURL: string }[] = [];

  constructor() { }

  ngOnInit() {

    const birthYear = (new Date()).getFullYear();

    for (let i = 0; i < 100; i++) {
      const year = birthYear - i;
      this.YearDropdown.push({
        key: year.toString(10),
        value: year.toString(10),
        iconURL: null
      });
    }
  }

  getBirthMonth(): string {

    return (this._currentValue.getMonth() + 1).toString(10);
  }

  onBirthMonthChanged(value: string) {

    const month = parseInt(value, 10) - 1;
    this._currentValue.setMonth(month);

    this.onValueChanged();

  }

  getBirthYear(): string {

    return this._currentValue.getFullYear().toString(10);
  }

  onBirthYearChanged(value: string) {

    const year = parseInt(value, 10);
    this._currentValue.setFullYear(year);

    this.onValueChanged();

  }

  onValueChanged() {

    if (this.valueChanged) {
      this.valueChanged.emit(this._currentValue);
    }

  }

}
