import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CultureModel} from '../../../../models/localization/culture.model';
import {UserModel} from '../../../../models/authentication/user.model';
import {StudentModel} from '../../../../models/authentication/student.model';
import {SchoolModel} from '../../../../models/school/school.model';
import {TokenModel} from '../../../../models/authentication/token.model';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-group-sidebar',
  templateUrl: './group-sidebar.component.html',
  styleUrls: ['./group-sidebar.component.css']
})
export class GroupSidebarComponent implements OnInit {

  groupId: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authSv: AuthService) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.groupId = params.groupId;
    });
  }

  /*
  onDashboardClicked() {
    this.router.navigate(['groups', this.groupId, 'dash']);
  }

   */

  dashboardRouterLink(): string {
    return `dash`;
  }

  onStudentsClicked() {
    this.router.navigate(['groupOverview', this.groupId, 'students']);
  }

  onSettingsClicked() {
    this.router.navigate(['groups', this.groupId, 'settings']);
  }

  onLogoutClicked() {

    // Send a signout request.
    this.authSv.signOut().subscribe();
    if (CultureModel.getHomepageCulture()) {
      this.router.navigate(['home', CultureModel.getHomepageCulture(), 'landing']);
    } else {
      this.router.navigate(['home', CultureModel.enGB, 'landing']);
    }

    UserModel.setCurrent( null );
    StudentModel.setCurrent( null );
    SchoolModel.setCurrent( null );
    TokenModel.setCurrent( null );

  }


}
