import { Injectable } from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {QuestionModel} from '../../../models/questions/question.model';
import {EVOQuestionFilter, AdminQuestionService} from '../../../services/admin/admin-question.service';
import {UserModel} from '../../../models/authentication/user.model';
import {BookService} from '../../../services/public/book.service';
import {BookModel} from '../../../models/edu-admin/book.model';
import {BookVersionModel} from '../../../models/edu-admin/book-version.model';

@Injectable({
  providedIn: 'root'
})
export class AdminBookSelectionListService {

  isVisible = false;
  subscriber: Subscriber<BookVersionModel>;

  books: BookModel[] = [];

  constructor(private bookSv: BookService) { }

  show(): Observable<BookVersionModel> {

    this.isVisible = true;

    this.fetchBooks();

    return new Observable((subscriber: Subscriber<BookVersionModel>) => {
      this.subscriber = subscriber;
    });

  }

  fetchBooks() {

    this.bookSv.fetchPublicBooks(UserModel.getAdminCountry()).subscribe((books) => {
      this.books = books;
    });

  }

  close() {

    this.subscriber.next(null);
    this.subscriber.complete();
    this.isVisible = false;

  }

  addBookVersion(version: BookVersionModel) {

    this.subscriber.next(version);
    this.subscriber.complete();
    this.isVisible = false;

  }

}
