import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Route, Router} from '@angular/router';
import {ModalService} from '../../../../shared/modals/modal/modal.service';
import {AdminSkillGroupService} from '../../../../services/admin/admin-skill-group.service';
import {SkillGroupModel} from '../../../../models/edu-admin/skillGroup.model';
import {QuestionModel} from '../../../../models/questions/question.model';
import {ErrorModel} from '../../../../models/shared/error.model';
import {EVOQuestionFilter} from '../../../../services/admin/admin-question.service';
import {AdminQuestionSelectionListService} from '../../../shared/admin-question-selection-list/admin-question-selection-list.service';
import {StringExtensionModel} from '../../../../models/extensions/string-extension.model';

@Component({
  selector: 'app-admin-skill-group-questions',
  templateUrl: './admin-skill-group-questions.component.html',
  styleUrls: ['./admin-skill-group-questions.component.css']
})
export class AdminSkillGroupQuestionsComponent implements OnInit {

  skillGroup: SkillGroupModel;

  selectedQuestions: QuestionModel[] = [];

  skillGroupId: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private skillGroupSv: AdminSkillGroupService,
              private modalSv: ModalService,
              private selectionSv: AdminQuestionSelectionListService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.skillGroupId = params.skillGroupId;
      this.fetchSkillGroup();

    });

  }

  fetchSkillGroup() {
    this.skillGroupSv.fetchSkillGroupById(this.skillGroupId).subscribe((skillGroup) => {
      this.skillGroup = skillGroup;
    });
  }

  onRemoveQuestions() {

    for (const q of this.selectedQuestions) {

      let index = -1;

      for (let i = 0; i < this.skillGroup.questions.length; i++) {
        const comparisonQ = this.skillGroup.questions[i];
        if (comparisonQ._id === q._id) {
          index = i;
          break;
        }
      }

      if (index !== -1) {
        this.skillGroup.questions.splice(index, 1);
      }

    }

    this.skillGroupSv.updateSkillGroup(this.skillGroup, true).subscribe((skillGroup) => {
      this.skillGroup = skillGroup;
      this.selectedQuestions = [];
      this.modalSv.showAlertModal('Success', 'Questions successfully removed from skill group');
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  getQuestions() {

    if (this.skillGroup) {
      return this.skillGroup.questions;
    }

    return [];
  }

  onSearchQuestions(name: string) {

  }

  onShowAddQuestions() {

    const questionIdsToExclude = StringExtensionModel.queryArrayString(this.skillGroup.questionIds);

    this.selectionSv.show(questionIdsToExclude).subscribe((questions) => {

      if (questions !== null) {
        this.skillGroup.questions = this.skillGroup.questions.concat(questions);

        this.skillGroupSv.updateSkillGroup(this.skillGroup, true).subscribe((skillGroup) => {

          this.modalSv.showAlertModal('Success', 'Questions successfully added to skill group');

          this.skillGroup = skillGroup;

        }, (err: ErrorModel) => {
          this.modalSv.showTranslatedErrorModal(err);
        });

      }

    });

  }

  onSaveSkillGroup() {

    this.onUpdateQuestions(this.skillGroup.questions);

  }

  onUpdateQuestions(questions: QuestionModel[]) {

    this.skillGroup.questions = questions;

    this.skillGroupSv.updateSkillGroup(this.skillGroup, true).subscribe((skillGroup) => {

      this.modalSv.showAlertModal('Success', 'Updated the skill group successfully.');

      this.skillGroup = skillGroup;

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

}
