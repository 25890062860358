export class DateExtensionModel {

  private static monthNames = ['January', 'February',
    'March', 'April', 'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'];

  /**
   * @description Creates a date object from a string that is ISO-8601 compliant. If you pass null or undefined
   * The method will return a null value.
   *
   * @param string The string to change to a date.
   */
  static date(string: string): Date {

    if (string !== undefined && string !== null) {

      return new Date(string);
    }

    return null;
  }

  static monthName(monthNumber: number): string {

    return this.monthNames[monthNumber];
  }

  /**
   * Takes in a date, creates a new one, and adds days to it and returns
   * a new date
   *
   * https://stackoverflow.com/questions/563406/how-to-add-days-to-date
   *
   * @param startDate The date which we add the days to
   * @param days The number of days to add
   */
  static addDays(startDate: Date, days: number) {

    const incrementedDate = new Date(startDate.getTime());
    incrementedDate.setDate(incrementedDate.getDate() + days);

    return incrementedDate;

  }

}
