export class EVOFlexSizeModel {

  xs: string = null;
  sm: string = null;
  md: string = null;
  lg: string = null;
  xl: string = null;

  default: string;

  constructor(defaultSize: string, xs: string = null, sm: string = null, md: string = null, lg: string = null, xl: string = null) {
    this.default = defaultSize;
    this.xs = xs;
    this.sm = sm;
    this.md = md;
    this.lg = lg;
    this.xl = xl;
  }

  xsSize(): string {

    if (this.xs == null) {
      return this.default;
    }

    return this.xs;

  }

  smSize(): string {

    if (this.sm == null) {
      return this.default;
    }

    return this.sm;

  }

  mdSize(): string {

    if (this.md == null) {
      return this.default;
    }


    return this.md;

  }

  lgSize(): string {

    if (this.lg == null) {
      return this.default;
    }

    return this.lg;

  }

  xlSize(): string {

    if (this.xl == null) {
      return this.default;
    }

    return this.xl;

  }

  defaultSize(): string {

    return this.default;
  }

}
