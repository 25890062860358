import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-root',
  templateUrl: './group-root.component.html',
  styleUrls: ['./group-root.component.css']
})
export class GroupRootComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
