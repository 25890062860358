import {Component, Input, OnInit} from '@angular/core';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {FractionSetupFromImageModel} from '../../../../../models/questions/subModels/fractionSetupFromImage.model';

@Component({
  selector: 'app-evo-fraction-setup-from-image',
  templateUrl: './evo-fraction-setup-from-image.component.html',
  styleUrls: ['./evo-fraction-setup-from-image.component.css']
})
export class EvoFractionSetupFromImageComponent implements OnInit {

  missingFactorsDropdownList = FractionSetupFromImageModel.MissingFactorTypes.dropdownList();
  @Input() question: QuestionModel = new FractionSetupFromImageModel();

  constructor() { }

  ngOnInit() {
  }

  castQuestion(): FractionSetupFromImageModel {

    return <FractionSetupFromImageModel>this.question;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage) {
        return true;
      }
    }

    return false;
  }

}
