import {Component, Input, OnInit} from '@angular/core';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {RepeatedAdditionModel} from '../../../../../models/questions/subModels/repeatedAddition.model';

@Component({
  selector: 'app-evo-repeated-addition',
  templateUrl: './evo-repeated-addition.component.html',
  styleUrls: ['./evo-repeated-addition.component.css']
})
export class EvoRepeatedAdditionComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new RepeatedAdditionModel();

  constructor() { }

  ngOnInit() {
  }

  castedQuestion(): RepeatedAdditionModel {

    return <RepeatedAdditionModel>this.question;
  }

  showQuestion(): boolean {

    if (this.question.subCategory === QuestionModel.SubCategoryTypes.repeatedAddition) {

      return true;
    }

    return false;
  }

  questionString(): string {

    if (this.question) {

      return `(${this.castedQuestion().model.multipliedValueRange.getRange()}) * (${this.castedQuestion().model.multiplierRange.getRange()})`;
    }

    return null;
  }

}
