import {ChangeDetectorRef, Injectable} from '@angular/core';
import {AdminSchoolUserService} from '../../../../../../services/admin/admin-school-user.service';
import {AdminSchoolStudentService} from '../../../../../../services/admin/admin-school-student.service';
import {StudentRelationshipModel} from '../../../../../../models/authentication/student-relationship.model';
import {StudentModel} from '../../../../../../models/authentication/student.model';
import {forkJoin, Observable, Subscriber} from 'rxjs';
import {ErrorModel} from '../../../../../../models/shared/error.model';
import {ModalService} from '../../../../../../shared/modals/modal/modal.service';
import {StudentFilterModel} from '../../../../../../models/filters/student-filter.model';
import {EVOTeacherListSort} from '../../../../../../shared/list-views/evo-teacher-list-view/evo-teacher-list-view.component';

@Injectable({
  providedIn: 'root'
})
export class EvoAdminUserSelectStudentService {

  public isVisible = false;
  public schoolId: string;
  public userId: string;
  public filter = new StudentFilterModel();

  students: StudentModel[] = [];
  selectedStudents: StudentModel[] = [];
  hasMoreData = true;

  subscriber: Subscriber<void>;

  constructor(public userSv: AdminSchoolUserService,
              private modalSv: ModalService,
              public studentSv: AdminSchoolStudentService) { }

  show(schoolId: string, userId: string): Observable<void> {

    this.filter.limit = 20;
    this.filter.skip = 0;
    this.isVisible = true;
    this.schoolId = schoolId;
    this.userId = userId;

    this.fetchStudentsNotConnectedToUser();

    return Observable.create((subscriber) => {
      this.subscriber = subscriber;
    });

  }

  fetchStudentsNotConnectedToUser() {

    this.filter.skip = this.students.length;

    this.studentSv.fetchStudentsNotConnectedToTeacher(this.schoolId, this.userId, this.filter).subscribe((students) => {

      this.students = this.students.concat(students);

      if (students.length === 0 || students.length < this.filter.limit) {
        this.hasMoreData = false;
      } else {
        this.hasMoreData = true;
      }

    });

  }

  searchFilteredStudents(searchString: string) {

    if (searchString !== null && searchString.length > 0) {
      this.filter.name = searchString;
    } else {
      this.filter.name = undefined;
    }

    this.students = [];
    this.selectedStudents = [];

    this.fetchStudentsNotConnectedToUser();

  }

  done() {

    this.isVisible = false;
    this.schoolId = null;
    this.userId = null;
    this.students = [];
    this.selectedStudents = [];

    if (this.subscriber) {
      this.subscriber.next();
      this.subscriber.complete();
    }

  }

  onSortChanged(sort: EVOTeacherListSort) {

    this.filter.sort = sort.value;
    this.students = [];
    this.selectedStudents = [];
    this.fetchStudentsNotConnectedToUser();

  }

  addStudents(students: StudentModel[]) {

    const relationships = [];

    for (const student of students) {

      const relationship = new StudentRelationshipModel();
      relationship.studentId = student._id;
      relationship.userId = this.userId;
      relationship.schoolId = this.schoolId;
      relationship.relationshipType = StudentRelationshipModel.RelationshipTypes.teacher;
      relationship.userAccessType = StudentRelationshipModel.UserAccessTypes.edit;

      relationships.push(relationship);

    }

    this.userSv.updateSchoolUserStudentRelationships(this.schoolId, this.userId, relationships).subscribe((relationships) => {

      this.modalSv.showAlertModal('Success', 'Successfully added relatinoships');
      this.done();

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

}
