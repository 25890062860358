import {Component, Input, OnInit} from '@angular/core';
import {CountNumbersModel} from '../../../../../models/questions/subModels/countNumbers.model';
import {QuestionModel} from '../../../../../models/questions/question.model';

@Component({
  selector: 'app-evo-count-numbers',
  templateUrl: './evo-count-numbers.component.html',
  styleUrls: ['./evo-count-numbers.component.css']
})
export class EvoCountNumbersComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new CountNumbersModel();

  constructor() { }

  ngOnInit() {
  }

  castedQuestion() {

    return <CountNumbersModel>this.question;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.countNumbers) {
        return true;
      }
    }

    return false;
  }

}
