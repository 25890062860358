import { Injectable } from '@angular/core';
import {AuthService} from '../auth.service';
import {Observable} from 'rxjs';
import {BookModel} from '../../models/edu-admin/book.model';
import {map} from 'rxjs/operators';
import {BookVersionIntegrationModel} from '../../models/edu-admin/book-version.model';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  constructor(private authSv: AuthService) { }

  fetchPublicBooks(country: string): Observable<BookModel[]> {

    let url = '/booksPublic';
    if (country) {
      url += '?country=' + country;
    }

    return this.authSv.get(url, {}, false).pipe(map((response) => {

      const books = BookModel.generateModels(response.data);

      return books;

    }));

  }

  fetchPublicBook(bookId: string): Observable<BookModel> {

    return this.authSv.get('/books/' + bookId + '/public').pipe(map((response) => {

      const book = BookModel.generateModel(response.data);

      return book;

    }));

  }

  fetchPublicIntegration(integrationId: string): Observable<BookVersionIntegrationModel> {

    return this.authSv.get('/integrations/' + integrationId).pipe(map((response) => {

      const integration = BookVersionIntegrationModel.generateModel(response.data);
      return integration;

    }));

  }

}
