import {Component, Input, OnInit} from '@angular/core';
import {SchoolGroupsService} from '../../../../services/school/school-groups.service';
import {ActivatedRoute, Params} from '@angular/router';
import {DropdownModel} from '../../../../models/component/dropdown.model';
import {TranslateService} from '@ngx-translate/core';
import {SessionActivityModel} from '../../../../models/student/session.model';
import {StudentModel} from '../../../../models/authentication/student.model';

@Component({
  selector: 'app-group-dash-student-sessions',
  templateUrl: './group-dash-student-sessions.component.html',
  styleUrls: ['./group-dash-student-sessions.component.css']
})
export class GroupDashStudentSessionsComponent implements OnInit {

  /**
   * Group id
   */
  gId: string;

  // Time options
  optionsTime = [{
    key: 'thisW',
    value: this.tSv.instant('dashboard.chart.thisWeek'),
    iconURL: null
  }, {
    key: 'lastW',
    value: this.tSv.instant('dashboard.chart.lastWeek'),
    iconURL: null
  }, {
    key: 'lastM',
    value: this.tSv.instant('dashboard.chart.lastMonth'),
    iconURL: null
  }, {
    key: 'thisM',
    value: this.tSv.instant('dashboard.chart.thisMonth'),
    iconURL: null
  }];
  optionTimeSelected = new DropdownModel('thisW', null);

  today: Date = new Date();
  firstDay: Date = new Date();
  lastDay: Date = new Date();

  sort = 1;

  sessions: SessionActivityModel[] = [];
  sessionsToDisplay: SessionActivityModel[] = [];

  _students: StudentModel[] = [];
  @Input('students')
  set students(students: StudentModel[]) {
    this._students = students;
    this.setupSessions();
  }

  get students(): StudentModel[] {
    return this._students;
  }

  constructor(private groupSv: SchoolGroupsService,
              private route: ActivatedRoute,
              public tSv: TranslateService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.gId = params.groupId;
      this.setupDates();
      this.fetchSessionActivity();

    });
  }

  setupDates() {

    this.firstDay = new Date();
    this.lastDay = new Date();
    // By default, we show the equivalent of one week of data
    // The week days start at 0 so the value should be <= 6

    if (this.optionTimeSelected.key === 'thisW') {

      // We want to start at the beginning of the week, so we subtract the days of the week which
      // start at 0 so we have to add one to make it start ona monday
      this.firstDay.setDate(this.today.getDate() - this.today.getDay() + 1);
      // Do the same thing but find the end of that same week
      this.lastDay.setDate((this.today.getDate() + 7) - this.today.getDay() + 1);

    } else if (this.optionTimeSelected.key === 'lastW') {

      // Same logic as before but 7 days behind as it is the week before.
      this.firstDay.setDate((this.today.getDate() - 7) - this.today.getDay() + 1);
      this.lastDay.setDate(this.today.getDate() - this.today.getDay() + 1);

    } else if (this.optionTimeSelected.key === 'lastM') {

      // first day of the last month
      this.firstDay = new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1);
      // We go to the next month and set the date component to 0 to find the last day of the month before
      // to know how many days are in that month.
      const daysInMonth = (new Date(this.lastDay.getFullYear(), this.today.getMonth(), 0)).getDate();
      // Set the last day
      this.lastDay = new Date(this.firstDay.getFullYear(), this.today.getMonth() - 1, daysInMonth);

    } else if (this.optionTimeSelected.key === 'thisM') {

      // Get the first day of the current month
      this.firstDay = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
      // Gets the number of days in the month by setting the date component to 0 for the next month
      const daysInMonth = (new Date(this.lastDay.getFullYear(), this.today.getMonth() + 1, 0)).getDate();
      // Set the last day to the end of the current month
      this.lastDay = new Date(this.firstDay.getFullYear(), this.today.getMonth(), daysInMonth);

    }

    // Set the hour, minute and second components.
    this.firstDay.setHours(0, 0, 0);
    this.lastDay.setHours(23, 59, 59);

  }

  onSortChanged() {

    if (this.sort === 1) {
      this.sort = -1;
    } else {
      this.sort = 1;
    }

    this.fetchSessionActivity();

  }

  fetchSessionActivity() {

    this.groupSv.fetchGroupStudentSessions(this.gId, this.firstDay, this.lastDay, this.sort).subscribe((sessions) => {

      this.sessions = sessions;
      this.setupSessions();

    });


  }

  setupSessions() {

    this.sessionsToDisplay = [];
    this.sessionsToDisplay = this.sessionsToDisplay.concat(this.sessions);

    if (this._students.length > 0) {

      for (const st of this._students) {

        let found = false;
        for (const sess of this.sessions) {

          if (sess._id.studentId === st._id) {
            found = true;
            break;
          }

        }

        if (found === false) {

          const zeroSession = new SessionActivityModel();
          zeroSession._id = { studentId: st._id };
          zeroSession.student = st;
          zeroSession.sum = 0;
          zeroSession.average = 0;

          if (this.sort === 1) {
            this.sessionsToDisplay.splice(0, 0, zeroSession);
          } else {
            this.sessionsToDisplay.push(zeroSession);
          }

        }

      }

    }

    if (this.sessions.length > 0 && this._students.length > 0) {

      for (const sess of this.sessions) {

        for (const student of this._students) {
          if (sess._id.studentId === student._id) {
            sess.student = student;
            break;
          }
        }

      }

    }

  }

  dropdownIntervalChanged(key: string) {

    this.optionTimeSelected.key = key;
    this.setupDates();
    this.fetchSessionActivity();

  }

}
