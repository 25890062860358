import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {SchoolAdminGroupService} from '../../../../../services/school/school-admin-group.service';
import {GroupQueryModel} from '../../../../../models/school/group.model';
import {UserModel} from '../../../../../models/authentication/user.model';
import {EVOTeacherListSort} from '../../../../../shared/list-views/evo-teacher-list-view/evo-teacher-list-view.component';
import {forkJoin} from 'rxjs';
import {ErrorModel} from '../../../../../models/shared/error.model';
import {ModalService} from '../../../../../shared/modals/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {SchoolModel} from '../../../../../models/school/school.model';

@Component({
  selector: 'app-school-admin-group-detail-teachers',
  templateUrl: './school-admin-group-detail-teachers.component.html',
  styleUrls: ['./school-admin-group-detail-teachers.component.css']
})
export class SchoolAdminGroupDetailTeachersComponent implements OnInit {

  groupId: string;
  teachers: UserModel[] = [];
  filter = new GroupQueryModel();
  hasMoreData = false;
  showAddTeachers = false;
  SchoolModel = SchoolModel;

  selectedTeachers: UserModel[] = [];

  constructor(private route: ActivatedRoute,
              private modalSv: ModalService,
              private tSv: TranslateService,
              private groupSv: SchoolAdminGroupService) { }

  ngOnInit() {

    this.filter.sort = 1;

    this.route.parent.params.subscribe((params: Params) => {
      this.groupId = params.groupId;
      this.fetchTeachersForGroup();
    });

  }

  // Get all the students in the group
  fetchTeachersForGroup() {

    this.filter.skip = this.teachers.length;

    this.groupSv.fetchGroupTeachers(this.filter, this.groupId).subscribe((teachers) => {

      this.hasMoreData = teachers.length === this.filter.limit;
      for (const teacher of teachers) {
        if (this.teachers.filter((t) => t._id === teacher._id).length === 0) {
          this.teachers.push(teacher);
        }
      }

    });
  }

  onTeacherAdded() {
    this.teachers = [];
    this.showAddTeachers = false;
    this.fetchTeachersForGroup();
  }

  onSearchTeachers(nameOrEmail: string) {

    if (nameOrEmail === '' || !nameOrEmail) {
      this.filter.name = null;
    } else {
      this.filter.name = nameOrEmail;
    }

    this.teachers = [];
    this.selectedTeachers = [];

    this.fetchTeachersForGroup();

  }

  allTeachersSelected(teachers: UserModel[]) {

    this.selectedTeachers = teachers;

  }

  onRemoveClicked() {

    const removeTeachers = [];
    for (const teacher of this.selectedTeachers) {
      removeTeachers.push(this.groupSv.removeTeacherFromGroup(this.groupId, teacher._id));
    }

    forkJoin(removeTeachers).subscribe(() => {
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'),
        this.tSv.instant('schoolAdministration.groups.deleteTeacherFromGroupModalSuccess'));
      this.selectedTeachers = [];
      this.teachers = [];
      this.fetchTeachersForGroup();
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onSortChanged(sort: EVOTeacherListSort) {

    this.filter.sort = sort.value;
    this.teachers = [];
    this.selectedTeachers = [];

    this.fetchTeachersForGroup();

  }

}
