import {EVOFlexSizeModel} from './flex-size.model';

export class EVOListViewColumn {

  name: string;
  size: EVOFlexSizeModel = new EVOFlexSizeModel('140px', null, null, '180px', '180px', '180px');
  isVisible = true;

  static xsFixedColumn(name: string, isVisible: boolean = true): EVOListViewColumn {

    const column = new EVOListViewColumn();
    column.name = name;
    column.size = new EVOFlexSizeModel('80px', null, null, null, null, null);
    column.isVisible = isVisible;

    return column;

  }

  static smallFixedColumn(name: string, isVisible: boolean = true): EVOListViewColumn {

    const column = new EVOListViewColumn();
    column.name = name;
    column.size = new EVOFlexSizeModel('140px', null, null, '180px', '180px', '180px');
    column.isVisible = isVisible;

    return column;
  }

  static smallGrowColumn(name: string, isVisible: boolean = true): EVOListViewColumn {

    const column = new EVOListViewColumn();
    column.name = name;
    column.size = new EVOFlexSizeModel('grow', '300px', null, null, null, null);
    column.isVisible = isVisible;

    return column;

  }

  static mediumGrowColumn(name: string, isVisible: boolean = true): EVOListViewColumn {

    const column = new EVOListViewColumn();
    column.name = name;
    column.size = new EVOFlexSizeModel('grow', '300px', '300px', null, null, null);
    column.isVisible = isVisible;

    return column;

  }

  static largeGrowColumn(name: string, isVisible: boolean = true): EVOListViewColumn {

    const column = new EVOListViewColumn();
    column.name = name;
    column.size = new EVOFlexSizeModel('grow', '300px', '300px', '460px', null, null);
    column.isVisible = isVisible;

    return column;

  }

  static customColumn(name: string, isVisible: boolean = true, defaultSize: string, xs: string, sm: string, md: string, lg: string, xl: string): EVOListViewColumn {

    const column = new EVOListViewColumn();
    column.name = name;
    column.size = new EVOFlexSizeModel(defaultSize, xs, sm, md, lg, xl);
    column.isVisible = isVisible;

    return column;

  }

  static calcWidth(size: string, columnDescriptors: EVOListViewColumn[]): string {

    let width = 40;

    for (const column of columnDescriptors) {

      if (column.isVisible === true) {

        const colSize = column.size[size + 'Size']();

        if (colSize.includes('px')) {
          width += parseInt(colSize.replace('px', ''), 10);
        } else {
          return '100%;';
        }

      }

    }

    return `calc(${width}px)`;

  }

}
