import {QuestionModel} from '../questions/question.model';
import {UserModel} from '../authentication/user.model';

export class AnswerModel {

  public _id: string;

  public answer: any;
  public questionModel: object;

  public subject: string;
  public topic: string;
  public category: string;
  public subCategory: string;
  public answerType: string;
  public representation: string;

  /**
   * @description Whether the answer to the question is correct or not.
   */
  public isCorrect: boolean;

  /**
   * @description The time it took for the user to answer the question.
   */
  public answerTime: number;

  /**
   * @description The date at which the answer was done.
   */
  public answeredAt: Date;

  /**
   * @description The country of the answer.
   */
  public country: string;
  public mastery: number;
  public context: string;

  public studentId: string;
  public questionId: string;
  public integrationId: string;
  public bookExamId: string;
  public sessionId: string;

  static generateModel(json: any): AnswerModel {

    const answer = new AnswerModel();

    answer._id = json._id;

    answer.answer = json.answer;
    answer.questionModel = json.questionModel;

    answer.subject = json.subject;
    answer.topic = json.topic;
    answer.category = json.category;
    answer.subCategory = json.subCategory;
    answer.answerType = json.answerType;
    answer.representation = json.representation;

    answer.isCorrect = json.isCorrect;
    answer.answerTime = json.answerTime;

    if (json.answeredAt) {
      answer.answeredAt = new Date(json.answeredAt);
    }

    answer.country = json.country;
    answer.mastery = json.mastery;
    answer.context = json.context;

    answer.studentId = json.studentId;
    answer.questionId = json.questionId;
    answer.integrationId = json.integrationId;
    answer.bookExamId = json.bookExamId;
    answer.sessionId = json.sessionId;

    return answer;

  }

  static generateModels(jsonList: any[]): AnswerModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push(AnswerModel.generateModel(json));
    }

    return list;
  }

  questionString(): string {

    if (this.subCategory === QuestionModel.SubCategoryTypes.countNumbers) {

      return this.questionModel['questionString'] + ' = ' + this.answer;

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.countShapes) {

      return this.questionModel['answerModel'] + ' = ' + this.answer;

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.countMultipleNumbers) {

      const answerOne = this.questionModel['answerModel']['one'];
      const answerTwo = this.questionModel['answerModel']['two'];

      return this.questionModel['answerModel']['one'] + ' = ' + this.answer['one'] + ' & ' + this.questionModel['answerModel']['two'];

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.countDiscrimination) {

      // TODO: Add count discrimination which is currently not in use

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.singleAddition ||
      this.subCategory === QuestionModel.SubCategoryTypes.doubleAddition ||
      this.subCategory === QuestionModel.SubCategoryTypes.singleSubtraction ||
      this.subCategory === QuestionModel.SubCategoryTypes.doubleSubtraction ||
      this.subCategory === QuestionModel.SubCategoryTypes.singleMultiplication ||
      this.subCategory === QuestionModel.SubCategoryTypes.singleDivision ||
      this.subCategory === QuestionModel.SubCategoryTypes.countNumbers) {

      return this.questionModel['questionString'] + ' ' + this.answer;

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.singleAdditionNeededAmount ||
      this.subCategory === QuestionModel.SubCategoryTypes.singleSubtractionNeededAmount) {

      return this.questionModel['questionString'];

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.repeatedAddition) {

      const multiplier = this.questionModel['parameters']['firstParam'];
      const multipliedValue = this.questionModel['parameters']['secondParam'];

      const answer = this.answer;

      let answerString = multipliedValue;

      for (let i = 1; i < multiplier; i++) {
        answerString += ' + ' + multipliedValue.toString();
      }
      answerString += ' = ' + answer.toString();

      return answerString;

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.sizeComparisonSymbols) {

      const firstNumber = this.questionModel['parameters']['firstParam'];
      const secondNumber = this.questionModel['parameters']['secondParam'];

      const symbolString = <string>(this.answer);
      let symbol = '';
      if (symbolString === QuestionModel.SizeComparisonTypes.equal) {
        symbol = ' = ';
      } else if (symbolString === QuestionModel.SizeComparisonTypes.greater) {
        symbol = ' > ';
      } else if (symbolString === QuestionModel.SizeComparisonTypes.less) {
        symbol = ' < ';
      }

      return firstNumber + symbol + secondNumber;

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.sizeComparisonWords) {

      const firstNumber = this.questionModel['parameters']['firstParam'];
      const secondNumber = this.questionModel['parameters']['secondParam'];

      const symbolString = this.answer;
      let symbol = '';
      if (symbolString === QuestionModel.SizeComparisonTypes.equal) {
        symbol = ' = ';
      } else if (symbolString === QuestionModel.SizeComparisonTypes.greater) {
        symbol = ' > ';
      } else if (symbolString === QuestionModel.SizeComparisonTypes.less) {
        symbol = ' < ';
      }

      return firstNumber + symbol + secondNumber;

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.sizeComparisonNumbers) {

      const firstNumber = this.questionModel['parameters']['secondParam'];
      const secondNumber = this.questionModel['parameters']['firstParam'];
      const symbolString = this.questionModel['parameters']['symbol'];

      let symbol = '';
      if (symbolString === QuestionModel.SizeComparisonTypes.equal) {
        symbol = ' = ';
      } else if (symbolString === QuestionModel.SizeComparisonTypes.greater) {
        symbol = ' > ';
      } else if (symbolString === QuestionModel.SizeComparisonTypes.less) {
        symbol = ' < ';
      }

      if (firstNumber === null) {
        return this.answer.toString() + symbol + secondNumber.toString();
      } else if (secondNumber === null) {
        return firstNumber.toString() + symbol + this.answer.toString();
      }

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.shapeNameFromImage) {

      const userAnswer = this.answer;
      const expectedAnswer = this.questionModel['answerModel'];

      return AnswerTranslations.shapeTranslation(userAnswer) + ' = ' + AnswerTranslations.shapeTranslation(expectedAnswer);

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.shapeSingleDiscriminationCount) {

      const userAnswer = this.answer;
      const expectedAnswer = this.questionModel['answerModel'];

      return userAnswer + ' = ' + expectedAnswer;

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.unitHouse) {

      const userAnswer = this.answer;
      const firstParam = this.questionModel['parameters']['firstParam'];
      const secondParam = this.questionModel['parameters']['secondParam'];

      let answer = `${secondParam}${firstParam}`;

      if (this.questionModel['numUnits'] > 2) {
        const thirdParam = this.questionModel['parameters']['thirdParam'];
        answer = `${thirdParam}${answer}`;
      }

      if (this.questionModel['numUnits'] > 3) {
        const fourthParam = this.questionModel['parameters']['fourthParam'];
        answer = `${fourthParam}${answer}`;
      }

      if (this.questionModel['numUnits'] > 4) {
        const fifthParam = this.questionModel['parameters']['fifthParams'];
        answer = `${fifthParam}${answer}`;
      }

      return answer + ' = ' + userAnswer;

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.numberPatternSingleFill) {

      const numberOfPatterns = Object.keys(this.questionModel['parameters']).length;

      let questionString = '';

      for (let i = 0; i < numberOfPatterns; i++) {

        const paramName = QuestionModel.ParameterNameTypes.paramNameByIndex(i);
        const value = this.questionModel['parameters'][paramName];

        if (value === null) {
          questionString += '_ ';
        } else {
          questionString += value.toString() + ' ';
        }

      }

      questionString += '= ' + this.answer.toString();

      return questionString;

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage) {

      // Should not do anything
      return '';

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.cornerNameFromImage) {

      return AnswerTranslations.cornerTranslation(this.answer);

    } else if (this.subCategory === QuestionModel.SubCategoryTypes.translation2D) {

      const aX = this.questionModel['answerModel']['one'];
      const aY = this.questionModel['answerModel']['two'];

      const qX = this.answer['one'];
      const qY = this.answer['two'];

      return `(${aX}, ${aY}) = (${qX}, ${qY})`;

    }

    return '';
  }

  answerCategoryIconURL(): string {

    return QuestionModel.categoryIconURL(this.category);

  }

  isCorrectIconURL(): any {

    if (this.isCorrect === true) {

      return {url: './assets/icons/white-checkmark-icon.svg', bgColor: 'greenLightBg', className: 'bigCheckmarkIcon'};

    } else if (this.isCorrect === false) {

      return {url: './assets/icons/close-icon.svg', bgColor: 'redBgIcon', className: 'bigCloseIcon'};

    }

    return null;
  }

  fractionNumeratorAnswer(): string {

    if (this.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage ||
      this.subCategory === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {
      return this.answer['one'];
    }

    return '';

  }

  fractionDenominatorAnswer(): string {

    if (this.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage ||
      this.subCategory === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {
      return this.answer['two'];
    }

    return '';
  }

  fractionNumeratorExpectedAnswer(): string {

    if (this.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage ||
      this.subCategory === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {


      return this.questionModel['answerModel']['one'];
    }

    return '';

  }

  fractionDenominatorExpectedAnswer(): string {

    if (this.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage ||
      this.subCategory === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {
      return this.questionModel['answerModel']['two'];
    }

    return '';
  }

}

export class AnswersGroupedModel {

  public _id: {
    year: number,
    month: number,
    week: number,
    dayOfMonth: number
  };

  answers: AnswerModel[] = [];

  static generateModel(json: any): AnswersGroupedModel {

    const group = new AnswersGroupedModel();
    group._id = {
      year: json._id.year,
      month: json._id.month,
      week: json._id.week,
      dayOfMonth: json._id.dayOfMonth
    };

    group.answers = AnswerModel.generateModels(json.answers);

    return group;

  }

  static generateModels(jsonList: any[]): AnswersGroupedModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push(this.generateModel(json));
    }

    return list;

  }


}

export class AnswerActivityModel {

  _id: {
    year: number,
    month: number,
    week: number,
    dayOfWeek: number,
    dayOfMonth: number,
    dayOfYear: number
  } = null;

  sum: number;
  date: Date;

  static generateModel(json: any): AnswerActivityModel {

    const answerActivity = new AnswerActivityModel();

    if (json._id === null) {
      answerActivity._id = null;
    } else {
      answerActivity._id = {
        year: json._id.year,
        month: json._id.month,
        week: json._id.week,
        dayOfWeek: json._id.dayOfWeek,
        dayOfMonth: json._id.dayOfMonth,
        dayOfYear: json._id.dayOfYear,
      };
    }

    answerActivity.sum = json.sum;

    if (json._id !== null) {
      answerActivity.date = new Date(json._id.year, json._id.month - 1, json._id.dayOfMonth);
    }

    return answerActivity;

  }

  static generateModels(jsonList: any[]): AnswerActivityModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push(AnswerActivityModel.generateModel(json));
    }

    return list;

  }

}

export class AnswerTranslations {

  private static Shapes = {
    circle: {
      'da-DK': 'cirkel',
      'de-DE': 'kreis',
      'en-GB': 'circle',
      'en-US': 'circle',
      'es-CL': 'círculo',
      'es-CO': 'círculo',
      'es-ES': 'círculo',
      'es-MX': 'círculo',
      'es-US': 'círculo',
      'fr-FR': 'cercle',
      'is-IS': 'hringur',
      'lt-LT': 'apskritimas',
      'nb-NO': 'sirkel',
      'pt-BR': 'círculo',
      'pt-PT': 'círculo',
      'sv-SE': 'cirkla'
    },
    square: {
      'da-DK': 'firkant',
      'de-DE': 'quadrat',
      'en-GB': 'square',
      'en-US': 'square',
      'es-CL': 'cuadrado',
      'es-CO': 'cuadrado',
      'es-ES': 'cuadrado',
      'es-MX': 'cuadrado',
      'es-US': 'cuadrado',
      'fr-FR': 'carré',
      'is-IS': 'ferningur',
      'lt-LT': 'kvadratas',
      'nb-NO': 'torget',
      'pt-BR': 'quadrado',
      'pt-PT': 'quadrado',
      'sv-SE': 'kvadrat'
    },
    rectangle: {
      'da-DK': 'rektangel',
      'de-DE': 'rechteck',
      'en-GB': 'rectangle',
      'en-US': 'rectangle',
      'es-CL': 'rectángulo',
      'es-CO': 'rectángulo',
      'es-ES': 'rectángulo',
      'es-MX': 'rectángulo',
      'es-US': 'rectángulo',
      'fr-FR': 'rectangle',
      'is-IS': 'ferhyrningur',
      'lt-LT': 'stačiakampis',
      'nb-NO': 'rektangel',
      'pt-BR': 'retângulo',
      'pt-PT': 'retângulo',
      'sv-SE': 'rektangel'
    },
    parallelogram: {
      'da-DK': 'parallelogram',
      'de-DE': 'parallelogramm',
      'en-GB': 'parallelogram',
      'en-US': 'parallelogram',
      'es-CL': 'paralelogramo',
      'es-CO': 'paralelogramo',
      'es-ES': 'paralelogramo',
      'es-MX': 'paralelogramo',
      'es-US': 'paralelogramo',
      'fr-FR': 'parallélogramme',
      'is-IS': 'samsíðungur',
      'lt-LT': 'lygiagretainis',
      'nb-NO': 'parallelogram',
      'pt-BR': 'paralelogramo',
      'pt-PT': 'paralelogramo',
      'sv-SE': 'parallellogram'
    },
    trapezium: {
      'da-DK': 'trapez',
      'de-DE': 'trapez',
      'en-GB': 'trapezium',
      'en-US': 'trapezoid',
      'es-CL': 'trapecio',
      'es-CO': 'trapecio',
      'es-ES': 'trapecio',
      'es-MX': 'trapecio',
      'es-US': 'trapecio',
      'fr-FR': 'trapèze',
      'is-IS': 'trapisa',
      'lt-LT': 'trapecija',
      'nb-NO': 'trapes',
      'pt-BR': 'trapézio',
      'pt-PT': 'trapézio',
      'sv-SE': 'trapets'
    },
    triangle: {
      'da-DK': 'trekant',
      'de-DE': 'dreieck',
      'en-GB': 'triangle',
      'en-US': 'triangle',
      'es-CL': 'triángulo',
      'es-CO': 'triángulo',
      'es-ES': 'triángulo',
      'es-MX': 'triángulo',
      'es-US': 'triángulo',
      'fr-FR': 'triangle',
      'is-IS': 'þríhyrningur',
      'lt-LT': 'trikampis',
      'nb-NO': 'triangel',
      'pt-BR': 'triângulo',
      'pt-PT': 'triângulo',
      'sv-SE': 'triangel'
    },
    pentagon: {
      'da-DK': 'femkant',
      'de-DE': 'fünfeck',
      'en-GB': 'pentagon',
      'en-US': 'pentagon',
      'es-CL': 'pentágono',
      'es-CO': 'pentágono',
      'es-ES': 'pentágono',
      'es-MX': 'pentágono',
      'es-US': 'pentágono',
      'fr-FR': 'pentagone',
      'is-IS': 'fimmhyrningur',
      'lt-LT': 'penkiakampis',
      'nb-NO': 'femkant',
      'pt-BR': 'pentágono',
      'pt-PT': 'pentágono',
      'sv-SE': 'femhörning'
    },
    hexagon: {
      'da-DK': 'sekskant',
      'de-DE': 'sechseck',
      'en-GB': 'hexagon',
      'en-US': 'hexagon',
      'es-CL': 'hexágono',
      'es-CO': 'hexágono',
      'es-ES': 'hexágono',
      'es-MX': 'hexágono',
      'es-US': 'hexágono',
      'fr-FR': 'hexagone',
      'is-IS': 'sexhyrningur',
      'lt-LT': 'šešiakampis',
      'nb-NO': 'sekskant',
      'pt-BR': 'hexágono',
      'pt-PT': 'hexágono',
      'sv-SE': 'sexhörning'
    },
    octagon: {
      'da-DK': 'ottekant',
      'de-DE': 'achteck',
      'en-GB': 'octagon',
      'en-US': 'octagon',
      'es-CL': 'octágono',
      'es-CO': 'octágono',
      'es-ES': 'octágono',
      'es-MX': 'octágono',
      'es-US': 'octágono',
      'fr-FR': 'octagone',
      'is-IS': 'átthyrningur',
      'lt-LT': 'aštuonkampis',
      'nb-NO': 'åttekant',
      'pt-BR': 'octagóno',
      'pt-PT': 'octagóno',
      'sv-SE': 'oktogon'
    }
  };

  private static Corners = {
    acute: {
      'da-DK': 'akut hjørne',
      'de-DE': 'spitze ecke',
      'en-GB': 'acute corner',
      'en-US': 'acute corner',
      'es-CL': 'esquina agudo',
      'es-CO': 'esquina agudo',
      'es-ES': 'esquina agudo',
      'es-MX': 'esquina agudo',
      'es-US': 'esquina agudo',
      'fr-FR': 'coin aigu',
      'is-IS': 'hvasst horn',
      'lt-LT': 'ūminis kampas',
      'nb-NO': 'akutt hjørne',
      'pt-BR': 'canto agudo',
      'pt-PT': 'canto agudo',
      'sv-SE': 'akut hörn'
    },
    obtuse: {
      'da-DK': 'stump hjørne',
      'de-DE': 'stumpfe ecke',
      'en-GB': 'obtuse corner',
      'en-US': 'obtuse corner',
      'es-CL': 'esquina obtuso',
      'es-CO': 'esquina obtuso',
      'es-ES': 'esquina obtuso',
      'es-MX': 'esquina obtuso',
      'es-US': 'esquina obtuso',
      'fr-FR': 'coin obtus',
      'is-IS': 'gleitt horn',
      'lt-LT': 'bukas kampas',
      'nb-NO': 'stump hjørne',
      'pt-BR': 'canto obtuso',
      'pt-PT': 'canto obtuso',
      'sv-SE': 'trubbigt hörn'
    },
    right: {
      'da-DK': 'retvinklet hjørne',
      'de-DE': 'rechtwinklige ecke',
      'en-GB': 'right corner',
      'en-US': 'right corner',
      'es-CL': 'esquina recto',
      'es-CO': 'esquina recto',
      'es-ES': 'esquina recto',
      'es-MX': 'esquina recto',
      'es-US': 'esquina recto',
      'fr-FR': 'coin droit',
      'is-IS': 'rétt horn',
      'lt-LT': 'stačiu kampu',
      'nb-NO': 'rettvinklet hjørne',
      'pt-BR': 'canto certo',
      'pt-PT': 'canto certo',
      'sv-SE': 'rätvinkligt hörn'
    }
  };

  static shapeTranslation(shape: string): string {

    const shapeType = this.Shapes[shape];
    if (shapeType) {

      const language = shapeType[UserModel.getCurrent().culture];
      if (language) {
        return language;
      }

    }

    return '';

  }

  static cornerTranslation(corner: string): string {

    const cornerType = this.Corners[corner];
    if (cornerType) {

      const translation = cornerType[UserModel.getCurrent().culture];
      if (translation) {
        return translation;
      }

    }

    return '';
  }

}

export class AnswerQueryModel {

  public subject: string = null;
  public topic: string = null;
  public category: string = null;
  public subCategory: string = null;
  public answerType: string = null;
  public isCorrect: boolean = null;
  public context: string = null;

  public integrationId: string = null;
  public bookExamId: string = null;
  public sessionId: string = null;

  public fromDate: Date = null;
  public toDate: Date = null;
  public skip = 0;
  public limit = 100;

  /**
   * @description Only used for the grouped answer endpoint. Groups by day, week, month, year.
   */
  public groupBy: string = null;
  /**
   * @description Only used for the grouped endpoint. 1 for ascending sort by the answeredAt date
   * and -1 descending sort by the answered At date.
   */
  public sortOrder: number = null;


}
