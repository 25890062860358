import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserModel} from '../../../models/authentication/user.model';
import {TranslateService} from '@ngx-translate/core';
import {EVOListViewColumn} from '../../../models/UI/EVOListViewColumn.model';

@Component({
  selector: 'app-evo-users-list-view',
  templateUrl: './evo-users-list-view.component.html',
  styleUrls: ['./evo-users-list-view.component.css']
})
export class EvoUsersListViewComponent implements OnInit {

  /**
   * Fixed or dynamic
   */
  @Input() heightType = 'fixed';

  /**
   * Used if height type is fixed.
   */
  @Input() height = '500px';

  @Input() users: UserModel[] = [];

  @Input() schoolId: string = null;

  allSelected = false;
  @Input() selectedUsers: UserModel[] = [];

  @Input() hasMoreData = false;

  @Output() usersSelected = new EventEmitter<UserModel[]>();
  @Output() fetchMore = new EventEmitter();

  @Input() sort = new EVOUserListSort();
  @Output() sortChanged = new EventEmitter<EVOUserListSort>();

  @Input() selectionColumn = EVOListViewColumn.xsFixedColumn('selection', false);
  @Input() nameColumn = EVOListViewColumn.largeGrowColumn('name');
  @Input() accountTypeColumn = EVOListViewColumn.smallFixedColumn('accountType');
  @Input() createdAtColumn = EVOListViewColumn.smallFixedColumn('createdAt');
  @Input() countryColumn = EVOListViewColumn.smallFixedColumn('country');

  @Input() userClickEnabled = false;
  @Output() userClicked = new EventEmitter<UserModel>();

  @Input('selectionEnabled')
  set selectionEnabled(selection: boolean) {
    this.selectionColumn.isVisible = selection;
  }

  getSelectionEnabled(): boolean {
    return this.selectionColumn.isVisible;
  }

  constructor(public tSv: TranslateService) { }

  ngOnInit() {
  }

  getContainerHeight() {

    if (this.heightType === 'fixed') {
      return this.height;
    }

    return 'auto';

  }

  getContentHeight() {

    if (this.heightType === 'fixed') {
      return 'calc(100% - 38px)';
    }

    return 'auto';

  }

  getYOverflow(): string {

    if (this.heightType === 'fixed') {
      return 'scroll';
    }

    return 'auto';
  }

  onAllSelected(selection: boolean) {

    if (selection) {
      this.selectedUsers = this.users;
    } else {
      this.selectedUsers = [];
    }

    this.allSelected = selection;

    if (this.usersSelected) {
      this.usersSelected.emit(this.selectedUsers);
    }

  }

  onTeacherSelect(teacher: UserModel) {

    const index = this.selectedUsers.indexOf(teacher);
    if (index !== -1) {
      this.selectedUsers.splice(index, 1);
    } else {
      this.selectedUsers.push(teacher);
    }

    if (this.usersSelected) {
      this.usersSelected.emit(this.selectedUsers);
    }

  }

  hasSelectedTeacher(teacher: UserModel): boolean {

    return this.selectedUsers.includes(teacher);

  }

  onFetchMoreData() {
    if (this.fetchMore) {
      this.fetchMore.emit();
    }
  }

  onNameLegendSortClicked() {

    if (this.sort.key !== 'name') {

      this.sort.key = 'name';
      this.sort.value = -1;

    } else {

      if (this.sort.value === 1) {
        this.sort.value = -1;
      } else {
        this.sort.value = 1;
      }

    }

    if (this.sortChanged) {
      this.sortChanged.emit(this.sort);
    }

  }

  onTeacherClicked(teacher: UserModel) {

    if (this.userClickEnabled === true && this.userClicked) {
      this.userClicked.emit(teacher);
    }

  }

  getSortArrowImgUrl(): string {

    if (this.sort.value === 1) {
      return './assets/icons/sort-up.svg';
    } else {
      return './assets/icons/sort-down.svg';
    }

  }

  calcWidth(size: string): string {

    return EVOListViewColumn.calcWidth(size, this.getColumnDescriptors());

  }

  getColumnDescriptors(): EVOListViewColumn[] {

    return [
      this.selectionColumn,
      this.nameColumn,
      this.accountTypeColumn,
      this.createdAtColumn,
      this.countryColumn
    ];

  }

}

export class EVOUserListSort {
  /**
   * The key to use for sorting
   */
  key = 'name';
  /**
   * Should be 1 or -1 for acending or descending
   */
  value = 1;
}
