import { Injectable } from '@angular/core';
import {SkillGroupFilter, SkillGroupModel} from '../../../models/edu-admin/skillGroup.model';
import {Observable, Subscriber} from 'rxjs';
import {AdminSkillGroupService} from '../../../services/admin/admin-skill-group.service';

@Injectable({
  providedIn: 'root'
})
export class AdminSkillGroupsSelectionListService {

  isVisible = false;
  subscriber: Subscriber<SkillGroupModel[]>;

  filter: SkillGroupFilter = new SkillGroupFilter();
  skillGroupsToAdd: SkillGroupModel[] = [];
  skillGroups: SkillGroupModel[] = [];
  hasMoreQuestions = false;

  constructor(private skillGroupSv: AdminSkillGroupService) { }

  show(idsToExclude: string = null, filter: SkillGroupFilter = null): Observable<SkillGroupModel[]> {

    this.isVisible = true;

    if (filter) {
      this.filter = filter;
    } else {
      this.filter = new SkillGroupFilter();
    }

    this.filter.limit = 100;
    this.filter.excludeSkillGroupIds = idsToExclude;

    this.fetchSkillGroups();

    return new Observable((subscriber: Subscriber<SkillGroupModel[]>) => {
      this.subscriber = subscriber;
    });

  }

  fetchSkillGroupsByName() {

    this.skillGroups = [];
    this.skillGroupsToAdd = [];

    this.fetchSkillGroups();

  }

  fetchSkillGroups() {

    this.filter.skip = this.skillGroups.length;

    this.skillGroupSv.fetchSkillGroups(this.filter).subscribe((skillGroups) => {

      this.skillGroups = this.skillGroups.concat(skillGroups);

      this.hasMoreQuestions = false;
      if (skillGroups.length === this.filter.limit) {
        this.hasMoreQuestions = true;
      }

    });

  }

  addSkillGroups() {

    this.subscriber.next(this.skillGroupsToAdd);
    this.subscriber.complete();
    this.fullReset();
    this.isVisible = false;

  }

  close() {

    this.subscriber.next(null);
    this.subscriber.complete();
    this.fullReset();
    this.isVisible = false;

  }

  fullReset() {
    this.filter.name = null;
    this.filter.skillGroupIds = null;
    this.filter.excludeSkillGroupIds = null;
    this.reset();
  }

  reset() {

    this.skillGroups = [];
    this.skillGroupsToAdd = [];

    this.filter.skip = 0;
    this.filter.limit = 100;

  }

}
