import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StudentModel} from '../../../../models/authentication/student.model';
import {AuthService} from '../../../../services/auth.service';
import {UserModel} from '../../../../models/authentication/user.model';
import {TokenModel} from '../../../../models/authentication/token.model';
import {MobileMenuService} from '../../../../mobile/mobile-menu/mobile-menu.service';
import {CultureModel} from '../../../../models/localization/culture.model';
import {SchoolModel} from '../../../../models/school/school.model';
import {UserSettingsModalService} from '../../settings/user-settings-modal/user-settings-modal.service';
import {TranslateService} from '@ngx-translate/core';
import {GroupModel} from '../../../../models/school/group.model';

@Component({
  selector: 'app-evo-header',
  templateUrl: './platform-header.component.html',
  styleUrls: ['./platform-header.component.css']
})
export class PlatformHeaderComponent implements OnInit {

  /**
   * Can be of type student or logout
   */
  @Input() type = 'student';
  @Input() hideHamburgerIcon = false;

  openUserDropdown = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authSv: AuthService,
              public tSv: TranslateService,
              private userSettingsSv: UserSettingsModalService,
              public mobileMenuSv: MobileMenuService) {
  }

  ngOnInit() {


  }

  userInitials(): string {

    if (UserModel.getCurrent()) {

      return UserModel.getCurrent().getTwoInitials();

    }

    return '';
  }

  userName(): string {

    if (UserModel.getCurrent()) {

      return UserModel.getCurrent().getFullName();
    }

    return '';
  }

  isTeacher(): boolean {

    if (UserModel.getCurrent().schoolAccess.length > 0) {
      return true;
    }

    return false;
  }

  isSchoolAdmin(): boolean {

    if (UserModel.getCurrent() && SchoolModel.getCurrent() && UserModel.getCurrent().hasSchoolAdminAccess(SchoolModel.getCurrent()._id)) {
      return true;
    }

    return false;
  }

  navigateHome() {

    if (StudentModel.getCurrent()) {
      this.router.navigate(['platform', 'dash']);
    } else {

      if (UserModel.getCurrent().hasSchoolAccess()) {
        // For teachers
        this.router.navigate(['selectschool']);
      } else {
        // For B2C users
        this.router.navigate(['selectstudent']);
      }

    }
  }

  isSelectingSchool(): boolean {

    return this.router.url.includes('selectschool');
  }

  isInGroupOverview(): boolean {

    return this.router.url.includes('groupOverview');
  }

  currentStudent(): StudentModel {

    return StudentModel.getCurrent();
  }

  currentSchool(): SchoolModel {
    return SchoolModel.getCurrent();
  }

  currentGroup(): GroupModel {
    return GroupModel.getCurrent();
  }

  onSwitchSchoolClicked() {

    this.openUserDropdown = false;
    StudentModel.removeCurrent();
    SchoolModel.removeCurrent();

    if (this.isTeacher()) {
      this.router.navigate(['selectschool']);
    } else {
      this.router.navigate(['selectstudent']);
    }

  }

  onSwitchStudentClicked() {

    this.openUserDropdown = false;
    StudentModel.removeCurrent();
    this.router.navigate(['selectstudent']);

  }

  onSwitchGroupClicked() {

    this.openUserDropdown = false;
    GroupModel.removeCurrent();
    this.router.navigate(['selectstudent']);

  }

  xsFlexSize() {

    if (this.hideHamburgerIcon === false) {
      return '80px';
    }

    return '120px';
  }

  onMobileMenuClicked() {

    if (this.mobileMenuSv.isVisible === true) {
      this.mobileMenuSv.isVisible = false;
    } else {
      this.mobileMenuSv.isVisible = true;
    }

  }

  onUserClicked() {
    this.openUserDropdown = !this.openUserDropdown;
  }

  onSchoolAdminClicked() {
    this.openUserDropdown = false;
    this.router.navigate(['schooladmin', 'teachers']);
  }

  onLogoutClicked() {

    this.openUserDropdown = false;

    // Send a signout request.
    this.authSv.signOut().subscribe();
    if (CultureModel.getHomepageCulture()) {
      this.router.navigate(['home', CultureModel.getHomepageCulture(), 'landing']);
    } else {
      this.router.navigate(['home', CultureModel.enGB, 'landing']);
    }

    UserModel.setCurrent(null);
    StudentModel.setCurrent(null);
    SchoolModel.setCurrent(null);
    TokenModel.setCurrent(null);

  }

  goToSettings() {
    this.openUserDropdown = false;
    this.userSettingsSv.onShow();
  }

}
