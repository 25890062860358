import {Component, Input, OnInit} from '@angular/core';
import {CountMultipleNumbersModel} from '../../../../../models/questions/subModels/countMultipleNumbers.model';
import {QuestionModel} from '../../../../../models/questions/question.model';

@Component({
  selector: 'app-evo-count-multiple-numbers',
  templateUrl: './evo-count-multiple-numbers.component.html',
  styleUrls: ['./evo-count-multiple-numbers.component.css']
})
export class EvoCountMultipleNumbersComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new CountMultipleNumbersModel();

  constructor() { }

  ngOnInit() {
  }

  castedQuestion(): CountMultipleNumbersModel {

    return <CountMultipleNumbersModel>this.question;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.countMultipleNumbers) {
        return true;
      }
    }

    return false;
  }

}
