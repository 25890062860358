import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SchoolModel} from '../../../models/school/school.model';
import {CountryModel} from '../../../models/localization/country.model';
import {EVOListViewColumn} from '../../../models/UI/EVOListViewColumn.model';

@Component({
  selector: 'app-evo-schools-list-view',
  templateUrl: './evo-schools-list-view.component.html',
  styleUrls: ['./evo-schools-list-view.component.css']
})
export class EvoSchoolsListViewComponent implements OnInit {

  CountryModel = CountryModel;

  /**
   * Fixed or dynamic
   */
  @Input() heightType = 'fixed';

  /**
   * Used if height type is fixed.
   */
  @Input() height = '500px';

  @Input() schools: SchoolModel[] = [];

  allSelected = false;
  @Input() selectedSchools: SchoolModel[] = [];

  @Input() hasMoreData = false;

  @Output() schoolsSelected = new EventEmitter<SchoolModel[]>();
  @Output() fetchMore = new EventEmitter();

  @Input() sort = new EVOSchoolListSort();
  @Output() sortChanged = new EventEmitter<EVOSchoolListSort>();

  @Input() selectionColumn = EVOListViewColumn.xsFixedColumn('selection', false);
  @Input() nameColumn = EVOListViewColumn.largeGrowColumn('name');
  @Input() cityColumn = EVOListViewColumn.smallFixedColumn('city');
  @Input() countryColumn = EVOListViewColumn.smallFixedColumn('country');
  @Input() createdAtColumn = EVOListViewColumn.smallFixedColumn('createdAt');

  @Input() schoolClickEnabled = false;
  @Output() schoolClicked = new EventEmitter<SchoolModel>();

  @Input('selectionEnabled')
  set selectionEnabled(selection: boolean) {
    this.selectionColumn.isVisible = selection;
  }

  getSelectionEnabled(): boolean {
    return this.selectionColumn.isVisible;
  }

  constructor() { }

  ngOnInit() {
  }

  getContainerHeight() {

    if (this.heightType === 'fixed') {
      return this.height;
    }

    return 'auto';

  }

  getContentHeight() {

    if (this.heightType === 'fixed') {
      return 'calc(100% - 38px)';
    }

    return 'auto';

  }

  getYOverflow(): string {

    if (this.heightType === 'fixed') {
      return 'scroll';
    }

    return 'auto';
  }

  onAllSelected(selection: boolean) {

    if (selection) {
      this.selectedSchools = this.schools;
    } else {
      this.selectedSchools = [];
    }

    this.allSelected = selection;

    if (this.schoolsSelected) {
      this.schoolsSelected.emit(this.selectedSchools);
    }

  }

  onSchoolSelect(school: SchoolModel) {

    const index = this.selectedSchools.indexOf(school);
    if (index !== -1) {
      this.selectedSchools.splice(index, 1);
    } else {
      this.selectedSchools.push(school);
    }

    if (this.schoolsSelected) {
      this.schoolsSelected.emit(this.selectedSchools);
    }

  }

  hasSelectedSchool(school: SchoolModel): boolean {

    return this.selectedSchools.includes(school);

  }

  onFetchMoreData() {
    if (this.fetchMore) {
      this.fetchMore.emit();
    }
  }

  onNameLegendSortClicked() {

    if (this.sort.key !== 'name') {

      this.sort.key = 'name';
      this.sort.value = -1;

    } else {

      if (this.sort.value === 1) {
        this.sort.value = -1;
      } else {
        this.sort.value = 1;
      }

    }

    if (this.sortChanged) {
      this.sortChanged.emit(this.sort);
    }

  }

  onSchoolClicked(school: SchoolModel) {

    if (this.schoolClickEnabled === true && this.schoolClicked) {
      this.schoolClicked.emit(school);
    }

  }

  getSortArrowImgUrl(): string {

    if (this.sort.value === 1) {
      return './assets/icons/sort-up.svg';
    } else {
      return './assets/icons/sort-down.svg';
    }

  }

  calcWidth(size: string): string {

    return EVOListViewColumn.calcWidth(size, this.getColumnDescriptors());

  }

  getColumnDescriptors(): EVOListViewColumn[] {

    return [
      this.selectionColumn,
      this.nameColumn,
      this.cityColumn,
      this.countryColumn,
      this.createdAtColumn
    ];

  }

}

export class EVOSchoolListSort {
  /**
   * The key to use for sorting
   */
  key = 'name';
  /**
   * Should be 1 or -1 for ascending or descending
   */
  value = 1;
}

