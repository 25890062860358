import {SkillGroupModel} from '../edu-admin/skillGroup.model';
import {SkillStatusModel} from './skill.model';
import {StudentModel} from '../authentication/student.model';
import {PlacementTestAttemptSectionQuestionModel, PlacementTestGroupAttempt} from './placement-test-attempt.model';

export class SkillGroupStructureModel {

  private data: Record<string, Record<string, SkillGroupStatusModel>> = {};
  students: StudentModel[] = [];
  skillGroups: SkillGroupModel[] = [];

  /**
   * The categories present in the skill group models.
   */
  public categories: string[] = [];

  /**
   * structured data where we have all skill groups associated with
   * a category
   */
  categorySkillGroups: Record<string, SkillGroupModel[]> = {};

  /**
   *
   */
  categoryMastery: Record<string, number> = {};

  /**
   * Can be of type skill or placement depending on what
   * the skill group status represents. We might add other
   * form of assessments to that.
   */
  type = 'skill';
  mastery = {
    numProblemAreas: 0,
    numProblemPerc: 0,
    numNotStarted: 0,
    notStartedPerc: 0,
    numLearning: 0,
    learningPerc: 0,
    numPracticing: 0,
    practicingPerc: 0,
    numMastering: 0,
    masteringPerc: 0,
    numMastered: 0,
    masteredPerc: 0,
    totalMastery: 0,
    totalProgress: 0
  };

  constructor() {
  }

  static setupWithSkillStatus(students: StudentModel[], skillGroups: SkillGroupModel[], skillStatuses: SkillStatusModel[]): SkillGroupStructureModel {

    const structure = this.setupStructureData(students, skillGroups, 'skill');

    // Populate the data
    for (const skillStatus of skillStatuses) {

      const currentStatus = structure.skillGroupStatusForStudent(skillStatus.studentId, skillStatus.skillGroupId);

      // Current status should always exist
      if (currentStatus) {
        currentStatus.addSkillStatus(skillStatus);
      }

    }

    structure.skillLevelMasterySetup();
    structure.categoryMasterySetup();
    return structure;

  }

  static setupWithPlacementTestAttempt(students: StudentModel[], skillGroups: SkillGroupModel[], attempts: PlacementTestGroupAttempt[]) {

    const structure = this.setupStructureData(students, skillGroups, 'placement');

    // Map all of the placement attempts to a skill status
    for (const attempt of attempts) {
      for (const section of attempt.placementTestAttempt.sections) {
        for (const placementStatus of section.orderedQuestions) {

          // find the skill group id which is not in the ordered questions
          const skillGroupsForSection = skillGroups.filter((s) => s.questionIds.includes(placementStatus.questionId));

          if (skillGroupsForSection.length > 0) {

            // The id of the attempt is the student attempt;
            const studentId = attempt._id;
            const skillGroupId = skillGroupsForSection[0]._id;

            const currentStatus = structure.skillGroupStatusForStudent(studentId, skillGroupId);

            if (currentStatus) {
              currentStatus.addPlacementStatus(placementStatus);
            }

          }

        }
      }

    }

    structure.skillLevelMasterySetup();
    structure.categoryMasterySetup();
    return structure;

  }

  /**
   * Sets up the structure data for each student for each skill group
   */
  private static setupStructureData(students: StudentModel[], skillGroups: SkillGroupModel[], type: string): SkillGroupStructureModel {

    const structure = new SkillGroupStructureModel();
    structure.type = type;
    structure.students = students;
    structure.skillGroups = skillGroups;

    for (const student of students) {

      const studentRecord: Record<string, SkillGroupStatusModel> = {};

      for (const skillGroup of skillGroups) {

        studentRecord[skillGroup._id] = new SkillGroupStatusModel(student._id, skillGroup, structure.type);

        // Add the skill group to the correct category
        if (structure.categories.includes(skillGroup.category) === false) {
          structure.categories.push(skillGroup.category);
          structure.categorySkillGroups[skillGroup.category] = [skillGroup];
        } else {
          structure.categorySkillGroups[skillGroup.category].push(skillGroup);
        }

      }

      structure.data[student._id] = studentRecord;

    }

    return structure;

  }



  skillGroupStatusForStudent(studentId: string, skillGroupId: string): SkillGroupStatusModel {

    const studentRecord = this.data[studentId];
    if (studentRecord) {

      const skillGroupStatus = studentRecord[skillGroupId];
      return skillGroupStatus;

    }

    return null;

  }

  masteryForStudentSkillGroupStatus(studentId: string, skillGroupId: string): number {

    const sGroup = this.skillGroupStatusForStudent(studentId, skillGroupId);

    if (skillGroupId === '664be0102c7a5b002e039675') {
      
    }

    if (sGroup) {
      return sGroup.mastery();
    }

    return 0;
  }

  private skillLevelMasterySetup() {

    let numMastered = 0;
    let numMastering = 0;
    let numPracticing = 0;
    let numLearning = 0;
    let numNotStarted = 0;
    let numProblemAreas = 0;
    let totalMastery = 0;

    for (const studentKey of Object.keys(this.data)) {

      const skillGroupsForStudent = this.data[studentKey];
      for (const skillGroup of this.skillGroups) {

        const mastery = skillGroupsForStudent[skillGroup._id].mastery();
        if (mastery >= 0) {
          totalMastery += mastery;
        }

        if (mastery === -2) {
          numProblemAreas += 1;
        } else if (mastery === -1) {
          numNotStarted += 1;
        } else if (mastery >= 0 && mastery < 0.5) {
          numLearning += 1;
        } else if (mastery >= 0.5 && mastery < 0.8) {
          numPracticing += 1;
        } else if (mastery >= 0.8 && mastery < 1.0) {
          numMastering += 1;
        } else if (mastery === 1) {
          numMastered += 1;
        }

      }

    }

    const totalSkills = this.students.length * this.skillGroups.length;

    this.mastery.totalMastery = totalMastery;
    this.mastery.totalProgress = totalMastery / totalSkills;

    this.mastery.numProblemAreas = numProblemAreas;
    this.mastery.numProblemPerc = numProblemAreas / totalSkills;

    this.mastery.numNotStarted = numNotStarted;
    this.mastery.notStartedPerc = numNotStarted / totalSkills;

    this.mastery.numLearning = numLearning;
    this.mastery.learningPerc = numLearning / totalSkills;

    this.mastery.numPracticing = numPracticing;
    this.mastery.practicingPerc = numPracticing / totalSkills;

    this.mastery.numMastering = numMastering;
    this.mastery.masteringPerc = numMastering / totalSkills;

    this.mastery.numMastered = numMastered;
    this.mastery.masteredPerc = numMastered / totalSkills;

  }

  private categoryMasterySetup() {

    for (const cat of this.categories) {

      let totalMastery = 0;
      const skillGroupsForCat = this.categorySkillGroups[cat];

      for (const studentKey of Object.keys(this.data)) {

        const skillGroupsForStudent = this.data[studentKey];
        for (const skillGroup of skillGroupsForCat) {
          totalMastery += skillGroupsForStudent[skillGroup._id].masteryFraction();
        }

      }

      const totalSkills = this.students.length * skillGroupsForCat.length;

      this.categoryMastery[cat] = totalMastery / totalSkills;

    }

  }

  /**
   * Changes the problem from a decimal to a percentage
   */
  getProblemAreasPercentage(): number {

    return this.mastery.numProblemPerc * 100;
  }

  getProblemAreasPercentageString(fractionalDigits = 2): string {

    return this.getProblemAreasPercentage().toFixed(fractionalDigits);
  }

  getNotStartedPercentage(): number {

    return this.mastery.notStartedPerc * 100;
  }

  getNotStartedPercentageString(fractionalDigits = 2): string {

    return this.getNotStartedPercentage().toFixed(fractionalDigits);
  }

  getLearningPercentage(): number {

    return this.mastery.learningPerc * 100;
  }

  getLearningPercentageString(fractionalDigits = 2): string {

    return this.getLearningPercentage().toFixed(fractionalDigits);
  }

  getPracticingPercentage(): number {

    return this.mastery.practicingPerc * 100;
  }

  getPracticingPercentageString(fractionalDigits = 2): string {

    return this.getPracticingPercentage().toFixed(fractionalDigits);
  }

  getMasteringPercentage(): number {

    return this.mastery.masteringPerc * 100;
  }

  getMasteringPercentageString(fractionalDigits = 2): string {

    return this.getMasteringPercentage().toFixed(fractionalDigits);
  }

  getMasteredPercentage(): number {

    return this.mastery.masteredPerc * 100;
  }

  getMasteredPercentageString(fractionalDigits = 2): string {

    return this.getMasteredPercentage().toFixed(fractionalDigits);

  }

  getTotalPercentage(): number {

    return this.mastery.totalProgress * 100;
  }

  getTotalPercentageString(fractionalDigits = 2): string {

    return this.getTotalPercentage().toFixed(fractionalDigits);
  }

  // Methods for categories
  getCategoryMastery(cat: string): number {
    return this.categoryMastery[cat];
  }

  getCategoryMasteryPercentage(cat: string): number {
    return (this.getCategoryMastery(cat) * 100);
  }

  getCategoryMasteryPercentageString(cat: string, fractionalDigits = 2): string {
    return this.getCategoryMasteryPercentage(cat).toFixed(fractionalDigits);
  }

}

export class SkillGroupStatusModel {

  studentId: string;
  questionId: string;
  skillGroupId: string;
  skillGroup: SkillGroupModel;

  /**
   * Can be 'skill' or 'placement' to indicate the kind of usage.
   */
  type = 'skill';

  skillStatuses: SkillStatusModel[] = [];

  constructor(studentId: string, skillGroup: SkillGroupModel, type = 'skill') {
    this.studentId = studentId;
    this.skillGroupId = skillGroup._id;
    this.skillGroup = skillGroup;
    this.type = type;
  }

  /**
   * Adds all the mastery together for the skills of the skill group.
   * This method will return -1 if there are no skills in the group
   * This method will return -2 if there is a skill that has >= 3
   * num consecutively wrong answers which constitutes a problem area
   */
  mastery(): number {

    let mastery = 0;
    let numSkillsInGroup = this.skillGroup.questionIds.length;

    // Identifies how many wrong questions in a row are required
    // different for placement tests
    let criterionForProblemArea = 3;

    // if we have type placement we should not use num skills in group
    // because everyone goes through the same fixed amount of skills
    // in a placement test which may be related to multiple skills
    if (this.type === 'placement') {

      // all students which take the same placement test have the same length
      numSkillsInGroup = this.skillStatuses.length;
      criterionForProblemArea = 2;

      if (numSkillsInGroup === 0) {
        return -1;
      }

    }

    // If there are no skills in a skill group what should we do
    if (numSkillsInGroup === 0) {
      return 1;
    }

    if (this.skillStatuses.length > 0) {

      for (const skill of this.skillStatuses) {

        mastery += skill.mastery;

        if (skill.numWrongConsecutively >= criterionForProblemArea) {
          return -2;
        }

      }
    } else {
      // If we do not have skill statuses it means we haven't started this
      return -1;
    }

    if (this.skillGroupId === '664be0102c7a5b002e039675') {
      console.log('skill statuses are: ' + JSON.stringify(this.skillStatuses, null, 4));
      console.log('num skills in group ' + numSkillsInGroup);
    }

    mastery = mastery / numSkillsInGroup;

    return mastery;

  }

  /**
   * This returns mastery as a fraction from 0 - 1
   * based on the num skill groups / question ids based on type
   * no special logic for problem areas / not started.
   */
  masteryFraction(): number {

    let mastery = 0;
    let numSkillsInGroup = this.skillGroup.questionIds.length;

    // if we have type placement we should not use num skills in group
    // because everyone goes through the same fixed amount of skills
    // in a placement test which may be related to multiple skills
    if (this.type === 'placement') {

      // all students which take the same placement test have the same length
      numSkillsInGroup = this.skillStatuses.length;

    }

    if (numSkillsInGroup === 0) {
      return 0;
    }

    for (const skill of this.skillStatuses) {
      mastery += skill.mastery;
    }

    return mastery / numSkillsInGroup;

  }

  addSkillStatus(skillStatus: SkillStatusModel) {

    if (skillStatus.studentId === this.studentId && this.skillGroup) {

      for (const qId of this.skillGroup.questionIds) {
        if (qId === skillStatus.questionId) {
          this.skillStatuses.push(skillStatus);
          break;
        }
      }

    }

  }

  addPlacementStatus(placementStatus: PlacementTestAttemptSectionQuestionModel) {

    if (this.skillGroup) {

      const includedInSkillGroup = this.skillGroup.questionIds.filter((qId) => placementStatus.questionId === qId).length > 0;
      if (includedInSkillGroup) {

        const skillStatus = new SkillStatusModel();
        skillStatus.mastery = placementStatus.mastery;

        if (placementStatus.mastery === 1) {
          skillStatus.numCorrectConsecutively = 2;
        } else if (placementStatus.mastery === 0) {
          skillStatus.numWrongConsecutively = 2;
        }

        this.skillStatuses.push(skillStatus);

      }

    }

  }

}
