import {QuestionModel} from '../question.model';
import {RangeModel} from '../shared/range.model';
import {ImageTypeModel} from '../shared/imageType.model';

export class RoundingModel extends QuestionModel {

  model = {
    firstRange: new RangeModel(),
    roundingType: QuestionModel.RoundingTypes['10'],
    includeUnitSeparators: false,
    includeImages: false,
    imageTypeOne: new ImageTypeModel(),
    imageTypeTwo: new ImageTypeModel()
  };

  public static generateModel(json: any): RoundingModel {

    const q = new RoundingModel();

    const model = json.model;

    console.log('json is: ' + JSON.stringify(model, null, 4));
    if (model) {

      if (model.firstRange) {
        q.model.firstRange = RangeModel.generateModel(model.firstRange);
      }

      if (model.imageTypeOne) {
        q.model.imageTypeOne = ImageTypeModel.generate(model.imageTypeOne);
      }

      if (model.imageTypeTwo) {
        q.model.imageTypeTwo = ImageTypeModel.generate(model.imageTypeTwo);
      }

      q.model.roundingType = model.roundingType;
      q.model.includeUnitSeparators = model.includeUnitSeparators;
      q.model.includeImages = model.includeImages;

    }

    return q;

  }

}
