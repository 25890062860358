import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionModel} from '../../../models/questions/question.model';
import {UserModel} from '../../../models/authentication/user.model';
import {EVOListViewColumn} from '../../../models/UI/EVOListViewColumn.model';

@Component({
  selector: 'app-evo-question-list-view',
  templateUrl: './evo-question-list-view.component.html',
  styleUrls: ['./evo-question-list-view.component.css']
})
export class EvoQuestionListViewComponent implements OnInit {

  country = UserModel.getAdminCountry();

  /**
   * Fixed or dynamic
   */
  @Input() heightType = 'fixed';

  /**
   * Used if height type is fixed.
   */
  @Input() height = '500px';

  _questions: QuestionModel[] = [];
  @Input('questions')
  get questions(): QuestionModel[] {
    return this._questions;
  }

  set questions(questions) {

    this._questions = questions;

  }

  allSelected = false;
  @Input() selectedQuestions: QuestionModel[] = [];

  @Input() hasMoreData = false;
  @Output() questionsSelected = new EventEmitter<QuestionModel[]>();
  @Output() fetchMore = new EventEmitter();

  @Input() sort = new EVOQuestionListSort();
  @Output() sortChanged = new EventEmitter<EVOQuestionListSort>();

  @Input() selectionColumn = EVOListViewColumn.xsFixedColumn('selection', false);
  @Input() nameColumn = EVOListViewColumn.customColumn('name', true, 'grow', '300px', '300px', '400px', '400px', null);
  @Input() categoryColumn = EVOListViewColumn.smallFixedColumn('category');
  @Input() subCategoryColumn = EVOListViewColumn.smallFixedColumn('subCategory');
  @Input() representationColumn = EVOListViewColumn.smallFixedColumn('representation');
  @Input() answerTypeColumn = EVOListViewColumn.smallFixedColumn('answerType');
  @Input() defaultRankColumn = EVOListViewColumn.smallFixedColumn('defaultRank');
  @Input() versionColumn = EVOListViewColumn.smallFixedColumn('version');
  @Input() createdAtColumn = EVOListViewColumn.smallFixedColumn('createdAt');

  @Input() questionClickEnabled = false;
  @Output() questionClicked = new EventEmitter<QuestionModel>();

  @Input('selectionEnabled')
  set selectionEnabled(selection: boolean) {
    this.selectionColumn.isVisible = selection;
  }

  getSelectionEnabled(): boolean {
    return this.selectionColumn.isVisible;
  }


  constructor() { }

  ngOnInit() {
    this.subCategoryColumn.isVisible = false;
  }

  getContainerHeight() {

    if (this.heightType === 'fixed') {
      return this.height;
    }

    return 'auto';

  }

  getContentHeight() {

    if (this.heightType === 'fixed') {
      return 'calc(100% - 38px)';
    }

    return 'auto';

  }

  getYOverflow(): string {

    if (this.heightType === 'fixed') {
      return 'scroll';
    }

    return 'auto';
  }

  getSortArrowImgUrl(value: number): string {

    if (value === 1) {
      return './assets/icons/sort-up.svg';
    } else {
      return './assets/icons/sort-down.svg';
    }

  }

  calcWidth(size: string): string {

    return EVOListViewColumn.calcWidth(size, this.getColumnDescriptors());

  }

  getColumnDescriptors(): EVOListViewColumn[] {

    return [this.selectionColumn,
      this.nameColumn,
      this.categoryColumn,
      this.subCategoryColumn,
      this.representationColumn,
      this.answerTypeColumn,
      this.defaultRankColumn,
      this.versionColumn,
      this.createdAtColumn];

  }

  onQuestionSelect(q: QuestionModel) {

    const index = this.selectedQuestions.indexOf(q);
    if (index !== -1) {
      this.selectedQuestions.splice(index, 1);
    } else {
      this.selectedQuestions.push(q);
    }

    if (this.questionsSelected) {
      this.questionsSelected.emit(this.selectedQuestions);
    }

  }

  hasSelectedQuestion(q: QuestionModel): boolean {

    return this.selectedQuestions.includes(q);

  }

  onAllSelected(selection: boolean) {

    if (selection) {
      this.selectedQuestions = this.questions;
    } else {
      this.selectedQuestions = [];
    }

    this.allSelected = selection;

    if (this.questionsSelected) {
      this.questionsSelected.emit(this.selectedQuestions);
    }

  }

  onQuestionClicked(q: QuestionModel) {
    if (this.questionClicked) {
      this.questionClicked.emit(q);
    }
  }

  onFetchMoreClicked() {
    if (this.fetchMore) {
      this.fetchMore.emit();
    }
  }

}

export class EVOQuestionListSort {

  sortByName = -1;
  sortByCategory = -1;
  sortBySubCategory = -1;

}
