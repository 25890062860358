import {Component, Input, OnInit} from '@angular/core';
import {SizeComparisonSymbolsModel} from '../../../../../models/questions/subModels/sizeComparisonSymbols.model';
import {QuestionModel} from '../../../../../models/questions/question.model';

@Component({
  selector: 'app-evo-size-comparison-symbols',
  templateUrl: './evo-size-comparison-symbols.component.html',
  styleUrls: ['./evo-size-comparison-symbols.component.css']
})
export class EvoSizeComparisonSymbolsComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new SizeComparisonSymbolsModel();

  constructor() { }

  ngOnInit() {
  }

  castedQuestion(): SizeComparisonSymbolsModel {

    return <SizeComparisonSymbolsModel>this.question;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.sizeComparisonSymbols) {
        return true;
      }
    }

    return false;
  }

}
