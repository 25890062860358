import {SkillGroupModel} from './skillGroup.model';
import {QuestionModel} from '../questions/question.model';
import {generateQuestionList} from '../questions/question-generator';
import {PlacementTestModel} from './placement-test.model';
import {DescriptionModel} from '../shared/description.model';
import {StringExtensionModel} from '../extensions/string-extension.model';
import {UserModel} from '../authentication/user.model';
import {BookModel} from './book.model';
import {BookVersionModel} from './book-version.model';

export class ObjectiveModel {

  public _id: string;

  public name: string;
  public country = 'none';
  public grade: number;
  public isArchived: boolean;
  public translatedName = new DescriptionModel();

  public skillGroupIds: string[] = [];
  public skillGroups: SkillGroupModel[] = [];

  public completedSkillGroupIds: string[] = [];
  public completedSkillGroups: SkillGroupModel[] = [];

  public skillIds: string[] = [];
  public skills: QuestionModel[] = [];

  public completedSkillIds: string[] = [];
  public completedSkills: QuestionModel[] = [];

  public placementTestId: string;
  public placementTest: PlacementTestModel;

  public bookId: string;
  public book: BookModel;

  public bookVersionId: string;
  public bookVersion: BookVersionModel;

  public createdAt: Date;
  public updatedAt: Date;

  static generateModel(json: any): ObjectiveModel {

    const objective = new ObjectiveModel();
    objective._id = json._id;
    objective.name = json.name;
    objective.country = json.country;
    objective.grade = json.grade;
    objective.isArchived = json.isArchived;

    if (json.translatedName) {
      objective.translatedName = DescriptionModel.generateModel(json.translatedName);
    }

    // The skill groups are all sent together
    if (json.skillGroupIds && json.skillGroupIds.length > 0) {

      const skillGroupJson = json.skillGroupIds[0];
      const type = typeof skillGroupJson;

      if (type === 'object') {

        const skillGroups = SkillGroupModel.generateModels(json.skillGroupIds);
        objective.skillGroups = skillGroups;

        for (const skillGroup of skillGroups) {
          objective.skillGroupIds.push(skillGroup._id);
        }

      } else if (type === 'string') {

        objective.skillGroupIds = json.skillGroupIds;

      }

    }

    if (json.completedSkillGroupIds && json.completedSkillGroupIds.length > 0) {

      const skillGroupJson = json.completedSkillGroupIds[0];
      const type = typeof skillGroupJson;

      if (type === 'object') {

        const completedSkillGroups = SkillGroupModel.generateModels(json.completedSkillGroupIds);
        objective.completedSkillGroups = completedSkillGroups;

        for (const completedSkillGroup of completedSkillGroups) {
          objective.completedSkillIds.push(completedSkillGroup._id);
        }

      } else if (type === 'string') {

        objective.completedSkillGroupIds = json.completedSkillGroupIds;

      }

    }

    if (json.skillIds && json.skillIds.length > 0) {

      const skill = json.skillIds[0];
      const type = typeof skill;

      if (type === 'object') {

        const questionSkills = generateQuestionList(json.skillIds);
        objective.skills = questionSkills;

        for (const questionSkill of questionSkills) {
          objective.skillIds.push(questionSkill._id);
        }

      } else if (type === 'string') {

        objective.skillIds = json.skillIds;

      }

    }

    if (json.completedSkillIds && json.completedSkillIds.length > 0) {

      const skill = json.completedSkillIds[0];
      const type = typeof skill;

      if (type === 'object') {

        const questionSkills = generateQuestionList(json.completedSkillIds);
        objective.completedSkills = questionSkills;

        for (const questionSkill of questionSkills) {
          objective.completedSkillIds.push(questionSkill._id);
        }

      } else if (type === 'string') {

        objective.completedSkillIds = json.completedSkillIds;

      }

    }

    if (json.placementTestId) {

      const type = typeof json.placementTestId;
      if (type === 'object') {

        objective.placementTest = PlacementTestModel.generateModel(json.placementTestId);
        objective.placementTestId = objective.placementTest._id;

      } else if (type === 'string') {

        objective.placementTestId = json.placementTestId;

      }

    }

    if (json.bookId) {

      const type = typeof json.bookId;
      if (type === 'object') {
        objective.book = BookModel.generateModel(json.bookId);
        objective.bookId = objective.book._id;
      } else if (type === 'string') {
        objective.bookId = json.bookId;
      }

    }

    if (json.bookVersionId) {

      const type = typeof json.bookVersionId;
      if (type === 'object') {
        objective.bookVersion = BookVersionModel.generateModel(json.bookVersionId);
        objective.bookVersionId = objective.bookVersion._id;
      } else if (type === 'string') {
        objective.bookVersionId = json.bookVersionId;
      }

    }

    if (json.createdAt) {
      objective.createdAt = new Date(json.createdAt);
    }

    if (json.updatedAt) {
      objective.updatedAt = new Date(json.updatedAt);
    }

    return objective;

  }

  static generateModels(jsonList: any[]): ObjectiveModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push(this.generateModel(json));
    }

    return list;

  }

  getTwoInitials(): string {

    return StringExtensionModel.getInitials(this.name, 2);
  }

  getTranslatedNameForUser() {

    const user = UserModel.getCurrent();
    if (user && user.culture) {
      return this.getTranslatedName(user.culture);
    }

    return null;

  }

  getTranslatedName(culture: string): string {

    if (culture) {
      return this.translatedName[culture];
    }

    return null;
  }

}

export class ObjectiveFilter {

  skip = 0;
  limit = 20;

  name: string = undefined;
  country: string = undefined;
  grade: string = undefined;

  isArchived: boolean = undefined;

  sort: number = undefined;

  populateSkillGroups: boolean = undefined;
  populateSkills: boolean = undefined;
  populatePlacementTest: boolean = undefined;
  populateBooks: boolean = undefined;

}
