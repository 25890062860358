import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-user-students',
  templateUrl: './admin-user-students.component.html',
  styleUrls: ['./admin-user-students.component.css']
})
export class AdminUserStudentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
