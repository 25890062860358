import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {StudentModel} from '../../../../models/authentication/student.model';
import {TranslateService} from '@ngx-translate/core';
import {SkillGroupModel} from '../../../../models/edu-admin/skillGroup.model';
import {SkillGroupStructureModel} from '../../../../models/student/skillGroupStatusModel';
import {EVOSegmentedControlItem} from '../../../../shared/reusable-controls/evo-segmented-control/evo-segmented-control.component';
import {UserModel} from '../../../../models/authentication/user.model';

@Component({
  selector: 'app-group-dash-skills-overview',
  templateUrl: './group-dash-skills-overview.component.html',
  styleUrls: ['./group-dash-skills-overview.component.css']
})
export class GroupDashSkillsOverviewComponent implements OnInit {

  controls = [
    new EVOSegmentedControlItem('placementTest', 'Placement Tests'),
    new EVOSegmentedControlItem('overview', 'Overview')
  ];
  currentControl = this.controls[1];

  @Input() students: StudentModel[] = [];
  @Input() skillGroupsObjective: SkillGroupModel[];
  @Input() skillStatus: SkillGroupStructureModel;
  @Input() skillGroupsPlacement: SkillGroupModel[];
  @Input() placementStatus: SkillGroupStructureModel;

  @Input() skillsYAxis = false;

  @Input() scrollContainer: HTMLDivElement;

  /**
   * For header calculation
   */
  topOffset = '0px';
  @ViewChild('skillHeader', { static: true }) skillHeader: ElementRef;
  @ViewChild('skillContainer', { static: true }) skillContainer: ElementRef;

  constructor(public tSv: TranslateService) { }

  ngOnInit() {

    if (UserModel.getCurrent().isAdmin) {
      this.controls = [
        new EVOSegmentedControlItem('placementTest', 'Placement Tests'),
        new EVOSegmentedControlItem('overview', 'Overview')
      ];
      this.currentControl = this.controls[1];
    } else {
      this.controls = [
        new EVOSegmentedControlItem('overview', 'Overview')
      ];
      this.currentControl = this.controls[0];
    }

    this.controls = [
      new EVOSegmentedControlItem('placementTest', 'Placement Tests'),
      new EVOSegmentedControlItem('overview', 'Overview')
    ];
    this.currentControl = this.controls[1];

  }

  getContainerWidth(): string {

    return (this.students.length * 62).toString() + 'px';

  }

  onScroll() {

    if (this.scrollContainer != null) {

      // start location of the scroll container
      const startY = this.scrollContainer.offsetTop;

      // current y location with the scroll
      const currentY = startY + this.scrollContainer.scrollTop;

      // start position to move header
      const yDiff = currentY - this.skillContainer.nativeElement.offsetTop;

      // Need to subtract scroll bar height if any
      const stopHeight = this.skillContainer.nativeElement.offsetHeight - this.skillHeader.nativeElement.offsetHeight;

      if (yDiff >= 0 && yDiff < stopHeight) {

        this.topOffset = (yDiff + 2).toString() + 'px';

      } else if (yDiff < 0) {

        this.topOffset = '0px';

      }

    }

  }

  getSkillGroups(): SkillGroupModel[] {

    if (this.skillGroupsObjective && this.currentControl.key === 'overview') {
      return this.skillGroupsObjective;
    } else if (this.skillGroupsPlacement && this.currentControl.key === 'placementTest') {
      return this.skillGroupsPlacement;
    }

    return [];
  }

  getColorsForStudentSkillGroupMastery(studentId: string, skillGroupId: string): { 'background-color': string, 'border-width': string, 'border-style': string, 'border-color': string } {

    if ((this.skillStatus && this.currentControl.key === 'overview') || (this.placementStatus && this.currentControl.key === 'placementTest')) {

      let value = null;
      if (this.currentControl.key === 'overview') {
        value = this.skillStatus.masteryForStudentSkillGroupStatus(studentId, skillGroupId);
      } else if (this.currentControl.key === 'placementTest') {
        value = this.placementStatus.masteryForStudentSkillGroupStatus(studentId, skillGroupId);
      }


      if (value === -2) {
        // Indicates the student is struggling with a question/skill in the skill group
        return {
          'background-color': 'rgba(215, 33, 33, 0.3)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'rgba(215, 33, 33, 1.0)'
        };

      } else if (value >= 0 && value < 0.5) {

        return {
          'background-color': 'rgba(255, 203, 5, 0.3)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'rgba(255, 203, 5, 1.0)'
        };

      } else if (value >= 0.5 && value < 0.8) {


        return {
          'background-color': 'rgba(155, 89, 182, 0.3)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'rgba(155, 89, 182, 1.0)'
        };


      } else if (value >= 0.8 && value < 1.0) {

        return {
          'background-color': 'rgba(121, 197, 112, 0.3)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'rgba(121, 197, 112, 1.0)'
        };

      } else if (value >= 1.0) {

        return {
          'background-color': 'rgba(0, 156, 204, 0.3)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'rgba(0, 156, 204, 1.0)'
        };

      }

    }

    // Default gray color
    return {
      'background-color': 'rgba(216, 216, 216, 0.3)',
      'border-width': '1px',
      'border-style': 'solid',
      'border-color': 'rgba(216, 216, 216, 1)'
    };

  }

}
