import {QuestionModel} from '../question.model';

export class AreaFromShapeModel extends QuestionModel {

  model = {
    shapes: []
  };

  static generateModel(json: any): AreaFromShapeModel {

    const q = new AreaFromShapeModel();

    const model = json.model;
    if (model) {

      q.model.shapes = model.shapes;

    }

    return q;

  }

}
