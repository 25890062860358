import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AdminSchoolService} from '../../../../../services/admin/admin-school.service';
import {CsvImportService} from '../../../../../services/reusable/csv-import.service';
import {SchoolModel} from '../../../../../models/school/school.model';
import {StudentModel} from '../../../../../models/authentication/student.model';
import {AdminSchoolStudentService} from '../../../../../services/admin/admin-school-student.service';
import {ModalService} from '../../../../../shared/modals/modal/modal.service';
import {ErrorModel} from '../../../../../models/shared/error.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-evo-school-school-upload-students',
  templateUrl: './evo-admin-school-upload-students.component.html',
  styleUrls: ['./evo-admin-school-upload-students.component.css']
})
export class EvoAdminSchoolUploadStudentsComponent implements OnInit {

  school: SchoolModel;
  students: StudentModel[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private schoolSv: AdminSchoolService,
              private studentSv: AdminSchoolStudentService,
              private modalSv: ModalService,
              private csvSv: CsvImportService,
              private tSv: TranslateService) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {

      if (params.schoolId) {
        this.schoolSv.fetchSchoolById(params.schoolId).subscribe((school) => {
          this.school = school;
        });

      }

    });
  }

  onFileChanged(event) {

    StudentModel.importStudentsViaCSV(event, this.csvSv, this.school.country).subscribe((students) => {
      this.students = students;
    }, (err: ErrorModel) => {
      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'), err.message);
    });

  }

  onUploadClicked() {

    this.studentSv.createSchoolStudentsAndRelationships(this.school._id, this.students).subscribe((students) => {
      this.modalSv.showAlertModal('Success', 'Students succesfully created').subscribe((() => {
        this.router.navigate(['admin', 'schools', this.school._id, 'edit']);
      }));
    }, (err: ErrorModel) => {
      this.modalSv.showAlertModal('Error', err.message);
    });

  }

  onDownloadTemplateClicked() {

    const a = document.createElement('a');
    a.href = 'assets/templates/student-Import-Template.csv';
    a.download = 'student-Import-Template.csv';
    // start download
    a.click();
    a.remove();

  }

}
