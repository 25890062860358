import { Component, OnInit } from '@angular/core';
import {AdminPlacementTestSelectionListService} from '../admin-placement-test-selection-list/admin-placement-test-selection-list.service';
import {AdminSchoolSelectionService} from './admin-school-selection.service';

@Component({
  selector: 'app-admin-school-selection',
  templateUrl: './admin-school-selection.component.html',
  styleUrls: ['./admin-school-selection.component.css']
})
export class AdminSchoolSelectionComponent implements OnInit {

  constructor(public selectionSv: AdminSchoolSelectionService) { }

  ngOnInit() {
  }

}
