import {Component, OnInit} from '@angular/core';
import {UserFilter, UserModel} from '../../models/authentication/user.model';
import {Router} from '@angular/router';
import {AdminUserService} from '../../services/admin/admin-user.service';
import {CountryModel} from '../../models/localization/country.model';
import {EVOUserListSort} from '../../shared/list-views/evo-users-list-view/evo-users-list-view.component';
import {forkJoin} from 'rxjs';
import {ModalService} from '../../shared/modals/modal/modal.service';

@Component({
  selector: 'app-school-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {

  users: UserModel[] = [];
  usersToDelete: UserModel[] = [];

  password = '';
  secret = '';

  filter = new UserFilter();
  hasMoreData = true;
  countryDropdownList = CountryModel.privateDropdownList(true);

  showConfirmationPrompt = false;

  constructor(private router: Router,
              private modalSv: ModalService,
              private adminUserSrv: AdminUserService) {
  }

  ngOnInit() {
    this.countryDropdownList.splice(0, 0, {key: null, value: 'World', iconURL: './assets/school-admin/world.png'});
    this.filter.limit = 20;
    this.fetchUsers();
  }

  fetchUsers() {

    this.filter.skip = this.users.length;
    this.adminUserSrv.fetchUsers(this.filter).subscribe((users) => {

      this.hasMoreData = users.length % this.filter.limit === 0;
      for (const user of users) {
        if (this.users.filter((u) => u._id === user._id).length === 0) {
          this.users.push(user);
        }
      }

    });

  }

  searchFilteredUsers(searchString: string, country: string) {

    this.filter.name = searchString;
    this.filter.email = searchString;
    this.filter.country = country;
    this.users = [];
    this.fetchUsers();

  }

  onSortClicked(sort: EVOUserListSort) {

    this.filter.sort = sort.value;
    this.users = [];
    this.fetchUsers();

  }

  height(): string {

    if (this.usersToDelete.length > 0) {
      return 'calc(100% - 236px);';
    }

    return 'calc(100% - 176px);';
  }

  heightXS(): string {

    if (this.usersToDelete.length > 0) {
      return 'calc(100% - 302px';
    }

    return 'calc(100% - 242px);';
  }

  onDeleteMultipleUsers() {

    const list = [];
    for (const user of this.usersToDelete) {
      const deleteOp = this.adminUserSrv.deleteUser(user._id, this.password, this.secret);
      list.push(deleteOp);
    }

    forkJoin(list).subscribe(() => {
      this.showConfirmationPrompt = false;
      this.password = '';
      this.secret = '';
      this.modalSv.showAlertModal('Success', 'Users were successfully deleted');
      this.usersToDelete = [];
      this.users = [];
      this.filter.skip = 0;
      this.fetchUsers();
    });

  }

  onHidePrompt() {
    this.showConfirmationPrompt = false;
    this.password = '';
    this.secret = '';
  }

  onUserClicked(user: UserModel) {
    this.router.navigate(['admin', 'users', user._id, 'detail', 'settings']);
  }

}
