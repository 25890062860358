import { Component, OnInit } from '@angular/core';
import {AdminPlacementTestQuestionModalService} from './admin-placement-test-question-modal.service';
import {EVOSegmentedControlItem} from '../../../../../../shared/reusable-controls/evo-segmented-control/evo-segmented-control.component';
import {PlacementTestSectionModel, PlacementTestSectionQuestionModel} from '../../../../../../models/edu-admin/placement-test.model';
import {StringExtensionModel} from '../../../../../../models/extensions/string-extension.model';

@Component({
  selector: 'app-admin-placement-test-question-modal',
  templateUrl: './admin-placement-test-question-modal.component.html',
  styleUrls: ['./admin-placement-test-question-modal.component.css']
})
export class AdminPlacementTestQuestionModalComponent implements OnInit {

  controlItems = [
    new EVOSegmentedControlItem('info', 'Information'),
    new EVOSegmentedControlItem('settings', 'Settings')
  ];

  currentItem = this.controlItems[0];

  constructor(public modalSv: AdminPlacementTestQuestionModalService) { }

  ngOnInit() {
  }

}
