import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GroupModel} from '../../models/school/group.model';
import {AuthService} from '../auth.service';
import {UserModel} from '../../models/authentication/user.model';
import {map} from 'rxjs/operators';
import {StringExtensionModel} from '../../models/extensions/string-extension.model';
import {StudentModel} from '../../models/authentication/student.model';
import {StudentFilterModel} from '../../models/filters/student-filter.model';
import {SchoolModel} from '../../models/school/school.model';
import {SessionActivityModel} from '../../models/student/session.model';
import {AnswerActivityModel} from '../../models/student/answer.model';
import {SkillGroupModel} from '../../models/edu-admin/skillGroup.model';
import {SkillGroupProblemModel, SkillStatusModel} from '../../models/student/skill.model';
import {PlacementTestGroupAttempt} from '../../models/student/placement-test-attempt.model';
import {PlacementTestModel} from '../../models/edu-admin/placement-test.model';

@Injectable({
  providedIn: 'root'
})
export class SchoolGroupsService {

  constructor(private authSv: AuthService) { }

  fetchGroup(groupId: string): Observable<GroupModel> {

    let url = `/schools/${SchoolModel.getCurrent()._id}`;
    url += `/teacherGroups/${groupId}/group`;

    return this.authSv.get(url).pipe(map((response) => {

      const group = GroupModel.generateModel(response.data);
      return group;

    }));

  }

  fetchTeacherGroups(filter: SchoolGroupFilter): Observable<GroupModel[]> {

    let url = '/schools/' + SchoolModel.getCurrent()._id;
    url += '/teacherGroups';

    url += StringExtensionModel.queryString(filter);

    return this.authSv.get(url).pipe(map((response) => {

        const groups = GroupModel.generateModels(response.data);
        return groups;

    }));

  }

  fetchStudentForGroup(groupId: string, filter: StudentFilterModel = null): Observable<StudentModel[]> {

    let url = '/schools/' + SchoolModel.getCurrent()._id;
    url += '/teacherGroups/' + groupId;
    url += '/students' + StringExtensionModel.queryString(filter);

    return this.authSv.get(url).pipe(map((response) => {
      const students = StudentModel.generateModels(response.data);
      return students;

    }));

  }

  fetchTeachersForGroup(groupId: string): Observable<UserModel[]> {

    let url = '/schools/' + SchoolModel.getCurrent()._id;
    url += '/teacherGroups/' + groupId;
    url += '/students';

    return this.authSv.get(url).pipe(map((response) => {
      const users = UserModel.generateList(response.data);
      return users;
    }));

  }

  fetchGroupedSessionActivity(groupId: string, interval: string = null, fromDate: Date = null, toDate: Date = null, sort: number = -1): Observable<SessionActivityModel[]> {

    let url = '/schools/' + SchoolModel.getCurrent()._id;
    url += '/groups/' + groupId;
    url += '/sessionActivity';

    const queryObject = {};
    if (fromDate) {
      queryObject['fromDate'] = fromDate;
    }

    if (toDate) {
      queryObject['toDate'] = toDate;
    }

    queryObject['interval'] = interval;
    queryObject['sort'] = sort;

    url += StringExtensionModel.queryString(queryObject);

    return this.authSv.get(url).pipe(map((response) => {

      const sessions = SessionActivityModel.generateModels(response.data);
      return sessions;

    }));

  }

  fetchGroupStudentSessions(gId: string, fromDate: Date = null, toDate: Date = null, sort: number = -1): Observable<any> {


    const query = {};
    if (fromDate) {
      query['fromDate'] = fromDate;
    }

    if (toDate) {
      query['toDate'] = toDate;
    }

    if (sort === 1) {
      query['sort'] = '1';
    } else {
      query['sort'] = '-1';
    }

    const qString = StringExtensionModel.queryString(query);

    const sId = SchoolModel.getCurrent()._id;

    const url = `/schools/${sId}/groups/${gId}/studentSessionActivity${qString}`;

    return this.authSv.get(url).pipe(map((response) => {

      const sessions = SessionActivityModel.generateModels(response.data);
      return sessions;

    }));

  }

  fetchGroupAnswerActivity(groupId: string, interval: string, fromDate: Date, toDate: Date, sort: number = -1): Observable<AnswerActivityModel[]> {

    let url = '/schools/' + SchoolModel.getCurrent()._id;
    url += '/groups/' + groupId;
    url += '/answerActivity';

    const queryObject = {};
    if (fromDate) {
      queryObject['fromDate'] = fromDate;
    }

    if (toDate) {
      queryObject['toDate'] = toDate;
    }

    queryObject['interval'] = interval;
    queryObject['sort'] = sort;

    url += StringExtensionModel.queryString(queryObject);

    return this.authSv.get(url).pipe(map((response) => {

      const answers = AnswerActivityModel.generateModels(response.data);
      return answers;

    }));

  }

  fetchGroupNumAnswers(groupId: string, fromDate: Date = null, toDate: Date = null): Observable<number> {

    let url = '/schools/' + SchoolModel.getCurrent()._id;
    url += '/groups/' + groupId;
    url += '/numAnswers';

    const queryObject = {};
    if (fromDate) {
      queryObject['fromDate'] = fromDate;
    }

    if (toDate) {
      queryObject['toDate'] = toDate;
    }

    url += StringExtensionModel.queryString(queryObject);

    return this.authSv.get(url).pipe(map((response) => {

      const data = response.data;
      return data as number;

    }));

  }

  fetchGroupSkills(groupId: string): Observable<{ skills: SkillStatusModel[], skillGroups: SkillGroupModel[] }> {

    let url = `/schools/${SchoolModel.getCurrent()._id}`;
    url += `/groups/${groupId}/skills`;

    return this.authSv.get(url).pipe(map((response) => {

      const data = response.data;

      const skillStatuses = SkillStatusModel.generateModels(data.skills);
      const skillGroups = SkillGroupModel.generateModels(data.skillGroups);

      return {
        skills: skillStatuses,
        skillGroups: skillGroups
      };

    }));

  }

  fetchPlacementTestForGroup(gId: string): Observable<{
    placementTest: PlacementTestModel,
    attempts: PlacementTestGroupAttempt[]
  }> {

    const sId = SchoolModel.getCurrent()._id;
    const url = `/schools/${sId}/groups/${gId}/placementTestAttempts`;

    return this.authSv.get(url).pipe(map((response) => {

      const placementTest = PlacementTestModel.generateModel(response.data.placementTest);
      const attempts = PlacementTestGroupAttempt.generateList(response.data.attempts);

      return {
        placementTest: placementTest,
        attempts: attempts
      };

    }));

  }

  fetchProblemSkillGroups(gId: string): Observable<SkillGroupModel[]> {

    const sId = SchoolModel.getCurrent()._id;
    const url = `/schools/${sId}/teacherGroups/${gId}/problemSkillGroups`;

    return this.authSv.get(url).pipe(map((response) => {

      const skillGroups = SkillGroupModel.generateModels(response.data);
      return skillGroups;

    }));

  }

  fetchStudentsForProblemSkillGroup(groupId: string, skillGroupId: string): Observable<SkillGroupProblemModel[]> {

    const sId = SchoolModel.getCurrent()._id;

    const url = `/schools/${sId}/teacherGroups/${groupId}/problemSkillGroups/${skillGroupId}/students`;

    return this.authSv.get(url).pipe(map((response) => {

      const studentSkillProblems = SkillGroupProblemModel.generateList(response.data);
      return studentSkillProblems;

    }));

  }

}

export class SchoolGroupFilter {

  skip = 0;
  limit = 50;

  name: string;

  fromAge: Date;
  toAge: Date;

  /*
    By default, things are sorted in ascending order alphabetically.
    You can change this to 1 to do so in a descending order.
   */
  sort = 1;


}
