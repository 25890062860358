import {Component, OnInit} from '@angular/core';
import {AdminSchoolService} from '../../services/admin/admin-school.service';
import {SchoolFilter, SchoolModel} from '../../models/school/school.model';
import {Router} from '@angular/router';
import {CountryModel} from '../../models/localization/country.model';
import {EVOSchoolListSort} from '../../shared/list-views/evo-schools-list-view/evo-schools-list-view.component';
import {PricingModel} from '../../models/localization/pricing.model';
import {EvoLangDropdownComponent} from '../../shared/reusable-controls/evo-lang-dropdown/evo-lang-dropdown.component';
import {ErrorModel} from '../../models/shared/error.model';
import {ModalService} from '../../shared/modals/modal/modal.service';

@Component({
  selector: 'app-evo-school-schools',
  templateUrl: './evo-admin-schools.component.html',
  styleUrls: ['./evo-admin-schools.component.css']
})
export class EvoAdminSchoolsComponent implements OnInit {

  schools: SchoolModel[] = [];
  filter = new SchoolFilter();
  hasMoreData = true;
  showSchool = false;

  countryDropdownList = CountryModel.privateDropdownList(true);

  school = new SchoolModel();
  CultureTypes = EvoLangDropdownComponent.Types;
  PricingModel = PricingModel;

  constructor(private schoolSv: AdminSchoolService,
              private modalSv: ModalService,
              private router: Router) {
  }

  ngOnInit() {

    this.filter.limit = 20;
    this.schoolSv.fetchSchools(this.filter).subscribe((data) => {
      this.schools = data;
    });

  }

  fetchSchools() {

    this.filter.skip = this.schools.length;
    this.schoolSv.fetchSchools(this.filter).subscribe((schools) => {

      this.hasMoreData = schools.length === this.filter.limit;

      for (const school of schools) {
        if (this.schools.filter((s) => s._id === school._id).length === 0) {
          this.schools.push(school);
        }
      }

    });

  }

  searchFilteredSchools(searchString: string, country: string) {

    this.filter.search = searchString;
    this.filter.country = country;
    this.schools = [];

    this.fetchSchools();

  }

  onSchoolClicked(school: SchoolModel) {
    this.router.navigate(['admin', 'schools', school._id, 'detail', 'students']);
  }


  onSortClicked(sort: EVOSchoolListSort) {

    this.filter.sort = sort.value;
    this.schools = [];
    this.fetchSchools();

  }

  onShowSchoolCreation() {
    this.showSchool = true;
  }

  onCancelClicked() {
    this.showSchool = false;
  }

  onCreateClicked() {

    if (!this.school.currency) {

      this.modalSv.showAlertModal('Error', 'Currency is required');
      return ;
    }

    this.schoolSv.updateSchool(this.school).subscribe((school) => {
      this.showSchool = false;
      this.schools = [];
      this.fetchSchools();
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }


}
