import {Component, Input, OnInit} from '@angular/core';
import {StudentModel} from '../../../../models/authentication/student.model';
import {SkillGroupProblemModel} from '../../../../models/student/skill.model';
import {SkillGroupModel} from '../../../../models/edu-admin/skillGroup.model';
import {SchoolGroupsService} from '../../../../services/school/school-groups.service';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-group-dash-problem-areas',
  templateUrl: './group-dash-problem-areas.component.html',
  styleUrls: ['./group-dash-problem-areas.component.css']
})
export class GroupDashProblemAreasComponent implements OnInit {

  groupId: string;

  currentProblemArea: SkillGroupModel = null;
  problemAreas: SkillGroupModel[] = [];
  isFetchingData = false;

  studentProblemAreas: SkillGroupProblemModel[] = [];

  selectProblemAreas = false;

  constructor(private route: ActivatedRoute,
              private groupSv: SchoolGroupsService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.groupId = params.groupId;

      this.groupSv.fetchProblemSkillGroups(this.groupId).subscribe((skillGroups) => {

        this.isFetchingData = false;
        this.problemAreas = skillGroups;
        if (this.problemAreas.length > 0) {
          this.currentProblemArea = this.problemAreas[0];
          this.fetchStudentProblemAreas();
        }

      });

    });

  }

  fetchStudentProblemAreas() {

    this.groupSv.fetchStudentsForProblemSkillGroup(this.groupId, this.currentProblemArea._id).subscribe((studentProblemAreas) => {

      this.studentProblemAreas = studentProblemAreas;

    });

  }

  onSelectProblemAreaClicked() {
    this.selectProblemAreas = true;
  }

  onProblemAreaClicked(problemArea: SkillGroupModel) {

    this.selectProblemAreas = false;

    this.currentProblemArea = problemArea;
    this.studentProblemAreas = [];

    this.fetchStudentProblemAreas();

  }

}
