import { Injectable } from '@angular/core';
import {Observable, Subscriber} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EvoPassModalService {

  isVisible = false;

  observer: Subscriber<string>;

  constructor() { }

  confirmPassModal(): Observable<string> {

    this.isVisible = true;

    const observable = new Observable((observer: Subscriber<string>) => {
      this.observer = observer;
    });

    return observable;

  }

  onCancel() {

    this.isVisible = false;
    this.observer.next(null);
    this.observer.complete();

  }

  onConfirmation(pass: string) {

    this.isVisible = false;
    this.observer.next(pass);
    this.observer.complete();

  }

}
