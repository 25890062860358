import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AdminObjectiveService} from '../../../services/admin/admin-objective.service';
import {ObjectiveModel} from '../../../models/edu-admin/objective.model';
import {AdminSkillGroupService} from '../../../services/admin/admin-skill-group.service';
import {ModalService} from '../../../shared/modals/modal/modal.service';
import {EVOSegmentedControlItem} from '../../../shared/reusable-controls/evo-segmented-control/evo-segmented-control.component';

@Component({
  selector: 'app-admin-objective-edit',
  templateUrl: './admin-objective-edit.component.html',
  styleUrls: ['./admin-objective-edit.component.css']
})
export class AdminObjectiveEditComponent implements OnInit {

  objective: ObjectiveModel = new ObjectiveModel();

  controls: EVOSegmentedControlItem[] = [
    new EVOSegmentedControlItem('skillGroups', 'admin.objectiveSkillGroupTitle'),
    new EVOSegmentedControlItem('skills', 'admin.objectiveSkillsTitle'),
    new EVOSegmentedControlItem('settings', 'sidebar.settings')
  ];

  currentItem = this.controls[0];

  constructor(
    private objectiveSv: AdminObjectiveService,
    private skillGroupSrv: AdminSkillGroupService,
    private modalSv: ModalService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    const urlParams = this.router.url.split('/');
    const page = urlParams[urlParams.length - 1];

    for (const item of this.controls) {
      if (item.key === page) {
        this.currentItem = item;
      }
    }

    this.route.params.subscribe((params: Params) => {

      const objectiveId = params.objectiveId;
      this.objectiveSv.fetchObjectiveById(objectiveId).subscribe((objective) => {
        this.objective = objective;
      });

    });

  }

  onPageChanged(item: EVOSegmentedControlItem) {
    this.currentItem = item;
    this.router.navigate(['admin', 'objectives', this.objective._id, 'detail', item.key]);
  }

}
