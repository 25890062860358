import {Component, Input, OnInit} from '@angular/core';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {RoundingModel} from '../../../../../models/questions/subModels/rounding.model';

@Component({
  selector: 'app-evo-rounding-whole-numbers',
  templateUrl: './evo-rounding-whole-numbers.component.html',
  styleUrls: ['./evo-rounding-whole-numbers.component.css']
})
export class EvoRoundingWholeNumbersComponent implements OnInit {

  RoundingTypes = QuestionModel.RoundingTypes;
  AnswerTypes = QuestionModel.AnswerTypes;

  @Input() question: QuestionModel = new RoundingModel();

  constructor() { }

  ngOnInit() {
  }

  castedQuestion(): RoundingModel {

    return <RoundingModel>this.question;

  }

  showQuestion(): boolean {

    if (this.question.subCategory === QuestionModel.SubCategoryTypes.roundingWholeNumber) {
      return true;
    }

    return false;

  }

  questionString() {

    return `Some string`;

  }

}
