import {Component, Input, OnInit} from '@angular/core';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {SizeComparisonWordsModel} from '../../../../../models/questions/subModels/sizeComparisonWords.model';

@Component({
  selector: 'app-evo-size-comparison-words',
  templateUrl: './evo-size-comparison-words.component.html',
  styleUrls: ['./evo-size-comparison-words.component.css']
})
export class EvoSizeComparisonWordsComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new SizeComparisonWordsModel();


  constructor() { }

  ngOnInit() {
  }

  castedQuestion(): SizeComparisonWordsModel {

    return <SizeComparisonWordsModel>this.question;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.sizeComparisonWords) {
        return true;
      }
    }

    return false;
  }

}
