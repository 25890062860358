import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ModalService} from '../../../../../shared/modals/modal/modal.service';
import {SchoolAdminGroupService} from '../../../../../services/school/school-admin-group.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GroupModel, GroupQueryModel} from '../../../../../models/school/group.model';
import {ErrorModel} from '../../../../../models/shared/error.model';
import {TranslateService} from '@ngx-translate/core';
import {StudentService} from '../../../../../services/student/student.service';
import {StudentModel} from '../../../../../models/authentication/student.model';
import {QrCodeDownloadService} from '../../../../../services/user/qr-code-download.service';
import {AdminObjectiveService} from '../../../../../services/admin/admin-objective.service';
import {ObjectiveModel} from '../../../../../models/edu-admin/objective.model';
import {SchoolModel} from '../../../../../models/school/school.model';

@Component({
  selector: 'app-school-admin-group-detail-settings',
  templateUrl: './school-admin-group-detail-settings.component.html',
  styleUrls: ['./school-admin-group-detail-settings.component.css']
})
export class SchoolAdminGroupDetailSettingsComponent implements OnInit {

  @ViewChildren('qrcodes') qrcodes: QueryList<any>;

  groupId: string;
  group: GroupModel;
  groupName = '';
  password = '';

  groupFilter = new GroupQueryModel();
  students: StudentModel[] = [];

  currentObjective = 'none';
  objectives: ObjectiveModel[] = [];

  isDownloadingAllStudents = false;
  shouldDownloadQRCodes = false;

  constructor(private modalSv: ModalService,
              private route: ActivatedRoute,
              private router: Router,
              private tSv: TranslateService,
              private studentSv: StudentService,
              private qrCodeDownloadSv: QrCodeDownloadService,
              private groupSv: SchoolAdminGroupService,
              private objectiveSv: AdminObjectiveService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.groupId = params.groupId;
      this.groupSv.fetchGroup(this.groupId).subscribe((group) => {

        this.group = group;
        this.groupName = group.name;
        this.onObjectiveSelected(this.group.objectiveId);

      });

      this.objectiveSv.fetchPublicObjectives(SchoolModel.getCurrent().country).subscribe((objectives) => {
        this.objectives = objectives;
      });

      this.isDownloadingAllStudents = true;
      this.fetchGroupStudents();

    });

  }

  getObjectivesDropdown(): { key: string, value: any, iconURL: string }[] {

    const list = [];
    list.push({
      key: 'none',
      value: this.tSv.instant('reusable.none'),
      iconURL: null
    });

    for (const objective of this.objectives) {

      const body = {
        key: objective._id,
        value: objective.getTranslatedNameForUser(),
        iconURL: null
      };

      list.push(body);

    }

    return list;

  }

  onObjectiveSelected(objectiveId: string) {

    // Can be none
    this.currentObjective = objectiveId;

  }

  onSaveGroupObjective() {

    for (const objective of this.objectives) {
      if (objective._id === this.currentObjective) {
        this.group.objectiveId = objective._id;
        this.group.grade = objective.grade;
      }
    }

    this.groupSv.updateGroupObjective(this.groupId, this.currentObjective).subscribe((group) => {
      this.group = group;
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.successMessage'));
    });

  }

  saveGroupObjectiveDisabled() {

    if (!this.group) {
      return true;
    }

    if (this.currentObjective === this.group.objectiveId) {
      return true;
    }

    return false;

  }

  fetchGroupStudents() {

    this.groupFilter.limit = 50;
    this.groupFilter.skip = this.students.length;
    this.groupSv.fetchGroupStudents(this.groupFilter, this.groupId).subscribe((students) => {

      this.students = this.students.concat(students);

      if (students.length === this.groupFilter.limit) {
        this.fetchGroupStudents();
      } else {
        this.isDownloadingAllStudents = false;
        if (this.shouldDownloadQRCodes === true) {
          this.shouldDownloadQRCodes = false;
          this.onDownloadQRCodes();
          this.modalSv.cancelClicked();
        }
      }

    });

  }

  hasActivatedQRCodes(): boolean {

    let activated = true;
    for (const s of this.students) {
      if (s.isQRCodeAuthEnabled === undefined || !s.qrCodePassword || s.isQRCodeAuthEnabled === false) {
        activated = false;
      }
    }

    return activated;

  }

  qrCodeActivationChanged(enabled: boolean) {

    this.groupSv.updateGroupQRCodeAuth(this.group, enabled).subscribe((value) => {

      this.groupFilter.skip = 0;
      this.students = [];
      this.fetchGroupStudents();

    });
  }

  // https://stackoverflow.com/questions/41484323/how-to-use-jspdf-with-angular-2
  onDownloadQRCodes() {

    if (this.isDownloadingAllStudents === true) {
      this.shouldDownloadQRCodes = true;
      // Show modal
      this.modalSv.showPopUp(this.tSv.instant('reusable.loading'), this.tSv.instant('schoolAdministration.groups.loadingQRCodes'));
      return ;
    }

    this.qrCodeDownloadSv.onDownloadQRCodes(this.qrcodes, this.students, this.group);

  }

  canSaveGroupName():  boolean {

    if (this.group) {
      if (this.group.name !== this.groupName) {
        return true;
      }
    }

    return false;
  }

  onSaveGroupNameClicked() {

    const previousGroupName = this.group.name;

    this.group.name = this.groupName;
    this.groupSv.updateGroup(this.group).subscribe((group) => {

      this.group = group;
      this.groupName = group.name;
      this.router.navigate(['schooladmin', 'groups', group._id, 'settings']);

    }, (err: ErrorModel) => {

      this.group.name = previousGroupName;
      this.modalSv.showTranslatedErrorModal(err);

    });

  }

  canDeleteGroup(): boolean {

    if (this.password.length >= 6) {

      return true;
    }

    return false;
  }

  onDeleteGroupClicked() {

    this.modalSv.showChoiceModal(this.tSv.instant('reusable.warning'),
      this.tSv.instant('schoolAdministration.groups.deleteGroupModalWarning'))
      .subscribe((response) => {

        if (response) {

          this.groupSv.deleteGroup(this.groupId, this.password).subscribe((group) => {

            this.modalSv.showAlertModal(this.tSv.instant('resuable.success'),
              this.tSv.instant('reusable.successMessage'))
              .subscribe(() => {

                this.router.navigate(['schooladmin', 'groups']);

              });

          }, (err: ErrorModel) => {

            this.modalSv.showTranslatedErrorModal(err);

          });
        }
      });

  }

}
