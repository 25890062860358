import {QuestionModel} from '../question.model';
import {RangeModel} from '../shared/range.model';

export class PerimeterFromShapeModel extends QuestionModel {

  model = {
    firstRange: new RangeModel(),
    shapes: []
  };

  static generateModel(json: any): PerimeterFromShapeModel {

    const q = new PerimeterFromShapeModel();

    const model = json.model;
    if (model) {

      if (model.firstRange) {
        q.model.firstRange = RangeModel.generateModel(model.firstRange);
      }

      q.model.shapes = model.shapes;

    }

    return q;

  }

}
