import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SkillGroupModel} from '../../../models/edu-admin/skillGroup.model';
import {EVOListViewColumn} from '../../../models/UI/EVOListViewColumn.model';
import {StringExtensionModel} from '../../../models/extensions/string-extension.model';
import {QuestionModel} from '../../../models/questions/question.model';

@Component({
  selector: 'app-evo-skill-groups-list-view',
  templateUrl: './evo-skill-groups-list-view.component.html',
  styleUrls: ['./evo-skill-groups-list-view.component.css']
})
export class EvoSkillGroupsListViewComponent implements OnInit {

  /**
   * Fixed or dynamic
   */
  @Input() heightType = 'fixed';

  /**
   * Used if height type is fixed.
   */
  @Input() height = '500px';

  @Input() skillGroups: SkillGroupModel[] = [];

  allSelected = false;
  @Input() selectedSkillGroups: SkillGroupModel[] = [];

  @Input() hasMoreData = false;

  @Output() skillGroupsSelected = new EventEmitter<SkillGroupModel[]>();
  @Output() fetchMore = new EventEmitter();

  @Input() sort = new EVOSkillGroupListSort();
  @Output() sortChanged = new EventEmitter<EVOSkillGroupListSort>();

  @Input() selectionColumn = EVOListViewColumn.xsFixedColumn('selection', false);
  @Input() nameColumn = EVOListViewColumn.largeGrowColumn('name');
  @Input() numSkillsColumn = EVOListViewColumn.smallFixedColumn('numSkills');
  @Input() orderColumn = EVOListViewColumn.xsFixedColumn('order');
  @Input() createdAtColumn = EVOListViewColumn.smallFixedColumn('createdAt');

  @Input() skillGroupClickEnabled = false;
  @Output() skillGroupClicked = new EventEmitter<SkillGroupModel>();

  @Input('selectionEnabled')
  set selectionEnabled(selection: boolean) {
    this.selectionColumn.isVisible = selection;
  }

  getSelectionEnabled(): boolean {
    return this.selectionColumn.isVisible;
  }

  constructor() { }

  ngOnInit() {
  }

  getContainerHeight() {

    if (this.heightType === 'fixed') {
      return this.height;
    }

    return 'auto';

  }

  getContentHeight() {

    if (this.heightType === 'fixed') {
      return 'calc(100% - 38px)';
    }

    return 'auto';

  }

  getYOverflow(): string {

    if (this.heightType === 'fixed') {
      return 'scroll';
    }

    return 'auto';
  }

  onAllSelected(selection: boolean) {

    if (selection) {
      this.selectedSkillGroups = this.skillGroups;
    } else {
      this.selectedSkillGroups = [];
    }

    this.allSelected = selection;

    if (this.skillGroupsSelected) {
      this.skillGroupsSelected.emit(this.selectedSkillGroups);
    }

  }

  skillGroupCategoryImgUrl(skillGroup: SkillGroupModel): string {

    return QuestionModel.categoryIconURL(skillGroup.category);
  }

  onSkillGroupSelect(skillGroup: SkillGroupModel) {

    const index = this.selectedSkillGroups.indexOf(skillGroup);
    if (index !== -1) {
      this.selectedSkillGroups.splice(index, 1);
    } else {
      this.selectedSkillGroups.push(skillGroup);
    }

    if (this.skillGroupsSelected) {
      this.skillGroupsSelected.emit(this.selectedSkillGroups);
    }

  }

  hasSelectedSkillGroup(skillGroup: SkillGroupModel): boolean {

    return this.selectedSkillGroups.includes(skillGroup);

  }

  onFetchMoreData() {
    if (this.fetchMore) {
      this.fetchMore.emit();
    }
  }

  onNameLegendSortClicked() {

    if (this.sort.key !== 'name') {

      this.sort.key = 'name';
      this.sort.value = -1;

    } else {

      if (this.sort.value === 1) {
        this.sort.value = -1;
      } else {
        this.sort.value = 1;
      }

    }

    if (this.sortChanged) {
      this.sortChanged.emit(this.sort);
    }

  }

  onOrderLegendClicked() {

    if (this.sort.key !== 'order') {

      this.sort.key = 'order';
      this.sort.value = 1;

    } else {

      if (this.sort.value === 1) {
        this.sort.value = -1;
      } else {
        this.sort.value = 1;
      }

    }

    if (this.sortChanged) {
      this.sortChanged.emit(this.sort);
    }

  }

  getSortArrowImgUrl(): string {

    if (this.sort.value === 1) {
      return './assets/icons/sort-up.svg';
    } else {
      return './assets/icons/sort-down.svg';
    }

  }

  onSkillGroupClicked(skillGroup: SkillGroupModel) {

    if (this.skillGroupClickEnabled === true && this.skillGroupClicked) {
      this.skillGroupClicked.emit(skillGroup);
    }

  }

  calcWidth(size: string): string {

    return EVOListViewColumn.calcWidth(size, this.getColumnDescriptors());

  }

  getColumnDescriptors(): EVOListViewColumn[] {

    return [
      this.selectionColumn,
      this.nameColumn,
      this.numSkillsColumn,
      this.createdAtColumn
    ];

  }

}

export class EVOSkillGroupListSort {
  key = 'name';
  value = 1;
}
