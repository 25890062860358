import {Component, Input, OnInit} from '@angular/core';
import {GroupModel} from '../../../../models/school/group.model';
import {SkillGroupStructureModel} from '../../../../models/student/skillGroupStatusModel';
import {UserModel} from '../../../../models/authentication/user.model';
import {LearningStates} from '../../../../models/student/skill.model';

@Component({
  selector: 'app-group-dash-objective',
  templateUrl: './group-dash-objective.component.html',
  styleUrls: ['./group-dash-objective.component.css']
})
export class GroupDashObjectiveComponent implements OnInit {

  @Input() group: GroupModel;
  @Input() skillGroupStructure: SkillGroupStructureModel;

  constructor() {
  }

  ngOnInit() {
  }

  groupName(): string {

    if (this.group && this.group.objective) {

      return this.group.objective.translatedName[UserModel.getCurrent().culture];
    }

    return '';
  }
}


