import {Component, OnInit} from '@angular/core';
import {EvoAdminUserSelectStudentService} from './evo-admin-user-select-student.service';
import {StudentModel} from '../../../../../../models/authentication/student.model';

@Component({
  selector: 'app-evo-admin-user-select-student',
  templateUrl: './evo-admin-user-select-student.component.html',
  styleUrls: ['./evo-admin-user-select-student.component.css']
})
export class EvoAdminUserSelectStudentComponent implements OnInit {

  constructor(public sv: EvoAdminUserSelectStudentService) { }

  ngOnInit() {

  }

}
