import {Injectable, QueryList} from '@angular/core';
import {jsPDF} from 'jspdf';
import {StudentModel} from '../../models/authentication/student.model';
import {GroupModel} from '../../models/school/group.model';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class QrCodeDownloadService {

  constructor(private tSv: TranslateService) { }

  onDownloadQRCodes(qrCodes: QueryList<any>, students: StudentModel[], group: GroupModel) {

    let index = 0;
    const pdf = new jsPDF('p', 'mm', 'A4');

    // page is split into 3x4, A4 is 210 x 297
    // 4.5 margin either side, 201 / 3 = 67
    // 8.5 margin either side, 270 / 3 = 90
    for (const qrcode of qrCodes.toArray()) {

      const x = (index % 3) * 67 + 4.5;
      const y = (Math.floor(index / 3) % 3) * 90 + 10;

      if (index % 9 === 0 && index !== 0) {
        pdf.addPage();
      }

      const image = qrcode.el.nativeElement.querySelector('img');
      const imageData = this.getBase64Image(image);

      pdf.addImage(imageData, 'JPEG', x + 11.5, y + 2, 46, 46);
      pdf.setFontSize(14);

      const fonts = pdf.getFontList();
      const keys = Object.keys(fonts);

      if (keys.includes('Helvetica') && fonts.Helvetica.includes('bold')) {
        pdf.setFont('Helvetica', 'bold');
      } else if (keys.includes('Times') && fonts.Times.includes('bold')) {
        pdf.setFont('Helvetica', 'bold');
      } else {
        const fontName = keys[0];
        const weight = fonts[fontName][0];
        pdf.setFont(keys[0], weight);
      }

      pdf.text(students[index].fullName(this.tSv), x + 34.5, y + 56, { maxWidth: 60, align: 'center' });

      index++;

    }

    const nameWithoutDots = group.name.replace('.', '') + '.pdf';

    pdf.save('QRCodes ' + nameWithoutDots);

  }

  getBase64Image(img) {

    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    const dataURL = canvas.toDataURL('image/jpeg');
    return dataURL;
  }

}
