import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {UserModel} from '../../../models/authentication/user.model';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {ModalService} from '../../../shared/modals/modal/modal.service';
import {StudentService} from '../../../services/student/student.service';
import {TranslateService} from '@ngx-translate/core';
import {CultureModel} from '../../../models/localization/culture.model';

@Component({
  selector: 'app-signin-secondary',
  templateUrl: './signin-secondary.component.html',
  styleUrls: ['./signin-secondary.component.css']
})
export class SigninSecondaryComponent implements OnInit {

  constructor(private router: Router,
              private authSv: AuthService,
              private studentSv: StudentService,
              private modalSv: ModalService,
              private tSv: TranslateService) {
  }

  ngOnInit() {
  }

  onSignInClicked(form: NgForm) {

    let email = form.value.email;
    if (email) {
      email = email.toLowerCase();
    }

    const password = form.value.password;

    UserModel.signInUser(email, password, this.authSv, this.tSv, this.modalSv, this.router);

  }

  onSignupClicked() {

    this.router.navigate(['home', CultureModel.getHomepageCulture(), 'signup']);

  }

  onForgotPassClicked() {

    this.router.navigate(['home', CultureModel.getHomepageCulture(), 'forgotpass']);


  }

}
