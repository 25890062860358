import {Component, Input, OnInit} from '@angular/core';
import {SizeComparisonNumbersModel} from '../../../../../models/questions/subModels/sizeComparisonNumbers.model';
import {QuestionModel} from '../../../../../models/questions/question.model';

@Component({
  selector: 'app-evo-size-comparison-numbers',
  templateUrl: './evo-size-comparison-numbers.component.html',
  styleUrls: ['./evo-size-comparison-numbers.component.css']
})
export class EvoSizeComparisonNumbersComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new SizeComparisonNumbersModel();

  constructor() { }

  ngOnInit() {
  }

  castedQuestion(): SizeComparisonNumbersModel {

    return <SizeComparisonNumbersModel>this.question;
  }

  getSymbol() {

    if (this.castedQuestion().model.symbol === 'greater') {

      if (this.castedQuestion().model.leftSideUnknown === true) {
        return '>';
      } else {
        return '<';
      }

    } else if (this.castedQuestion().model.symbol === 'less') {

      if (this.castedQuestion().model.leftSideUnknown === true) {
        return '<';
      } else {
        return '>';
      }

    } else if (this.castedQuestion().model.symbol === 'equal') {

      return '=';

    }

  }

  getFirstRange() {

    if (this.castedQuestion().model.leftSideUnknown === true) {
      return this.castedQuestion().model.firstRange.getRange();
    } else {
      return this.castedQuestion().model.possibleAnswers.getRange();
    }

  }

  getSecondRange() {

    if (this.castedQuestion().model.leftSideUnknown === true) {
      return this.castedQuestion().model.possibleAnswers.getRange();
    } else {
      return this.castedQuestion().model.firstRange.getRange();
    }

  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.sizeComparisonNumbers) {
        return true;
      }
    }

    return false;
  }

}
