import { Injectable } from '@angular/core';
import {ObjectiveModel, ObjectiveFilter} from '../../models/edu-admin/objective.model';
import {AuthService} from '../auth.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StringExtensionModel} from '../../models/extensions/string-extension.model';

@Injectable({
  providedIn: 'root'
})
export class AdminObjectiveService {

  constructor(private authSv: AuthService) { }

  fetchObjectives(filter: ObjectiveFilter): Observable<ObjectiveModel[]> {

    const url = '/admin/objectives' + StringExtensionModel.queryString(filter);

    return this.authSv.get(url).pipe(map((response) => {

      return ObjectiveModel.generateModels(response.data);

    }));

  }

  fetchObjectiveById(objectiveId: string, filter: ObjectiveFilter = null): Observable<ObjectiveModel> {

    const url = `/admin/objectives/${objectiveId}` + StringExtensionModel.queryString(filter);

    return this.authSv.get(url).pipe(map((response) => {

      return ObjectiveModel.generateModel(response.data);

    }));

  }

  fetchPublicObjectives(country: string): Observable<ObjectiveModel[]> {

    const url = '/objectivesPublic' + StringExtensionModel.queryString({ country });

    return this.authSv.get(url).pipe(map((response) => {

      const objectives = ObjectiveModel.generateModels(response.data);
      return objectives;

    }));

  }

  updateObjective(objective: ObjectiveModel, filter: ObjectiveFilter = null): Observable<any> {

    const url = `/admin/objectives/${objective._id}` + StringExtensionModel.queryString(filter);

    return this.authSv.patch(url, objective).pipe(map((response) => {

        return ObjectiveModel.generateModel(response.data);

      }));
  }

  archiveObjective(objective: ObjectiveModel, isArchived: boolean, pass: string): Observable<ObjectiveModel> {

    const url = `/admin/objectives/${objective._id}/archive`;

    return this.authSv.patch(url, {
      isArchived: isArchived,
      password: pass
    }).pipe(map((response) => {

      const obj = ObjectiveModel.generateModel(response.data);
      return obj;

    }));

  }

  deleteObjective(_id: string) {

    const url = `/admin/objectives/${_id}`;

    return this.authSv.delete(url).pipe(map(objectiveDeleted => {
      return !!objectiveDeleted.data;
    }));
  }

  createObjective(objective: ObjectiveModel): Observable<any> {

    return this.authSv.post('/admin/objectives', objective).pipe(map(response => {

      return ObjectiveModel.generateModel(response.data);

    }));

  }
}
