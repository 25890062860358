import {Component, OnInit} from '@angular/core';
import {CultureModel} from '../../../models/localization/culture.model';
import {AnswerQueryModel} from '../../../models/student/answer.model';
import {StudentModel} from '../../../models/authentication/student.model';
import {UserModel} from '../../../models/authentication/user.model';
import {NavigationEnd, Router} from '@angular/router';
import {StudentService} from '../../../services/student/student.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  CultureModel = CultureModel;
  UserModel = UserModel;

  filter = new AnswerQueryModel();
  isSetup = false;
  student: StudentModel;

  constructor(private router: Router,
              private studentSv: StudentService) {
  }

  ngOnInit() {

    this.student = StudentModel.getCurrent();
    this.isSetup = this.student.evolytesProfile.isSetup;

    // Logic to reload the videos
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {

        this.studentSv.fetchStudent(StudentModel.getCurrent()._id).subscribe((uStudent) => {

          StudentModel.setCurrent(uStudent);

          this.student = uStudent;
          this.isSetup = this.student.evolytesProfile.isSetup;

        });

      }

    });

  }

}
