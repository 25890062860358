import {Component, Input, OnInit} from '@angular/core';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {DoubleAdditionModel} from '../../../../../models/questions/subModels/doubleAddition.model';

@Component({
  selector: 'app-evo-double-addition',
  templateUrl: './evo-double-addition.component.html',
  styleUrls: ['./evo-double-addition.component.css']
})
export class EvoDoubleAdditionComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new DoubleAdditionModel();

  constructor() { }

  ngOnInit() {
  }

  getOutcomeString(): string {

    let minOutcomeOne = this.castedQuestion().model.firstRange.minValue() + this.castedQuestion().model.secondRange.minValue();
    let maxOutcomeOne = this.castedQuestion().model.firstRange.maxValue() + this.castedQuestion().model.secondRange.maxValue();

    if (this.castedQuestion().model.firstMinOutcome !== null) {

      minOutcomeOne = this.castedQuestion().model.firstMinOutcome;

    }

    if (this.castedQuestion().model.firstMaxOutcome !== null) {

      maxOutcomeOne = this.castedQuestion().model.firstMaxOutcome;

    }

    let outcomePartOne = '[' + minOutcomeOne.toString(10) + ', ';
    outcomePartOne += maxOutcomeOne + ']';

    let minOutcomeTwo = minOutcomeOne + this.castedQuestion().model.thirdRange.minValue();
    let maxOutcomeTwo = maxOutcomeOne + this.castedQuestion().model.thirdRange.maxValue();

    if (this.castedQuestion().model.secondMinOutcome !== null) {
      minOutcomeTwo = this.castedQuestion().model.secondMinOutcome;
    }

    if (this.castedQuestion().model.secondMaxOutcome !== null) {
      maxOutcomeTwo = this.castedQuestion().model.secondMaxOutcome;
    }

    let outcomePartTwo = '[' + minOutcomeTwo + ', ';
    outcomePartTwo += maxOutcomeTwo + ']';

    return outcomePartOne + ' + [' + this.castedQuestion().model.thirdRange.getRange()
      + '] = ' + outcomePartTwo;
  }

  castedQuestion(): DoubleAdditionModel {

    return <DoubleAdditionModel>this.question;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.doubleAddition) {
        return true;
      }
    }

    return false;
  }

}
