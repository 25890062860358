import {Component, Input, OnInit} from '@angular/core';
import {FractionSetupFromImageModel} from '../../../../../models/questions/subModels/fractionSetupFromImage.model';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {ProportionsSetupFromImageModel} from '../../../../../models/questions/subModels/proportionsSetupFromImage.model';

@Component({
  selector: 'app-evo-proportions-setup-from-image',
  templateUrl: './evo-proportions-setup-from-image.component.html',
  styleUrls: ['./evo-proportions-setup-from-image.component.css']
})
export class EvoProportionsSetupFromImageComponent implements OnInit {

  QuestionImages = QuestionModel.ImageTypes.dropdownList();
  missingFactorsDropdownList = FractionSetupFromImageModel.MissingFactorTypes.dropdownList();
  @Input() question: QuestionModel = new ProportionsSetupFromImageModel();

  constructor() { }

  ngOnInit() {
  }

  castQuestion(): ProportionsSetupFromImageModel {

    return <ProportionsSetupFromImageModel>this.question;
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {
        return true;
      }
    }

    return false;
  }
}
