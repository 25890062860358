export class NumberExtensionModel {

  static delimiterSeparatedNumber(value: number, delimiter: string, fractionalDigits: number = 0): string {

    if (value === undefined || value === null) {
      value = 0;
    }

    return value.toFixed(fractionalDigits).replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);

  }

  // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  static localeSeparatedNumber(value: number, culture: string = 'en-GB', fractionalDigits: number = 0): string {

    return value.toLocaleString(culture, { maximumFractionDigits: fractionalDigits });

  }

  // https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
  static getScrollBarWidth(): number {

    let width = 0;
    if (document && document.body) {

      const el = document.createElement('div');
      el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
      document.body.appendChild(el);

      width = el.offsetWidth - el.clientWidth;
      el.remove();
      return width;

    }

    return width;

  }

  static truncate(value: number, fractionalDigits: number = 2): number {

    const mult = Math.pow(10, fractionalDigits);

    const newValue = Math.floor(value * mult);

    return newValue / mult;

  }

}
